import * as React from "react";
import { Box, Typography, useTheme, TextField, Button, CircularProgress } from "@mui/material";
import { Export, SearchNormal1 } from "iconsax-react";
import { coreSlideRouterUpdateSlideText } from "../../backend-client/generated/sdk.gen";

// Define template type
interface SlideTemplate {
    id: string;
    title: string;
    thumbnailUrl: string; // Path to the thumbnail image
    fileUrl: string; // URL to the actual PowerPoint file
}

// Hard-coded templates
const SLIDE_TEMPLATES: SlideTemplate[] = [
    {
        id: "sequential-research-summary",
        title: "Sequential Research Summary",
        thumbnailUrl: "https://answergrid.b-cdn.net/arrow_template_blue_tenant_HC.png",
        fileUrl: "https://AnswerGrid.b-cdn.net/arrow_template_blue_tenant.pptx",
    },
    // {
    //     id: "line-graph",
    //     title: "Trends - Line Graph",
    //     thumbnailUrl:
    //         "https://cdn2.slidemodel.com/wp-content/uploads/80007-02-consulting-report-powerpoint-template-11-870x489.jpg",
    //     fileUrl: "https://AnswerGrid.b-cdn.net/arrow_template_blue_tenant.pptx",
    // },
    {
        id: "bar-graph",
        title: "Bar Graph",
        thumbnailUrl: "https://AnswerGrid.b-cdn.net/Landing%20page/blue-bar-chart-theme.png",
        fileUrl: "https://AnswerGrid.b-cdn.net/arrow_template_blue_tenant.pptx",
    },
    // {
    //     id: "scatter-plot",
    //     title: "Scatter Plot",
    //     thumbnailUrl:
    //         "https://slidemodel.com/wp-content/uploads/7443-01-scatter-plot-powerpoint-template-16x9-5-320x180.jpg",
    //     fileUrl: "https://AnswerGrid.b-cdn.net/arrow_template_blue_tenant.pptx",
    // },
    {
        id: "area-graph",
        title: "Area Graph",
        thumbnailUrl:
            "https://www.slideteam.net/wp/wp-content/uploads/2021/11/4_area_chart_ppt_inspiration_professional_wd.png",
        fileUrl: "https://AnswerGrid.b-cdn.net/arrow_template_blue_tenant.pptx",
    },
    {
        id: "heat-map",
        title: "Heat Map",
        thumbnailUrl: "https://powerslides.com/wp-content/uploads/2022/01/PowerPoint-Heatmap-Template-2.png",
        fileUrl: "https://AnswerGrid.b-cdn.net/arrow_template_blue_tenant.pptx",
    },
];

interface AnswerAsSlideProps {
    content: string;
}

export const AnswerAsSlide: React.FC<AnswerAsSlideProps> = ({ content }) => {
    const theme = useTheme();
    const [searchQuery, setSearchQuery] = React.useState("");
    const [selectedTemplate, setSelectedTemplate] = React.useState<SlideTemplate | null>(null);

    // Filter templates based on search query
    const filteredTemplates = React.useMemo(() => {
        return SLIDE_TEMPLATES.filter(template => template.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [searchQuery]);

    const handleTemplateSelect = (template: SlideTemplate) => {
        // Toggle selection - if clicking the same template, deselect it
        if (selectedTemplate?.id === template.id) {
            setSelectedTemplate(null);
        } else {
            setSelectedTemplate(template);
        }
    };

    const [isExporting, setIsExporting] = React.useState(false);
    const handleExport = React.useCallback(async () => {
        if (!selectedTemplate || !content) return;

        try {
            setIsExporting(true);
            const response = await coreSlideRouterUpdateSlideText({
                throwOnError: true,
                body: {
                    answer: content,
                },
            });
            // Create a blob from the response and download it
            const blob = new Blob([response.data as BlobPart], {
                type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "AnswerGrid-AI-Slide.pptx";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error("Failed to generate slide:", error);
            // If you have access to a snackbar notification system, you could use it here
        } finally {
            setIsExporting(false);
        }
    }, [content, selectedTemplate]);

    // Display templates in rows of 3
    const templateRows = React.useMemo(() => {
        const rows = [];
        for (let i = 0; i < filteredTemplates.length; i += 3) {
            rows.push(filteredTemplates.slice(i, i + 3));
        }
        return rows;
    }, [filteredTemplates]);

    return (
        <>
            {/* Preview area first */}
            <Box sx={{ my: 3, display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        width: "100%",
                        aspectRatio: "16/9",
                        bgcolor: "surface.0",
                        border: 1,
                        borderColor: "neutrals.30",
                        borderRadius: 1,
                        backgroundImage: selectedTemplate ? `url(${selectedTemplate.thumbnailUrl})` : "none",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        position: "relative",
                        mb: 2,
                    }}
                >
                    {!selectedTemplate && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography variant="h6" color="neutrals.70">
                                No template selected
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={
                            isExporting ? (
                                <CircularProgress size={20} sx={{ color: "secondary.main" }} />
                            ) : (
                                <Export size={20} color={theme.palette.neutrals[80]} />
                            )
                        }
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleExport}
                        sx={{ borderRadius: 5, borderColor: "neutrals.30", border: 1 }}
                        disabled={isExporting || selectedTemplate == null}
                    >
                        <Typography variant="body2" color="neutrals.80">
                            {isExporting ? "Generating slide..." : "Generate slide"}
                        </Typography>
                    </Button>
                </Box>
            </Box>

            {/* divider */}
            <Box
                sx={{
                    width: "100%",
                    height: "1px",
                    bgcolor: "neutrals.40",
                    mb: 3,
                    flexShrink: 0,
                }}
            />

            <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ position: "relative", flexGrow: 1 }}>
                    <TextField
                        fullWidth
                        placeholder="Search templates"
                        variant="outlined"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        size="small"
                        InputProps={{
                            startAdornment: <SearchNormal1 size={20} color={theme.palette.neutrals[70]} />,
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                pl: 1,
                                display: "flex",
                                gap: 1,
                                borderRadius: 5,
                                typography: "body2",
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "neutrals.30",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "neutrals.30",
                                },
                            },
                        }}
                    />
                </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", mt: 2, flexGrow: 1, overflow: "auto", flexShrink: 0 }}>
                {templateRows.map((row, rowIndex) => (
                    <Box
                        key={rowIndex}
                        sx={{
                            display: "flex",
                            gap: 2,
                            mb: 2,
                        }}
                    >
                        {row.map(template => (
                            <Box
                                key={template.id}
                                onClick={() => handleTemplateSelect(template)}
                                sx={{
                                    flex: 1,
                                    cursor: "pointer",
                                    border: selectedTemplate?.id === template.id ? 2 : 0,
                                    borderColor:
                                        selectedTemplate?.id === template.id ? "secondary.main" : "transparent",
                                    borderRadius: 1,
                                    overflow: "hidden",
                                    transition: "all 0.2s",
                                    bgcolor: "surface.25",
                                    "&:hover": {
                                        boxShadow: 2,
                                    },
                                }}
                            >
                                <Box
                                    component="img"
                                    src={template.thumbnailUrl}
                                    sx={{
                                        height: 110,
                                        width: "100%",
                                        objectFit: "cover",
                                        objectPosition: "left",
                                    }}
                                />
                                <Box sx={{ p: 2 }}>
                                    <Typography variant="subtitle1" fontWeight="medium">
                                        {template.title}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                        {/* Fill empty slots with blank spaces to maintain grid */}
                        {Array(3 - row.length)
                            .fill(0)
                            .map((_, i) => (
                                <Box key={i} sx={{ flex: 1 }} />
                            ))}
                    </Box>
                ))}
            </Box>
        </>
    );
};
