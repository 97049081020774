import * as React from "react";
import { Box, Dialog, DialogContent, Typography, IconButton, useTheme, alpha } from "@mui/material";
import { Close } from "@mui/icons-material";
import { AnswerAsSlide } from "./AnswerAsSlide";
import { getScrollbarSx } from "../../shared/scrollbarProps";

interface AnswerAsSlideDialogProps {
    open: boolean;
    onClose: () => void;
    content: string;
}

export const AnswerAsSlideDialog = ({ open, onClose, content }: AnswerAsSlideDialogProps) => {
    const theme = useTheme();
    const shadowColor = alpha(theme.palette.surface[50], 0.5);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: shadowColor,
                    },
                },
            }}
            sx={{
                "& .MuiDialog-paper": {
                    border: 1,
                    borderColor: "neutrals.30",
                    borderRadius: 3,
                    height: 750,
                    width: 800,
                    maxWidth: 800,
                    boxShadow: "none",
                },
            }}
        >
            <DialogContent
                sx={{
                    px: 2.5,
                    py: 2,
                    height: "100%",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                    <Typography variant="h6" color="secondary.main" noWrap>
                        Create Slide
                    </Typography>
                    <IconButton onClick={onClose} size="small" edge="end">
                        <Close sx={{ color: "neutrals.50", width: 20, height: 20 }} />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        flex: 1,
                        overflowY: "auto",
                        ...getScrollbarSx("surface.0"),
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <AnswerAsSlide content={content} />
                </Box>
            </DialogContent>
        </Dialog>
    );
};
