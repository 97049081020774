import * as React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Box, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isHomegrownLoggedIn } from "../auth/authentication";
import { useSnackbar } from "notistack";
import { Lock } from "iconsax-react";
import { Helmet } from "react-helmet-async";
import { currentUserQueryOptions } from "../chat/queryOptions";
import { coreAuthRouterChangePassword, ErrorOut } from "../backend-client/generated";
import { UserAdministrationLayout } from "./UserAdministrationLayout";
import { UserAdministrationForm } from "./UserAdministrationForm";
import { FormTextField } from "./formTextField";

export const ChangePassword: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Change Password - AnswerGrid</title>
            </Helmet>
            <UserAdministrationLayout imageUrl="https://AnswerGrid.b-cdn.net/field-of.png">
                <ChangePasswordContent />
            </UserAdministrationLayout>
        </>
    );
};

const ChangePasswordContent: React.FC = () => {
    const navigate = useNavigate();
    const [error, setError] = React.useState<string | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [isRedirecting, setIsRedirecting] = React.useState(false);

    const user = useQuery({
        ...currentUserQueryOptions,
        enabled: isHomegrownLoggedIn(),
    });

    React.useEffect(() => {
        if (isRedirecting) {
            return;
        }
        if (!isHomegrownLoggedIn()) {
            setIsRedirecting(true);
            enqueueSnackbar("Please login to change your password", { variant: "info" });
            navigate("/login");
        }
    }, [user.data, navigate, isRedirecting, enqueueSnackbar]);

    const changePasswordMutation = useMutation({
        mutationFn: (data: { oldPassword: string; newPassword: string }) =>
            coreAuthRouterChangePassword({
                body: { old_password: data.oldPassword, new_password: data.newPassword },
            }),
        onSuccess: () => {
            console.log("Password change successful");
            enqueueSnackbar("Password changed successfully", { variant: "success" });
            navigate("/home");
        },
        onError: error => {
            console.error("Password change failed:", error);
            setError(error.message ?? (error as unknown as ErrorOut)?.error ?? "Password change failed.");
        },
    });

    const handleSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            const oldPassword = data.get("old_password") as string;
            const newPassword = data.get("new_password") as string;
            const confirmNewPassword = data.get("confirm_new_password") as string;

            if (newPassword !== confirmNewPassword) {
                setError("New passwords do not match.");
                return;
            }

            await changePasswordMutation.mutateAsync({ oldPassword, newPassword });
        },
        [changePasswordMutation],
    );

    const handleChangePasswordClick = React.useCallback(async () => {
        const form = document.querySelector("form");
        if (!form) return;

        const data = new FormData(form);
        const oldPassword = data.get("old_password") as string;
        const newPassword = data.get("new_password") as string;
        const confirmNewPassword = data.get("confirm_new_password") as string;

        if (newPassword !== confirmNewPassword) {
            setError("New passwords do not match.");
            return;
        }

        await changePasswordMutation.mutateAsync({ oldPassword, newPassword });
    }, [changePasswordMutation]);

    return (
        <UserAdministrationForm
            title="Change Password"
            actionButtonText="Change Password"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onActionButtonClick={handleChangePasswordClick}
            loading={changePasswordMutation.isPending}
            underButtonContent={
                <Typography variant="body2">
                    Want to go back?{" "}
                    <Link href="/settings" color="secondary" sx={{ textDecoration: "none", fontWeight: "medium" }}>
                        Return to Settings →
                    </Link>
                </Typography>
            }
        >
            <Box
                component="form"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, display: "flex", flexDirection: "column", gap: 2 }}
            >
                <FormTextField
                    label="Current Password"
                    id="old_password"
                    name="old_password"
                    placeholder="Current Password"
                    type="password"
                    autoComplete="current-password"
                    icon={<Lock size={20} />}
                />
                <FormTextField
                    label="New Password"
                    id="new_password"
                    name="new_password"
                    placeholder="New Password"
                    type="password"
                    autoComplete="new-password"
                    icon={<Lock size={20} />}
                />
                <FormTextField
                    label="Confirm New Password"
                    id="confirm_new_password"
                    name="confirm_new_password"
                    placeholder="Confirm New Password"
                    type="password"
                    autoComplete="new-password"
                    icon={<Lock size={20} />}
                />
                {error && (
                    <Typography variant="body2" color="error" align="center">
                        {error}
                    </Typography>
                )}
            </Box>
        </UserAdministrationForm>
    );
};
