import React from "react";
import { Box, Typography, Theme, SxProps } from "@mui/material";
import PDFIcon from "../assets/pdf-icon.svg";
import XlsxIcon from "../assets/xlsx-icon.png";
import DocIcon from "../assets/docx-icon.png";
import PptxIcon from "../assets/pptx-icon.png";
import { FILE_EXTENSIONS_REGEX } from "../search/fileExtensions";
import { FileType } from "../shared/types";
import { DocumentThumbnail } from "../search/documentThumbnail";

export interface CitedSourcePage {
    page: number;
    rank: number;
}

interface CitedSourceBoxProps {
    fileName: string;
    blobName: string | undefined;
    fileType: FileType | undefined;
    isUserFile: boolean;
    pages: CitedSourcePage[];
    onSelect: ((page: number | undefined) => void) | undefined;
    /**
     * If provided, the page will be highlighted in the UI.
     *
     * This component can work without this, but it's useful for the chat component where no other selection state is shown.
     */
    selectedPage?: number;
    sx?: SxProps<Theme>;
    timeCreated?: string | undefined;
}

function getIcon(fileType: FileType | undefined) {
    switch (fileType) {
        case "pdf":
            return PDFIcon;
        case "docx":
            return DocIcon;
        case "xlsx":
            return XlsxIcon;
        case "pptx":
            return PptxIcon;
        default:
            return null;
    }
}

const MAX_PAGES_TO_SHOW = 2;

const CARD_HEIGHT = 80;

export const CitedSourceBox: React.FC<CitedSourceBoxProps> = ({
    fileName,
    blobName,
    fileType,
    isUserFile,
    timeCreated,
    pages,
    onSelect,
    selectedPage,
    sx,
}) => {
    const handleSelectWholeCard = React.useMemo(() => (onSelect != null ? () => onSelect(1) : undefined), [onSelect]);
    const isSelected = selectedPage != null;

    const year = React.useMemo(() => {
        if (timeCreated == null) {
            return undefined;
        }
        return new Date(timeCreated).getFullYear();
    }, [timeCreated]);

    const thumbnail =
        blobName != null ? (
            <Box sx={{ position: "relative" }}>
                <DocumentThumbnail
                    blobName={blobName}
                    fileType={fileType ?? undefined}
                    isUserFile={isUserFile}
                    // TODO: Make this happen on the server side
                    shouldSasSign={true}
                    width={48}
                    height={CARD_HEIGHT - 16}
                    fileTypeIconPlacement="top"
                />
                {year != null && (
                    <Box
                        sx={theme => ({
                            position: "absolute",
                            bottom: -1,
                            left: 1,
                            right: 1,
                            bgcolor: "neutrals.25",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: 48 - 2,
                            borderBottomLeftRadius: 0.5 * theme.shape.borderRadius,
                            borderBottomRightRadius: 0.5 * theme.shape.borderRadius,
                        })}
                    >
                        <Typography
                            variant="caption"
                            sx={{
                                // color: "white",
                                lineHeight: 1.3,
                                color: "neutrals.70",
                            }}
                        >
                            {year}
                        </Typography>
                    </Box>
                )}
            </Box>
        ) : undefined;

    const fileNameSection = (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                columnGap: 2,
                overflow: "hidden",
            }}
        >
            <Typography
                variant="body2"
                sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    alignSelf: "flex-start",
                    cursor: onSelect != null ? "pointer" : "default",
                    lineHeight: 1.3,
                    "&:hover": {
                        bgcolor: onSelect != null ? "action.hover" : "transparent",
                    },
                }}
                onClick={handleSelectWholeCard}
            >
                {fileName.replace(FILE_EXTENSIONS_REGEX, "")}
            </Typography>

            {thumbnail == null && (
                <img src={getIcon(fileType) ?? PDFIcon} alt={`${fileType ?? "PDF"} icon`} width={24} height={24} />
            )}
        </Box>
    );

    const content = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                overflow: "hidden",
            }}
        >
            {fileNameSection}
            {pages.length > 0 && (
                <PagesSection pages={pages} fileType={fileType} onSelect={onSelect} selectedPage={selectedPage} />
            )}
        </Box>
    );

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    alignItems: "stretch",
                    columnGap: 1.5,
                    bgcolor: "surface.0",
                    border: 1,
                    borderColor: isSelected ? "selection.main" : "neutrals.20",
                    borderRadius: 1,
                    p: 1,
                    height: thumbnail == null ? CARD_HEIGHT - 10 : CARD_HEIGHT,
                    width: 240,
                    minWidth: 240,
                    overflow: "hidden",
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {thumbnail}
            {content}
        </Box>
    );
};

interface PagesSectionProps {
    pages: CitedSourcePage[];
    fileType: FileType | undefined;
    onSelect: ((page: number | undefined) => void) | undefined;
    selectedPage?: number;
}

const PagesSection: React.FC<PagesSectionProps> = ({ pages, fileType, onSelect, selectedPage }) => {
    const isPlusPageSelected = React.useMemo(() => {
        return (
            pages.length > MAX_PAGES_TO_SHOW &&
            selectedPage != null &&
            pages.slice(MAX_PAGES_TO_SHOW).some(p => p.page === selectedPage)
        );
    }, [pages, selectedPage]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
            }}
        >
            {pages.length <= MAX_PAGES_TO_SHOW ? (
                pages.map(p => (
                    <CitedSourceBoxPage
                        key={p.rank}
                        page={p.page}
                        rank={p.rank}
                        fileType={fileType}
                        onSelect={onSelect}
                        isSelected={selectedPage === p.page}
                    />
                ))
            ) : (
                <>
                    {pages.slice(0, MAX_PAGES_TO_SHOW).map(p => (
                        <CitedSourceBoxPage
                            key={p.rank}
                            page={p.page}
                            rank={p.rank}
                            fileType={fileType}
                            onSelect={onSelect}
                            isSelected={selectedPage === p.page}
                        />
                    ))}
                    <CitedSourceBoxPagePlus
                        count={pages.length - MAX_PAGES_TO_SHOW}
                        isSomeSelected={isPlusPageSelected}
                    />
                </>
            )}
        </Box>
    );
};

interface CitedSourceBoxPageProps {
    page: number;
    rank: number;
    fileType: FileType | undefined;
    onSelect: ((page: number | undefined) => void) | undefined;
    isSelected: boolean;
}

const CitedSourceBoxPage: React.FC<CitedSourceBoxPageProps> = ({ page, rank, fileType, onSelect, isSelected }) => {
    const handleClick = React.useMemo(() => (onSelect != null ? () => onSelect(page) : undefined), [onSelect, page]);
    return (
        <Typography
            onClick={handleClick}
            noWrap
            sx={{
                color: "neutrals.60",
                px: 0.25,
                border: 1,
                borderColor: isSelected ? "selection.main" : "neutrals.20",
                borderRadius: 1,
                cursor: onSelect != null ? "pointer" : "default",
                display: "flex",
                alignItems: "center",
                columnGap: 0.5,
                "&:hover": {
                    bgcolor: onSelect != null ? "action.hover" : "transparent",
                },
            }}
        >
            <Box
                component="span"
                sx={{
                    color: "citations.main",
                    typography: "caption",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                }}
            >{`[${rank + 1}]`}</Box>
            <Box
                component="span"
                sx={{
                    color: "neutrals.60",
                    typography: "caption",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                }}
            >
                {fileType === "xlsx" ? `Sheet ${page}` : `Pg. ${page}`}
            </Box>
        </Typography>
    );
};

interface CitedSourceBoxPagePlusProps {
    count: number;
    isSomeSelected: boolean;
}

const CitedSourceBoxPagePlus: React.FC<CitedSourceBoxPagePlusProps> = ({ count, isSomeSelected }) => {
    return (
        <Typography
            sx={{
                color: "neutrals.60",
                px: 0.25,
                border: 1,
                borderColor: isSomeSelected ? "selection.main" : "neutrals.20",
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
                columnGap: 0.5,
            }}
        >
            <Box
                component="span"
                sx={{
                    color: "citations.main",
                    typography: "caption",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                }}
            >{`+${count}`}</Box>
        </Typography>
    );
};
