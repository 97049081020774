import * as React from "react";
import {
    Box,
    Dialog,
    DialogContent,
    Typography,
    TextField,
    IconButton,
    useTheme,
    alpha,
    Skeleton,
    CircularProgress,
} from "@mui/material";
import { Message, SearchNormal1, Warning2 } from "iconsax-react";
import { useQuery } from "@tanstack/react-query";
import { recentHistoryQueryOptions, searchHistoryQueryOptions } from "../chat/queryOptions";
import { SearchHistoryItem } from "../backend-client/generated/types.gen";
import { ChatHistoryItem } from "../backend-client/generated/types.gen";
import { Close } from "@mui/icons-material";
import { useDebounce } from "../hooks/useDebounce";
import { getScrollbarSx } from "../shared/scrollbarProps";
import { getSearchUrl } from "../search/url";

type HistoryType = "all" | "chats" | "searches";

interface HistoryDialogProps {
    open: boolean;
    defaultTab?: HistoryType;
    onClose: () => void;
}

const FILTER_TABS: { type: HistoryType; label: string }[] = [
    { type: "all", label: "All" },
    { type: "chats", label: "Chats" },
    { type: "searches", label: "Search" },
];

export const HistoryDialog = ({ open, defaultTab, onClose }: HistoryDialogProps) => {
    const [selectedType, setSelectedType] = React.useState<HistoryType>(defaultTab ?? "all");
    const [searchQuery, setSearchQuery] = React.useState("");
    const debouncedSearch = useDebounce(searchQuery, 300);
    const theme = useTheme();
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (open) {
            requestAnimationFrame(() => {
                inputRef.current?.focus();
            });
        }
    }, [open]);

    const handleClearSearch = React.useCallback(() => {
        onClose();
        setSearchQuery("");
    }, [onClose]);

    const recentHistoryQuery = useQuery({
        ...recentHistoryQueryOptions(selectedType),
        enabled: debouncedSearch.trim().length === 0 && open,
    });
    const searchHistoryQuery = useQuery({
        ...searchHistoryQueryOptions(debouncedSearch, selectedType),
        enabled: debouncedSearch.trim().length > 0 && open,
    });

    const historyItems = React.useMemo(
        () => (debouncedSearch.trim().length > 0 ? searchHistoryQuery.data : recentHistoryQuery.data) ?? [],
        [debouncedSearch, searchHistoryQuery.data, recentHistoryQuery.data],
    );
    const isSearching = debouncedSearch.trim().length > 0;
    const groupedItems = useMaybeGroupedItems(historyItems, isSearching);

    const shadowColor = alpha(theme.palette.surface[50], 0.5);

    const isLoading = React.useMemo(
        () => (isSearching ? searchHistoryQuery.isLoading : recentHistoryQuery.isLoading),
        [isSearching, searchHistoryQuery.isLoading, recentHistoryQuery.isLoading],
    );

    const hasError = React.useMemo(
        () => (isSearching ? searchHistoryQuery.isError : recentHistoryQuery.isError),
        [isSearching, searchHistoryQuery.isError, recentHistoryQuery.isError],
    );

    const flattenedItems = React.useMemo(() => {
        if (!groupedItems) return historyItems;
        return Object.values(groupedItems).flat();
    }, [groupedItems, historyItems]);

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent) => {
            if (flattenedItems.length === 0) return;

            switch (e.key) {
                case "ArrowDown":
                    e.preventDefault();
                    setSelectedIndex(prev => (prev + 1) % flattenedItems.length);
                    break;
                case "ArrowUp":
                    e.preventDefault();
                    setSelectedIndex(prev => (prev - 1 + flattenedItems.length) % flattenedItems.length);
                    break;
                case "Enter":
                    if (selectedIndex >= 0) {
                        const selectedItem = flattenedItems[selectedIndex];
                        window.location.href = getHistoryItemUrl(selectedItem);
                    }
                    break;
            }
        },
        [flattenedItems, selectedIndex],
    );

    React.useEffect(() => {
        setSelectedIndex(-1);
    }, [searchQuery]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onKeyDown={handleKeyDown}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: shadowColor,
                    },
                },
            }}
            sx={{
                "& .MuiDialog-paper": {
                    border: 1,
                    borderColor: "neutrals.30",
                    borderRadius: 3,
                    height: 480,
                    width: 700,
                    maxWidth: 700,
                    // boxShadow: "0px 8px 32px rgba(0, 0, 0, 0.2)",
                    boxShadow: "none",
                },
            }}
        >
            <DialogContent
                sx={{
                    px: 2.5,
                    py: 2,
                    height: "100%",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <TextField
                    autoFocus
                    fullWidth
                    inputRef={inputRef}
                    placeholder="Search history…"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    inputProps={{ style: { height: 40 } }}
                    InputProps={{
                        style: { height: 40 },
                        endAdornment: (
                            <IconButton onClick={handleClearSearch} size="small" edge="end">
                                <Close sx={{ color: "neutrals.50", width: 16, height: 16 }} />
                            </IconButton>
                        ),
                    }}
                    sx={{
                        mb: 1,
                        "& .MuiOutlinedInput-root": {
                            borderRadius: 5,
                        },
                        "& .MuiInputBase-input": {
                            typography: "body2",
                        },
                    }}
                />

                <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
                    {FILTER_TABS.map(({ type, label }) => (
                        <Box
                            key={type}
                            onClick={() => setSelectedType(type)}
                            sx={{
                                px: 2,
                                py: 0.5,
                                borderRadius: 6,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                                bgcolor: selectedType === type ? "neutrals.60" : "neutrals.25",
                                color: selectedType === type ? "white" : "neutrals.50",
                                minWidth: 60,
                                "&:hover": {
                                    bgcolor: selectedType === type ? "neutrals.70" : "neutrals.30",
                                },
                            }}
                        >
                            <Typography fontWeight={selectedType === type ? 550 : undefined}>{label}</Typography>
                        </Box>
                    ))}
                </Box>

                <Box sx={{ flex: 1, overflowY: "auto", ...getScrollbarSx("surface.0") }}>
                    {hasError ? (
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1 }}>
                            <Warning2 size={32} variant="Bold" color={theme.palette.error.main} />
                        </Box>
                    ) : isLoading ? (
                        isSearching ? (
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                {Array.from({ length: 8 }).map((_, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            borderRadius: 2,
                                            py: 0.5,
                                            bgcolor: "background.paper",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 0.5,
                                        }}
                                    >
                                        <Skeleton variant="rectangular" width={20} height={20} />
                                        <Skeleton
                                            variant="text"
                                            sx={{ width: Math.floor(80 + Math.random() * 300), typography: "body1" }}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        ) : (
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1 }}>
                                <CircularProgress size={32} sx={{ color: "neutrals.50", mb: 2 }} />
                            </Box>
                        )
                    ) : historyItems.length === 0 ? (
                        <Typography color="neutrals.50" variant="body2">
                            No results
                        </Typography>
                    ) : groupedItems == null ? (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                            {historyItems.map((item, index) => (
                                <HistoryItem key={item.id} item={item} isSelected={index === selectedIndex} />
                            ))}
                        </Box>
                    ) : (
                        Object.entries(groupedItems).map(([group, items]) => {
                            const startIndex = flattenedItems.indexOf(items[0]);
                            return (
                                items.length > 0 && (
                                    <Box key={group} sx={{ display: "flex", flexDirection: "column", mb: 2, gap: 1 }}>
                                        <Typography
                                            variant="caption"
                                            color="neutrals.50"
                                            fontWeight={550}
                                            sx={{ ml: 0.5 }}
                                        >
                                            {group}
                                        </Typography>
                                        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                                            {items.map((item, index) => (
                                                <HistoryItem
                                                    key={item.id}
                                                    item={item}
                                                    isSelected={startIndex + index === selectedIndex}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                )
                            );
                        })
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

function useMaybeGroupedItems(items: (ChatHistoryItem | SearchHistoryItem)[], isSearching: boolean) {
    const groupedItems = React.useMemo(() => {
        if (isSearching) {
            return undefined;
        }

        const groups: Record<string, (ChatHistoryItem | SearchHistoryItem)[]> = {
            Today: [],
            Yesterday: [],
            "Last 7 Days": [],
            "Last Month": [],
            "Last Year": [],
            Older: [],
        };

        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);
        const lastMonth = new Date(today);
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        const lastYear = new Date(today);
        lastYear.setFullYear(lastYear.getFullYear() - 1);

        items.forEach(item => {
            const itemDate = new Date(item.created_at);

            if (itemDate >= today) {
                groups.Today.push(item);
            } else if (itemDate >= yesterday) {
                groups.Yesterday.push(item);
            } else if (itemDate >= lastWeek) {
                groups["Last 7 Days"].push(item);
            } else if (itemDate >= lastMonth) {
                groups["Last Month"].push(item);
            } else if (itemDate >= lastYear) {
                groups["Last Year"].push(item);
            } else {
                groups.Older.push(item);
            }
        });

        return groups;
    }, [isSearching, items]);

    return groupedItems;
}

interface HistoryItemProps {
    item: ChatHistoryItem | SearchHistoryItem;
    isSelected?: boolean;
}

const HistoryItem = React.memo(({ item, isSelected }: HistoryItemProps) => {
    const href = getHistoryItemUrl(item);

    return (
        <Box
            component="a"
            href={href}
            sx={{
                borderRadius: 2,
                py: 0.75,
                px: 1,
                bgcolor: isSelected ? "surface.25" : "background.paper",
                color: "neutrals.70",
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
                textDecoration: "none",
                "&:hover": {
                    bgcolor: "surface.25",
                    color: "neutrals.90",
                },
                transition: "background-color 0.2s",
            }}
        >
            {item.type === "chat" ? (
                <Message size={20} style={{ flexShrink: 0 }} />
            ) : (
                <SearchNormal1 size={20} style={{ flexShrink: 0 }} />
            )}
            <Typography color="neutrals.70">{item.title}</Typography>
        </Box>
    );
});

HistoryItem.displayName = "HistoryItem";

function parseDate(date: string | undefined): Date | undefined {
    if (date == null) {
        return undefined;
    }
    return new Date(date);
}

export const getHistoryItemUrl = (item: ChatHistoryItem | SearchHistoryItem): string => {
    if (item.type === "chat") {
        return `/chat/${item.id}`;
    }

    return getSearchUrl(
        item.title,
        (item.search_filters["projects"] as string[] | undefined) ?? [],
        (item.search_filters["sources"] as string[] | undefined) ?? [],
        (item.search_filters["file_types"] as string[] | undefined) ?? [],
        parseDate(item.search_filters["earliest_date"] as string | undefined),
    );
};
