import * as React from "react";
import { Typography, Box, CircularProgress, useTheme, Button } from "@mui/material";
import { PreviewSectionLayout } from "../../shared/previewSectionLayout";
import { useQuery } from "@tanstack/react-query";
import { deepResearchRunQueryOptions, pdfSasUrlQueryOptions } from "../queryOptions";
import { DocumentText, SearchNormal1, User } from "iconsax-react";
import { scrollbarSx } from "../../shared/scrollbarProps";
import {
    ExecutionGoalWithProgressSchema,
    AssistantChatMessageSourceSchema,
    AssistantChatMessageSourcePage,
    DeepResearchRunResponseSchema,
} from "../../backend-client/generated/types.gen";
import { ReactComponent as Logo } from "../../assets/logomark-primary.svg";
import { CitedSourcePage } from "../../shared/citedSourceBox";
import { CitedSourceBox } from "../../shared/citedSourceBox";
import { FullScreenPDFDialog } from "../../search/fullscreenPdfDialog";

interface DeepResearchPreviewSectionProps {
    deepResearchRunId: string;
    onClose: () => void;
}

type TabValue = "research" | "sources";

export const DeepResearchPreviewSection: React.FC<DeepResearchPreviewSectionProps> = ({
    deepResearchRunId,
    onClose,
}) => {
    const [activeTab, setActiveTab] = React.useState<TabValue>("research");
    const [selectedSource, setSelectedSource] = React.useState<AssistantChatMessageSourceSchema | null>(null);
    const [selectedPage, setSelectedPage] = React.useState<number | undefined>(undefined);
    const [fullScreenDialogOpen, setFullScreenDialogOpen] = React.useState(false);

    const { data: deepResearchRun, isLoading } = useQuery(deepResearchRunQueryOptions(deepResearchRunId));

    const header = React.useMemo(() => {
        return (
            <Typography variant="h6" color="secondary.main" noWrap>
                Deep Research
            </Typography>
        );
    }, []);

    const setActiveTabToResearch = React.useCallback(() => {
        setActiveTab("research");
    }, []);

    const setActiveTabToSources = React.useCallback(() => {
        setActiveTab("sources");
    }, []);

    const tabs = React.useMemo(() => {
        const tabSx = {
            px: 2,
            py: 0.75,
            borderRadius: 6,
            minWidth: "unset",
        };

        const isSelectedSx = {
            color: "secondary.main",
            bgcolor: "surface.0",
            border: 1,
            borderColor: "neutrals.30",
            fontWeight: 450,
            "&:hover": {
                bgcolor: "surface.0",
            },
        };

        const isNotSelectedSx = {
            color: "neutrals.80",
            bgcolor: "transparent",
            border: 0,
            "&:hover": {
                bgcolor: "transparent",
            },
        };

        return (
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                    borderBottom: 1,
                    pb: 1.5,
                    borderColor: "neutrals.30",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        borderRadius: 6,
                        bgcolor: "surface.50",
                        p: 0.5,
                    }}
                >
                    <Button
                        onClick={setActiveTabToResearch}
                        sx={{
                            ...tabSx,
                            ...(activeTab === "research" ? isSelectedSx : isNotSelectedSx),
                        }}
                    >
                        Research
                    </Button>
                    <Button
                        onClick={setActiveTabToSources}
                        sx={{
                            ...tabSx,
                            ...(activeTab === "sources" ? isSelectedSx : isNotSelectedSx),
                        }}
                    >
                        Sources
                    </Button>
                </Box>
            </Box>
        );
    }, [activeTab, setActiveTabToResearch, setActiveTabToSources]);

    const theme = useTheme();

    const messagesWithTypes = useDeepResearchMessages(deepResearchRun);

    const messagesContent = React.useMemo(() => {
        if (isLoading) {
            return (
                <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
                    <CircularProgress size={24} />
                </Box>
            );
        }

        if (!deepResearchRun) {
            return (
                <Typography variant="body2" color="neutrals.70">
                    No data available
                </Typography>
            );
        }

        const isInProgress = deepResearchRun.result == null;
        const shouldAnimate = isInProgress;

        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, ...scrollbarSx }}>
                {messagesWithTypes.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            gap: 2,
                            p: 1,
                            bgcolor: "surface.25",
                            borderRadius: 2,
                            ...(shouldAnimate && {
                                animation: "fadeIn 0.5s ease-in-out",
                                "@keyframes fadeIn": {
                                    "0%": {
                                        opacity: 0,
                                        transform: "translateY(10px)",
                                    },
                                    "100%": {
                                        opacity: 1,
                                        transform: "translateY(0)",
                                    },
                                },
                                animationFillMode: "backwards",
                                animationDelay: `${index * 0.1}s`,
                            }),
                        }}
                    >
                        {item.messageType === "query" ? (
                            <SearchNormal1 size={20} color={theme.palette.secondary.main} style={{ flexShrink: 0 }} />
                        ) : item.messageType === "system" ? (
                            <Logo style={{ flexShrink: 0, width: 20, height: 20 }} />
                        ) : item.messageType === "reading" ? (
                            <DocumentText size={20} color={theme.palette.secondary.main} style={{ flexShrink: 0 }} />
                        ) : (
                            <User size={20} color={theme.palette.secondary.main} style={{ flexShrink: 0 }} />
                        )}
                        <Typography variant="body2">{item.message}</Typography>
                    </Box>
                ))}
            </Box>
        );
    }, [deepResearchRun, isLoading, theme.palette.secondary.main, messagesWithTypes]);

    const { data: loadedPdfUrl } = useQuery({
        ...pdfSasUrlQueryOptions({
            blobName: selectedSource?.blob_name ?? selectedSource?.name ?? "",
            isUserFile: selectedSource?.is_user_file ?? false,
        }),
        enabled: selectedSource != null,
    });

    const handleSourceSelect = React.useCallback(
        (sourceIdx: number, page: number | undefined) => {
            if (deepResearchRun?.result?.sources == null) return;

            const source = deepResearchRun.result.sources[sourceIdx];
            setFullScreenDialogOpen(true);
            setSelectedSource(source);
            setSelectedPage(page);
        },
        [deepResearchRun],
    );

    const handleFullScreenDialogClose = React.useCallback(() => {
        setFullScreenDialogOpen(false);
    }, []);

    const sourcesContent = React.useMemo(() => {
        if (isLoading) {
            return (
                <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
                    <CircularProgress size={24} sx={{ color: "secondary.main" }} />
                </Box>
            );
        }

        if (deepResearchRun == null || deepResearchRun.result == null) {
            return (
                <Typography variant="body2" color="neutrals.70">
                    No sources available
                </Typography>
            );
        }

        const sources = deepResearchRun.result.sources ?? [];

        if (sources.length === 0) {
            return (
                <Typography variant="body2" color="neutrals.70">
                    No sources found
                </Typography>
            );
        }

        return (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, ...scrollbarSx, flexWrap: "wrap" }}>
                {sources.map((source: AssistantChatMessageSourceSchema, index: number) => (
                    <ChatCitedSourceBox
                        key={index}
                        fileName={source.name}
                        fileType={source.file_type}
                        pages={source.pages ?? EMPTY_PAGES}
                        blobName={source.blob_name ?? undefined}
                        isUserFile={source.is_user_file ?? false}
                        sourceIdx={index}
                        timeCreated={source.time_created ?? undefined}
                        onSelect={handleSourceSelect}
                        selectedPage={selectedSource?.name === source.name ? selectedPage : undefined}
                    />
                ))}

                {/* Add FullScreenPDFDialog */}
                {selectedSource != null && loadedPdfUrl != null && (
                    <FullScreenPDFDialog
                        open={fullScreenDialogOpen}
                        onClose={handleFullScreenDialogClose}
                        pdfName={selectedSource.name}
                        fileType={selectedSource.file_type ?? undefined}
                        pdfUrl={loadedPdfUrl}
                        pageNumbers={selectedSource.pages?.map(p => p.page) ?? []}
                        initialPage={selectedPage ?? 1}
                    />
                )}
            </Box>
        );
    }, [
        isLoading,
        deepResearchRun,
        selectedSource,
        loadedPdfUrl,
        fullScreenDialogOpen,
        handleFullScreenDialogClose,
        selectedPage,
        handleSourceSelect,
    ]);

    return (
        <PreviewSectionLayout header={header} onClose={onClose}>
            {tabs}
            <Box
                sx={{
                    overflowY: "auto",
                    pb: 3,
                    ...scrollbarSx,
                }}
            >
                {activeTab === "research" ? messagesContent : sourcesContent}
            </Box>
        </PreviewSectionLayout>
    );
};

interface ChatCitedSourceBoxProps {
    fileName: string;
    fileType: AssistantChatMessageSourceSchema["file_type"];
    pages: AssistantChatMessageSourcePage[];
    blobName: string | undefined;
    isUserFile: boolean;
    sourceIdx: number;
    timeCreated: string | undefined;
    onSelect: ((sourceIdx: number, page: number | undefined) => void) | undefined;
    selectedPage: number | undefined;
}

const EMPTY_PAGES: CitedSourcePage[] = [];

const ChatCitedSourceBox: React.FC<ChatCitedSourceBoxProps> = ({
    fileName,
    fileType,
    pages,
    blobName,
    sourceIdx,
    isUserFile,
    timeCreated,
    selectedPage,
    onSelect,
}) => {
    const handleSelect = React.useCallback(
        (page: number | undefined) => (onSelect != null ? onSelect(sourceIdx, page) : undefined),
        [onSelect, sourceIdx],
    );
    const citedSourcePages = React.useMemo((): CitedSourcePage[] => {
        return pages != null ? pages.map<CitedSourcePage>(p => ({ page: p.page, rank: p.rank })) : EMPTY_PAGES;
    }, [pages]);

    const blobNameForThumbnail = React.useMemo(() => {
        return blobName?.replace(".pdf", ".jpg");
    }, [blobName]);

    return (
        <CitedSourceBox
            fileName={fileName}
            timeCreated={timeCreated}
            fileType={fileType ?? undefined}
            pages={citedSourcePages}
            blobName={blobNameForThumbnail}
            isUserFile={isUserFile}
            onSelect={handleSelect}
            selectedPage={selectedPage}
            sx={theme => ({
                width: `calc(50% - ${theme.spacing(2)})`,
                maxWidth: `calc(50% - ${theme.spacing(2)})`,
            })}
        />
    );
};

interface DeepResearchMessage {
    message: string;
    messageType: "query" | "system" | "user" | "reading";
}

export const useDeepResearchMessages = (
    deepResearchRun: DeepResearchRunResponseSchema | undefined,
): DeepResearchMessage[] => {
    return React.useMemo(() => {
        if (deepResearchRun == null) {
            return [];
        }

        const messagesWithTypes: DeepResearchMessage[] = [];

        // First add original query
        messagesWithTypes.push({
            message: deepResearchRun.query,
            messageType: "user",
        });

        // Add message from each goal and its queries
        deepResearchRun.execution.goals_with_progress.forEach((goal: ExecutionGoalWithProgressSchema) => {
            // Add goal explanation
            messagesWithTypes.push({
                message: goal.goal.user_facing_explanation,
                messageType: "system",
            });

            // Add messages from each query within the goal
            goal.queries.forEach(query => {
                if (query.plan.query) {
                    messagesWithTypes.push({
                        message: `Searching for: ${query.plan.query}`,
                        messageType: "query",
                    });
                }
                if (query.result != null) {
                    if (query.result.sources.length === 0) {
                        messagesWithTypes.push({
                            message: "No results found. Continuing with the next query.",
                            messageType: "reading",
                        });
                    } else {
                        messagesWithTypes.push({
                            message: `Reading relevant content in ${
                                query.result.sources.length > 2
                                    ? `${query.result.sources
                                          .slice(0, 3)
                                          .map(s => `"${s.name}"`)
                                          .join(
                                              ", ",
                                          )}${query.result.sources.length > 3 ? ` and ${query.result.sources.length - 3} more files` : ""}`
                                    : query.result.sources.length === 2
                                      ? `${query.result.sources[0].name} and ${query.result.sources[1].name}`
                                      : query.result.sources[0].name
                            }`,
                            messageType: "reading",
                        });
                    }
                }
            });

            // Add goal summary if available
            if (goal.result?.user_facing_summary_message) {
                messagesWithTypes.push({
                    message: goal.result.user_facing_summary_message,
                    messageType: "system",
                });
            }
        });

        // Finally add the result if available
        if (deepResearchRun.result) {
            messagesWithTypes.push({
                message: deepResearchRun.result.user_facing_thinking,
                messageType: "system",
            });
        }

        return messagesWithTypes;
    }, [deepResearchRun]);
};
