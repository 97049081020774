import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { identifyUserForSession } from "../auth/userIdentification";
import { useSubdomainRedirect, SUBDOMAIN_REDIRECT_QUERY_PARAM } from "../useSubdomainRedirect";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useIsLoggedIn } from "../auth/authentication";
import { getAsyncValue } from "../utils/async";
import { isProduction } from "../utils/isProduction";

interface ProtectedRouteProps {
    authenticationPath: string;
    /** Whether the authentication path is external (hosted on a different domain) to the app. */
    isExternalPath: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ authenticationPath, isExternalPath }) => {
    const [isLoggedIn] = useIsLoggedIn();

    useSubdomainRedirect();

    const isLoggedInValue = getAsyncValue(isLoggedIn);

    React.useEffect(() => {
        if (isLoggedInValue != null && isLoggedInValue) {
            void identifyUserForSession();
        }
    }, [isLoggedInValue]);

    const hasExternallyRedirected = React.useRef(false);
    React.useEffect(() => {
        const isLoggedOut = isLoggedInValue != null && !isLoggedInValue;
        if (isExternalPath && !hasExternallyRedirected.current && isLoggedOut) {
            hasExternallyRedirected.current = true;
            console.log("Redirecting from protected route to", authenticationPath);
            window.location.href = authenticationPath;
        }
    }, [isLoggedInValue, isExternalPath, authenticationPath]);

    const hasRedirectParam = React.useMemo(
        () => new URLSearchParams(window.location.search).has(SUBDOMAIN_REDIRECT_QUERY_PARAM),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [window.location.search],
    );

    if (isLoggedIn.state === "loading" || isLoggedIn.state === "unloaded") {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100vw",
                }}
            >
                {!isProduction && <Typography>Loading logged in state...</Typography>}
            </Box>
        );
    } else if (isLoggedIn.state === "loaded" && isLoggedIn.data) {
        return <Outlet />;
    } else if (hasRedirectParam) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100vw",
                }}
            >
                {!isProduction && (
                    <Typography>Redirecting via redirect parameter to {authenticationPath}...</Typography>
                )}
            </Box>
        );
    } else {
        if (!isExternalPath) {
            return <Navigate to={authenticationPath} />;
        } else {
            return (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw",
                    }}
                >
                    <CircularProgress sx={{ color: "secondary.main", width: 48, height: 48 }} />
                    {!isProduction && (
                        <Typography>Redirecting to external log in page {authenticationPath}...</Typography>
                    )}
                </Box>
            );
        }
    }
};
