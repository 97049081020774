import * as React from "react";
import { Async } from "./async";

type UseBooleanOutput = {
    value: boolean;
    setTrue: () => void;
    setFalse: () => void;
    updateValue: React.Dispatch<React.SetStateAction<boolean>>;
    toggleValue: () => void;
};

export const useBoolean = (initialValue = false): UseBooleanOutput => {
    const [value, setValue] = React.useState(initialValue);

    const setTrue = React.useCallback(() => setValue(true), []);
    const setFalse = React.useCallback(() => setValue(false), []);
    const toggleValue = React.useCallback(() => setValue(prevValue => !prevValue), []);

    return {
        value,
        setTrue,
        setFalse,
        updateValue: setValue,
        toggleValue,
    };
};

export const useOnMount = (callback: () => void): void => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useEffect(callback, []);
};

export const usePrevious = <T,>(value: T): T | undefined => {
    const ref = React.useRef<T>();

    React.useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

function shallowEqual(arr1: React.DependencyList, arr2: React.DependencyList) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
    return true;
}

export function useAsyncData<T>(asyncFunction: () => Promise<T>, deps: React.DependencyList) {
    const [state, setState] = React.useState<{
        data: Async<T>;
        deps: React.DependencyList;
    }>({ data: { state: "unloaded" }, deps });

    const timesLoaded = React.useRef(0);

    const isUnmounted = React.useRef(false);

    React.useEffect(() => {
        isUnmounted.current = false;
        return () => {
            isUnmounted.current = true;
        };
    }, []);

    const loadData = React.useCallback(() => {
        const timesLoadedThisTime = ++timesLoaded.current;

        setState({ data: { state: "loading" }, deps });

        const asyncPromise = asyncFunction();

        asyncPromise
            .then(result => {
                if (!isUnmounted.current && timesLoaded.current === timesLoadedThisTime) {
                    setState({ data: { state: "loaded", data: result }, deps });
                }
            })
            .catch(error => {
                if (!isUnmounted.current && timesLoaded.current === timesLoadedThisTime) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    setState({ data: { state: "error", error }, deps });
                }
            });
        return asyncPromise;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps]);

    React.useEffect(() => {
        void loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps]);

    const fixedData = React.useMemo(() => {
        if (shallowEqual(state.deps, deps)) {
            return state.data;
        } else {
            return { state: "loading" } as Async<T>;
        }
    }, [state.data, state.deps, deps]);

    return [fixedData, loadData] as const;
}
