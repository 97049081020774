import * as React from "react";
import { Box, Theme, SxProps, useTheme, Button, Typography } from "@mui/material";
import { GlobalSearch, Information } from "iconsax-react";

interface SearchExternallyCalloutProps {
    onSearchWeb: () => void;
    sx?: SxProps<Theme>;
    buttonSx?: SxProps<Theme>;
}

export const SearchExternallyCallout: React.FC<SearchExternallyCalloutProps> = ({ onSearchWeb, sx, buttonSx }) => {
    const theme = useTheme();

    return (
        <Box
            sx={[
                {
                    height: 48,
                    flexBasis: 48,
                    display: "flex",
                    alignItems: "center",
                    px: 1.5,
                    borderRadius: 1,
                    bgcolor: "surface.25",
                    columnGap: 1,
                    flexShrink: 0,
                    flexGrow: 0,
                    justifyContent: "space-between",
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Information size={20} color={theme.palette.neutrals[80]} />
                <Typography variant="body2" sx={{ color: "neutrals.80" }}>
                    Limited relevant internal sources available
                </Typography>
            </Box>
            <Button
                variant="outlined"
                onClick={onSearchWeb}
                startIcon={<GlobalSearch size={16} color={theme.palette.secondary.main} variant="Bold" />}
                color="secondary"
                size="small"
                sx={[
                    {
                        border: 1,
                        borderColor: "neutrals.25",
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    ...(Array.isArray(buttonSx) ? buttonSx : [buttonSx]),
                ]}
            >
                <Typography variant="caption" sx={{ color: "secondary.main" }} fontWeight={500}>
                    Search externally
                </Typography>
            </Button>
        </Box>
    );
};
