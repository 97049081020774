import React from "react";
import { Skeleton } from "@mui/material";
import { FileSourceItemLayout } from "./FileSourceItemLayout";

const NO_OP = () => {
    return;
};

export function FileSourceItemSkeleton({ showPagesAndContent }: { showPagesAndContent: boolean }) {
    // Thumbnail skeleton
    const thumbnailSlot = <Skeleton variant="rectangular" width={35} height={43} />;

    // Title skeleton
    const titleSlot = <Skeleton variant="text" width="60%" height={28} />;

    // Metadata skeleton (show a couple lines of small placeholders)
    const metadataSlot = (
        <>
            <Skeleton variant="text" width="14%" height={16} />
            <Skeleton variant="text" width="12%" height={16} />
            <Skeleton variant="text" width="10%" height={16} />
        </>
    );

    // Content preview skeleton
    const contentPreviewSlot = showPagesAndContent ? (
        <>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
        </>
    ) : null;

    // Pages skeleton
    const pagesSlot = showPagesAndContent ? (
        <>
            <Skeleton variant="rectangular" width={40} height={16} />
            <Skeleton variant="rectangular" width={40} height={16} />
        </>
    ) : null;

    // Chat button placeholder (either omit or show a small skeleton)
    const focusedChatButtonSlot = null;

    return (
        <FileSourceItemLayout
            thumbnailSlot={thumbnailSlot}
            titleSlot={titleSlot}
            metadataSlot={metadataSlot}
            contentPreviewSlot={contentPreviewSlot}
            pagesSlot={pagesSlot}
            focusedChatButtonSlot={focusedChatButtonSlot}
            onClick={NO_OP}
        />
    );
}
