import React from "react";
import { Box } from "@mui/material";

export const ProjectsGridLayout = React.memo(
    ({ children, button }: { children: React.ReactNode; button?: React.ReactNode }) => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                mb: 3,
                alignItems: "stretch",
            }}
        >
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    rowGap: 0.5,
                    columnGap: 2,
                    width: "100%",
                    mb: button != null ? 2 : 0,
                }}
            >
                {children}
            </Box>
            {button}
        </Box>
    ),
);

ProjectsGridLayout.displayName = "ProjectsGridLayout";
