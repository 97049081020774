import * as React from "react";
import { Box, SxProps, Theme } from "@mui/material";

interface PeopleAnswerLayoutProps {
    cardWidth: number;
    children: React.ReactNode;
    sx?: SxProps<Theme>;
}

export const PeopleAnswerLayout = React.memo(({ cardWidth, children, sx }: PeopleAnswerLayoutProps) => {
    return (
        <Box
            sx={[
                theme => ({
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 1.5,
                    // 4px is for the borders of the cards
                    width: `calc(${2 * cardWidth}px + ${theme.spacing(1.5)} + 4px)`,
                }),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {children}
        </Box>
    );
});

PeopleAnswerLayout.displayName = "PeopleAnswerLayout";
