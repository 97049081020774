import * as React from "react";
import { Box, Typography } from "@mui/material";
import { Sources } from "./types";
import { SearchViewHeader } from "./searchViewHeader";
import { FileSourceItem } from "./fileSourceItem";

export const SourcesSection: React.FC<{
    sources: Sources | undefined;
    onSourceSelect: (idx: number, page: number | undefined) => void;
    focusedChatSourceIdxs: number[];
    onFocusedChatSourceIdxsChange: React.Dispatch<React.SetStateAction<number[]>>;
    selectedFileName?: string;
    selectedPage: number | undefined;
}> = ({
    sources,
    onSourceSelect,
    focusedChatSourceIdxs,
    onFocusedChatSourceIdxsChange,
    selectedFileName,
    selectedPage,
}) => {
    const onClickSource = React.useCallback(
        (idx: number, page: number | undefined) => {
            if (sources == null) {
                return;
            }
            onSourceSelect(idx, page);
        },
        [sources, onSourceSelect],
    );

    const focusedChatSourceIdxsSet = React.useMemo(() => new Set(focusedChatSourceIdxs), [focusedChatSourceIdxs]);

    const handleToggleFocusedChat = React.useCallback(
        (idx: number) => {
            if (focusedChatSourceIdxsSet.has(idx)) {
                onFocusedChatSourceIdxsChange(prev => prev.filter(i => i !== idx));
            } else {
                onFocusedChatSourceIdxsChange(prev => [...prev, idx]);
            }
        },
        [focusedChatSourceIdxsSet, onFocusedChatSourceIdxsChange],
    );

    if (sources == null) {
        return null;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 0.5 }}>
            <SearchViewHeader
                text="Documents"
                tooltip={`These are the (${Object.keys(sources).length}) sources that matched your search query`}
            />
            {Object.keys(sources).length > 0 ? (
                <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2, overflowX: "auto" }}>
                    {sources.map((source, index) => (
                        <FileSourceItem
                            key={index}
                            source={source}
                            idx={index}
                            onClick={onClickSource}
                            onToggleFocusedChat={handleToggleFocusedChat}
                            isAddedToFocusedChat={focusedChatSourceIdxsSet.has(index)}
                            isFileSelected={source.file_name === selectedFileName}
                            selectedPage={selectedPage}
                        />
                    ))}
                </Box>
            ) : (
                <Typography variant="body1">No results found.</Typography>
            )}
        </Box>
    );
};
