import Box from "@mui/material/Box";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Skeleton, Typography, useTheme, Avatar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
    SidebarRight,
    SidebarLeft,
    Home,
    Login,
    Logout,
    Add,
    Messages3,
    Home2,
    Message2,
    Folder,
    Setting2,
    SearchStatus,
} from "iconsax-react";
import "../App.css";
// import { ReactComponent as Logo } from "../assets/logomark-primary.svg";
// import SpurIcon from "../assets/spur.png";
import { useQuery } from "@tanstack/react-query";
import { chatsQueryOptions, currentUserQueryOptions, tenantQueryOptions } from "../chat/queryOptions";
import { useIsLoggedIn, useLogout } from "../auth/authentication";
import { ChatListSchema } from "../backend-client/generated";
import { comparatorOnFields } from "../utils/comparators";
import { scrollbarSx } from "../shared/scrollbarProps";
import { ReactComponent as Logo } from "../assets/logomark-primary-custom-color.svg";
import { DriveExplorerDialog } from "../chat/driveExplorerDialog";
import { useState, useCallback, useMemo, useEffect } from "react";
import throttle from "lodash-es/throttle";
import { HistoryDialog } from "../history/historyDialog";

const SIDEBAR_COLLAPSED_LOCAL_STORAGE_KEY = "newton.sidebarCollapsed";
const SIDEBAR_WIDTH_LOCAL_STORAGE_KEY = "newton.sidebarWidth";

const ACTION_BUTTON_SIZE = 24;

const MIN_SIDEBAR_WIDTH = 240;
const MAX_SIDEBAR_WIDTH = 400;
const COLLAPSED_WIDTH = 66;

export const Sidebar: React.FC = () => {
    const [isCollapsed, setIsCollapsed] = React.useState(
        localStorage.getItem(SIDEBAR_COLLAPSED_LOCAL_STORAGE_KEY) !== "false",
    );
    const [width, setWidth] = React.useState(() => {
        const savedWidth = localStorage.getItem(SIDEBAR_WIDTH_LOCAL_STORAGE_KEY);
        return savedWidth
            ? Math.min(Math.max(Number(savedWidth), MIN_SIDEBAR_WIDTH), MAX_SIDEBAR_WIDTH)
            : MIN_SIDEBAR_WIDTH;
    });
    const [isResizing, setIsResizing] = React.useState(false);
    const [isHistoryOpen, setIsHistoryOpen] = React.useState(false);

    const throttledSetWidth = useMemo(
        () =>
            throttle((newWidth: number) => {
                const clampedWidth = Math.min(Math.max(newWidth, MIN_SIDEBAR_WIDTH), MAX_SIDEBAR_WIDTH);
                setWidth(clampedWidth);
                localStorage.setItem(SIDEBAR_WIDTH_LOCAL_STORAGE_KEY, clampedWidth.toString());
            }, 16),
        [],
    );

    useEffect(() => {
        return () => {
            throttledSetWidth.cancel();
        };
    }, [throttledSetWidth]);

    const handleResizeStart = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setIsResizing(true);
    }, []);

    const handleResizeEnd = useCallback(() => {
        setIsResizing(false);
        throttledSetWidth.flush();
    }, [throttledSetWidth]);

    const handleResize = useCallback(
        (e: MouseEvent) => {
            if (isResizing && !isCollapsed) {
                throttledSetWidth(e.clientX);
            }
        },
        [isResizing, isCollapsed, throttledSetWidth],
    );

    React.useEffect(() => {
        if (isResizing) {
            window.addEventListener("mousemove", handleResize);
            window.addEventListener("mouseup", handleResizeEnd);

            return () => {
                window.removeEventListener("mousemove", handleResize);
                window.removeEventListener("mouseup", handleResizeEnd);
            };
        }
    }, [isResizing, handleResize, handleResizeEnd]);

    const navigate = useNavigate();

    const toggleSidebar = React.useCallback(() => {
        const newCollapsedValue = !isCollapsed;
        setIsCollapsed(newCollapsedValue);
        localStorage.setItem(SIDEBAR_COLLAPSED_LOCAL_STORAGE_KEY, newCollapsedValue.toString());
    }, [isCollapsed]);

    const handleClickNewTab = React.useCallback(() => {
        navigate("/");
    }, [navigate]);

    const handleClickChat = React.useCallback(() => {
        navigate("/chat");
    }, [navigate]);

    const location = useLocation();

    const handleOpenHistory = React.useCallback(() => {
        setIsHistoryOpen(true);
    }, []);

    const handleCloseHistory = React.useCallback(() => {
        setIsHistoryOpen(false);
    }, []);

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if CMD+G on Mac or Ctrl+G on Windows/Linux
            if ((event.ctrlKey || event.metaKey) && event.key === "g") {
                event.preventDefault();
                handleClickNewTab();
            }
            if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key.toLowerCase() === "o") {
                event.preventDefault();
                // if (location.pathname.startsWith("/chat")) {
                handleClickChat();
                // } else {
                // handleClickNewTab();
                // }
            }
            // Add history shortcut (CMD+K or Ctrl+K)
            if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "k") {
                event.preventDefault();
                handleOpenHistory();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleClickNewTab, handleClickChat, handleOpenHistory, location.pathname]);

    const handleSignOut = useLogout();

    const [isLoggedIn] = useIsLoggedIn();

    const handleSignIn = React.useCallback(() => {
        navigate("/login");
    }, [navigate]);

    const theme = useTheme();

    const iconColor = theme.palette.neutrals[50];

    const currentUserQuery = useQuery(currentUserQueryOptions);

    const initial = React.useMemo(() => {
        if (currentUserQuery.data == null) {
            return undefined;
        }
        return currentUserQuery.data.first_name.charAt(0);
    }, [currentUserQuery.data]);

    const handleSettings = React.useCallback(() => {
        navigate("/settings");
    }, [navigate]);

    const { data: tenant } = useQuery(tenantQueryOptions);

    return (
        <>
            <Box
                sx={{
                    pt: 3,
                    flex: "0 0 auto",
                    flexBasis: isCollapsed ? COLLAPSED_WIDTH : width,
                    width: isCollapsed ? COLLAPSED_WIDTH : width,
                    minHeight: 0,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    transition: isResizing ? "none" : "flex-basis 0.3s ease, background-color 0.3s ease",
                    bgcolor: "primary.main",
                    border: 1,
                    borderRadius: 3,
                    borderColor: "neutrals.30",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        px: 2,
                        overflowY: "hidden",
                        rowGap: 2,
                        overflowX: "hidden",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: isCollapsed ? "center" : "stretch",
                            overflow: "hidden",
                        }}
                    >
                        <CustomerComponent isCollapsed={isCollapsed} />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: isCollapsed ? "center" : "stretch",
                            maxWidth: "100%",
                            rowGap: 2,
                            overflow: "hidden",
                            transition: "align-items 0.3s ease",
                            overflowX: "hidden",
                        }}
                    >
                        <HistoryButton isCollapsed={isCollapsed} onClick={handleOpenHistory} />
                        <HomeButton isCollapsed={isCollapsed} />
                        <ChatButton isCollapsed={isCollapsed} />
                        {(tenant?.can_chat_with_docs ?? false) && <DriveButton isCollapsed={isCollapsed} />}
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        gap: 1,
                        pb: 2,
                    }}
                >
                    <Box sx={{ borderTop: 1, borderColor: "neutrals.30", my: 0.5 }} />

                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", gap: 1, px: 2 }}>
                        <ActionButton
                            isCollapsed={isCollapsed}
                            icon={
                                isCollapsed ? (
                                    <SidebarRight
                                        color={iconColor}
                                        size={ACTION_BUTTON_SIZE}
                                        style={{ flexShrink: 0 }}
                                    />
                                ) : (
                                    <SidebarLeft
                                        color={iconColor}
                                        size={ACTION_BUTTON_SIZE}
                                        style={{ flexShrink: 0 }}
                                    />
                                )
                            }
                            text={isCollapsed ? "Expand" : "Collapse"}
                            onClick={toggleSidebar}
                        />

                        <ActionButton
                            isCollapsed={isCollapsed}
                            icon={<Setting2 color={iconColor} size={ACTION_BUTTON_SIZE} style={{ flexShrink: 0 }} />}
                            text="Settings"
                            onClick={handleSettings}
                        />
                    </Box>
                    <Box sx={{ borderTop: 1, borderColor: "neutrals.30", my: 0.5 }} />
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", gap: 1, px: 2 }}>
                        {isLoggedIn.state === "loaded" && isLoggedIn.data ? (
                            <UserSection
                                isCollapsed={isCollapsed}
                                initial={initial}
                                currentUser={currentUserQuery.data}
                                iconColor={iconColor}
                                onSignOut={handleSignOut}
                            />
                        ) : (
                            <ActionButton
                                isCollapsed={isCollapsed}
                                icon={<Login color={iconColor} size={ACTION_BUTTON_SIZE} />}
                                text="Sign In"
                                onClick={handleSignIn}
                            />
                        )}
                    </Box>
                    <Box sx={{ borderTop: 1, borderColor: "neutrals.30", my: 0.5 }} />
                    {isCollapsed ? (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Logo style={{ width: 24, height: 24, color: theme.palette.surface[100], flexShrink: 0 }} />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1.25,
                                px: 2,
                            }}
                        >
                            <Logo style={{ width: 24, height: 24, color: theme.palette.surface[100], flexShrink: 0 }} />
                            <Typography variant="body2" color="neutrals.60">
                                AnswerGrid
                            </Typography>
                        </Box>
                    )}
                </Box>

                {!isCollapsed && (
                    <Box
                        onMouseDown={handleResizeStart}
                        sx={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            bottom: 0,
                            width: "4px",
                            cursor: "col-resize",
                            "&:hover": {
                                bgcolor: "secondary.main",
                            },
                            transition: "background-color 0.2s ease",
                            ...(isResizing && {
                                bgcolor: "secondary.main",
                            }),
                        }}
                    />
                )}
            </Box>
            <HistoryDialog open={isHistoryOpen} onClose={handleCloseHistory} />
        </>
    );
};

interface HomeButtonProps {
    isCollapsed: boolean;
}

const HomeButton: React.FC<HomeButtonProps> = ({ isCollapsed }) => {
    const theme = useTheme();

    const navigate = useNavigate();
    const handleClickHome = React.useCallback(() => {
        navigate("/");
    }, [navigate]);

    const location = useLocation();
    const isSelected = React.useMemo(() => {
        return location.pathname === "/" || location.pathname === "/search";
    }, [location.pathname]);

    const color = isSelected ? theme.palette.secondary.main : theme.palette.neutrals[50];
    if (isCollapsed) {
        return (
            <IconButton aria-label="Home" onClick={handleClickHome} size="small" sx={{ mx: 1 }}>
                <Home2 color={color} variant={isSelected ? "Bold" : "Outline"} size={24} />
            </IconButton>
        );
    }

    return (
        <Button
            startIcon={<Home color={color} variant={isSelected ? "Bold" : "Outline"} size={24} />}
            onClick={handleClickHome}
            sx={{
                color: "secondary.main",
                justifyContent: "flex-start",
            }}
        >
            <Typography fontWeight={isSelected ? 550 : undefined}>Home</Typography>
        </Button>
    );
};

interface CustomerComponentProps {
    isCollapsed: boolean;
}

const LOGO_SIZE = 32;

const CustomerComponent: React.FC<CustomerComponentProps> = ({ isCollapsed }) => {
    const theme = useTheme();

    const { data: tenant, isLoading: isTenantLoading } = useQuery(tenantQueryOptions);

    if (isCollapsed) {
        if (isTenantLoading) {
            return <Skeleton variant="circular" width={LOGO_SIZE} height={LOGO_SIZE} />;
        } else if (tenant != null && tenant.logo_url != null) {
            return (
                <Box
                    component="img"
                    src={tenant.logo_url}
                    alt={tenant.name}
                    sx={{ width: LOGO_SIZE, height: LOGO_SIZE, flexShrink: 0, mt: 1, mb: 1.5 }}
                />
            );
        }
        return <></>;
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    pr: 1,
                    pl: 1.5,
                    py: 1,
                    borderRadius: 3.5,
                    columnGap: 1,
                    bgcolor: "primary.main",
                    typography: "body2",
                    alignItems: "center",
                    fontWeight: "semibold",
                    color: theme.palette.neutrals[80],
                    border: 1,
                    borderColor: "neutrals.30",
                    justifyContent: "space-between",
                    flexShrink: 0,
                    overflow: "hidden",
                    // cursor: "pointer",
                }}
                // onClick={handleClick}
            >
                <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, overflow: "hidden" }}>
                    {isTenantLoading ? (
                        <Skeleton variant="circular" width={LOGO_SIZE} height={LOGO_SIZE} />
                    ) : tenant != null && tenant.logo_url != null ? (
                        <img
                            src={tenant.logo_url}
                            alt={tenant.name}
                            style={{ width: LOGO_SIZE, height: LOGO_SIZE, flexShrink: 0 }}
                        />
                    ) : (
                        <></>
                    )}
                    {isTenantLoading ? (
                        <Skeleton variant="rectangular" width={50} height={20} />
                    ) : (
                        <Typography variant="body2" fontWeight={550} color="neutrals.60">
                            {tenant?.name}
                        </Typography>
                    )}
                </Box>
                {/* <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                    <More color={theme.palette.neutrals[60]} size={16} />
                </Box> */}
            </Box>
        </>
    );
};

interface ActionButtonProps {
    isCollapsed: boolean;
    icon: React.ReactNode;
    text: string;
    href?: string;
    onClick: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ isCollapsed, icon, text, href, onClick }) => {
    if (isCollapsed) {
        return (
            <IconButton aria-label={text} onClick={onClick} size="small">
                {icon}
            </IconButton>
        );
    } else {
        return (
            <Button
                href={href}
                onClick={onClick}
                startIcon={icon}
                color="primary"
                fullWidth
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    whiteSpace: "nowrap",
                    color: "neutrals.60",
                    textTransform: "none",
                    fontWeight: 400,
                    // TODO: Alignment hack
                    ml: -0.25,
                    justifyContent: "flex-start",
                }}
            >
                {text}
            </Button>
        );
    }
};

interface ChatButtonProps {
    isCollapsed: boolean;
}

const ChatButton: React.FC<ChatButtonProps> = React.memo(({ isCollapsed }: ChatButtonProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const handleClickChat = React.useCallback(() => {
        navigate("/chat");
    }, [navigate]);

    const isSelected = React.useMemo(() => {
        return location.pathname.startsWith("/chat");
    }, [location.pathname]);

    const selectedChatId = React.useMemo(() => {
        return location.pathname.split("/").pop();
    }, [location.pathname]);

    const { data: chats } = useQuery(chatsQueryOptions);

    const sortedChats = React.useMemo(() => {
        return chats?.sort(comparatorOnFields(chat => [-new Date(chat.updated_at).getTime()]));
    }, [chats]);

    const handleClickNewChat = React.useCallback(() => {
        navigate("/chat");
    }, [navigate]);

    const iconColor = isSelected ? theme.palette.secondary.main : theme.palette.neutrals[50];

    if (isCollapsed) {
        return (
            <IconButton aria-label="Chat" onClick={handleClickChat} size="small" sx={{ mx: 1 }}>
                <Message2 color={iconColor} variant={isSelected ? "Bold" : "Outline"} size={24} />
            </IconButton>
        );
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: sortedChats != null && sortedChats.length > 0 ? 1 : 0,
                }}
            >
                <Button
                    onClick={handleClickChat}
                    startIcon={<Messages3 color={iconColor} variant={isSelected ? "Bold" : "Outline"} size={24} />}
                    sx={{
                        color: "secondary.main",
                        justifyContent: "flex-start",
                    }}
                >
                    <Typography fontWeight={isSelected ? 550 : undefined}>Chats</Typography>
                </Button>
                <Box
                    onClick={handleClickNewChat}
                    sx={{
                        bgcolor: "surface.100",
                        mr: 1,
                        borderRadius: 1,
                        width: 24,
                        height: 24,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                    }}
                >
                    <Add size={20} color={theme.palette.secondary.main} />
                </Box>
            </Box>
            {sortedChats != null && sortedChats.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        pl: 2,
                        maxHeight: { xs: 170, xl: 250 },
                        overflowX: "hidden",
                        ...scrollbarSx,
                    }}
                >
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ minWidth: "1px", bgcolor: "neutrals.30", mr: 1.5 }} />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                overflowY: "auto",
                            }}
                        >
                            {sortedChats.map(chat => (
                                <PreviousChatButton chat={chat} selectedChatId={selectedChatId} key={chat.unique_id} />
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
});

ChatButton.displayName = "ChatButton";

const PreviousChatButton: React.FC<{ chat: ChatListSchema; selectedChatId: string | undefined }> = ({
    chat,
    selectedChatId,
}) => {
    const navigate = useNavigate();
    const handleClick = React.useCallback(() => {
        navigate(`/chat/${chat.unique_id}`);
    }, [navigate, chat.unique_id]);

    return (
        <Button
            onClick={handleClick}
            sx={{
                width: "100%",
                justifyContent: "flex-start",
                color: selectedChatId === chat.unique_id ? "secondary.main" : "text.secondary",
                py: 0.5,
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
        >
            <Typography variant="body2" noWrap fontWeight={selectedChatId === chat.unique_id ? 600 : 450}>
                {chat.name}
            </Typography>
        </Button>
    );
};

interface UserSectionProps {
    isCollapsed: boolean;
    initial?: string;
    currentUser?: {
        first_name?: string;
        last_name?: string;
    };
    iconColor: string;
    onSignOut: () => Promise<void>;
}

const UserSection: React.FC<UserSectionProps> = React.memo(
    ({ isCollapsed, initial, currentUser, iconColor, onSignOut }: UserSectionProps) => {
        if (isCollapsed) {
            return (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 40 }}>
                    <Avatar
                        sx={{
                            bgcolor: "surface.100",
                            color: "secondary.main",
                            fontSize: "14px",
                            width: 24,
                            height: 24,
                        }}
                    >
                        {initial ?? ""}
                    </Avatar>
                </Box>
            );
        }

        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1.25,
                    }}
                >
                    <Avatar
                        sx={{
                            bgcolor: "surface.100",
                            color: "secondary.main",
                            fontSize: "14px",
                            width: 24,
                            height: 24,
                        }}
                    >
                        {initial ?? ""}
                    </Avatar>
                    <Typography
                        variant="body2"
                        color="neutrals.60"
                        noWrap
                        sx={{
                            textOverflow: "ellipsis",
                        }}
                    >
                        {currentUser?.first_name ?? ""} {currentUser?.last_name ?? ""}
                    </Typography>
                </Box>
                <IconButton
                    aria-label="Sign Out"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onSignOut}
                    sx={{
                        color: "neutrals.60",
                        textTransform: "none",
                    }}
                >
                    <Logout color={iconColor} size={ACTION_BUTTON_SIZE} />
                </IconButton>
            </Box>
        );
    },
);

UserSection.displayName = "UserSection";

interface DriveButtonProps {
    isCollapsed: boolean;
}

const DriveButton: React.FC<DriveButtonProps> = ({ isCollapsed }) => {
    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClickDrive = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const color = theme.palette.neutrals[50];

    if (isCollapsed) {
        return (
            <>
                <IconButton aria-label="Drive" onClick={handleClickDrive} size="small" sx={{ mx: 1 }}>
                    <Folder color={color} variant="Outline" size={24} />
                </IconButton>
                <DriveExplorerDialog open={dialogOpen} onClose={handleCloseDialog} />
            </>
        );
    }

    return (
        <>
            <Button
                startIcon={<Folder color={color} variant="Outline" size={24} />}
                onClick={handleClickDrive}
                sx={{
                    color: "secondary.main",
                    justifyContent: "flex-start",
                }}
            >
                <Typography>Drive</Typography>
            </Button>
            <DriveExplorerDialog open={dialogOpen} onClose={handleCloseDialog} />
        </>
    );
};

interface HistoryButtonProps {
    isCollapsed: boolean;
    onClick: () => void;
}

const HistoryButton: React.FC<HistoryButtonProps> = React.memo(({ isCollapsed, onClick }: HistoryButtonProps) => {
    const theme = useTheme();
    const iconColor = theme.palette.neutrals[70];

    const specialKey = React.useMemo(() => {
        return navigator.platform.includes("Mac") ? "⌘" : "Ctrl+";
    }, []);

    if (isCollapsed) {
        return (
            <IconButton
                aria-label="History"
                onClick={onClick}
                size="small"
                sx={{
                    mx: 1,
                    width: 32,
                    height: 32,
                    px: 0,
                    bgcolor: "neutrals.25",
                    "&:hover": {
                        bgcolor: "neutrals.30",
                    },
                }}
            >
                <SearchStatus color={iconColor} size={16} />
            </IconButton>
        );
    }

    return (
        <Box
            onClick={onClick}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                px: 1.5,
                py: 0.75,
                bgcolor: "neutrals.25",
                borderRadius: 4,
                height: 32,
                cursor: "pointer",
                "&:hover": {
                    bgcolor: "neutrals.30",
                },
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <SearchStatus color={iconColor} size={14} />
                <Typography noWrap variant="body2" color="neutrals.50">
                    Search history…
                </Typography>
            </Box>
            <Typography noWrap variant="body2" color="neutrals.60">
                {specialKey}K
            </Typography>
        </Box>
    );
});

HistoryButton.displayName = "HistoryButton";
