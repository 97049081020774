import * as React from "react";
import { Box, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import { coreBlobRouterGenerateSasUrl, coreProjectRouterGetProjectInfo } from "../backend-client/generated/sdk.gen";
import { Source, Page as SearchPage } from "./types";
import { FileType } from "../shared/types";
import { ReactComponent as MaximizeIcon } from "../assets/maximize.svg";
import { useResizeDetector } from "react-resize-detector";
import { NavigationButton } from "./components/NavigationButton";
import { PageThumbnail } from "./pageThumbnail";
import { FullScreenPDFDialog } from "./fullscreenPdfDialog";
import { comparatorOnFields } from "../utils/comparators";
import { unique } from "../utils/arrays";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { Calendar, Folder, Gps } from "iconsax-react";
import { Apartment } from "@mui/icons-material";
import { ProjectInfo } from "../backend-client/generated/types.gen";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

export type PDFPreviewPage = Pick<SearchPage, "page" | "rank">;
export type PDFPreviewSource = Pick<
    Source,
    "file_name" | "file_type" | "project_code" | "project_name" | "project_id"
> & {
    pages: PDFPreviewPage[];
    blob_name: string | undefined;
    is_user_file: boolean;
    time_created?: string | undefined | null;
    project_code?: string | undefined | null;
};

interface PDFPreviewProps<T extends PDFPreviewSource> {
    source: T;
    page: number;
    onPageChange: (newPage: number) => void;
}

export const PDFPreview = <T extends PDFPreviewSource>({ source, page, onPageChange }: PDFPreviewProps<T>) => {
    const { width, ref } = useResizeDetector({
        refreshMode: "debounce",
        refreshRate: 100,
    });
    const [numPages, setNumPages] = React.useState<number | undefined>(undefined);

    const sortedJumpToPageNums = React.useMemo(
        () => unique(source.pages.map(p => p.page)).sort(comparatorOnFields(p => [p])),
        [source.pages],
    );

    const handlePageSelect = React.useCallback(
        (index: number) => onPageChange(sortedJumpToPageNums[index]),
        [sortedJumpToPageNums, onPageChange],
    );

    const selectedPageIndex = React.useMemo(
        () => sortedJumpToPageNums.findIndex(p => p === page),
        [sortedJumpToPageNums, page],
    );

    const { data: pdfUrl, error } = useQuery({
        queryKey: ["pdfUrl", source.file_name],
        queryFn: async () => {
            const DOCUMENT_EXTENSIONS = [".pdf", ".doc", ".docx", ".txt", ".xlsx", ".xls", ".ppt", ".pptx"];
            const hasValidExtension = DOCUMENT_EXTENSIONS.some(
                ext => source.blob_name?.toLowerCase().endsWith(ext) ?? source.file_name.toLowerCase().endsWith(ext),
            );
            const blobName = source.blob_name ?? source.file_name;
            const fileNameWithEnding = hasValidExtension ? blobName : `${blobName}.pdf`;
            const response = await coreBlobRouterGenerateSasUrl({
                throwOnError: true,
                path: { blob_name: encodeURIComponent(fileNameWithEnding) },
                query: {
                    container_type: source.is_user_file ? "user-pdfs-and-images" : "pdfs",
                },
            });
            return response.data.url;
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });

    const onDocumentLoadSuccess = React.useCallback(({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }, []);

    const [fullScreenDialogOpen, setFullScreenDialogOpen] = React.useState(false);

    const handleFullScreenDialogOpen = React.useCallback(() => {
        setFullScreenDialogOpen(true);
    }, []);

    const handleFullScreenDialogClose = React.useCallback(() => {
        setFullScreenDialogOpen(false);
    }, []);

    if (error) {
        return <Box sx={{ p: 2 }}>Failed to load PDF</Box>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: 1 }} ref={ref}>
            {pdfUrl != null && (
                <>
                    <PDFViewer
                        key={pdfUrl}
                        pdfUrl={pdfUrl}
                        fileType={source.file_type ?? undefined}
                        pageNumber={page}
                        onPageChange={onPageChange}
                        numPages={numPages}
                        onDocumentLoadSuccess={onDocumentLoadSuccess}
                        onFullScreenDialogOpen={handleFullScreenDialogOpen}
                        width={width}
                    />
                    {sortedJumpToPageNums.length > 0 && (
                        <JumpToSection
                            pageNumbers={sortedJumpToPageNums}
                            fileType={source.file_type ?? undefined}
                            pdfUrl={pdfUrl}
                            selectedPageIndex={selectedPageIndex !== -1 ? selectedPageIndex : undefined}
                            handlePageSelect={handlePageSelect}
                        />
                    )}
                    <Box
                        sx={{
                            width: "100%",
                            borderBottom: 1,
                            borderColor: "neutrals.40",
                            mt: 2,
                        }}
                    />
                    <MetadataSection
                        projectCode={source.project_code}
                        timeCreated={source.time_created ?? undefined}
                        projectId={source.project_id}
                    />
                </>
            )}
            {pdfUrl != null && fullScreenDialogOpen && (
                <FullScreenPDFDialog
                    open={fullScreenDialogOpen}
                    onClose={handleFullScreenDialogClose}
                    pdfName={source.file_name}
                    fileType={source.file_type ?? undefined}
                    pdfUrl={pdfUrl}
                    pageNumbers={sortedJumpToPageNums}
                    initialPage={page}
                />
            )}
        </Box>
    );
};

interface PDFViewerProps {
    pdfUrl: string;
    pageNumber: number;
    onPageChange: (newPage: number) => void;
    numPages: number | undefined;
    onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void;
    width?: number;
    onFullScreenDialogOpen: () => void;
    fileType: FileType | undefined;
}

const PDFViewer: React.FC<PDFViewerProps> = React.memo(
    // eslint-disable-next-line react/prop-types
    ({
        pdfUrl,
        pageNumber,
        onPageChange,
        numPages,
        onDocumentLoadSuccess,
        width,
        onFullScreenDialogOpen,
        fileType,
    }: PDFViewerProps) => {
        const theme = useTheme();
        const [pageInputValue, setPageInputValue] = React.useState<string | undefined>(undefined);
        const handlePageInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
            setPageInputValue(e.target.value);
        }, []);
        const handlePageInputBlur = React.useCallback(() => {
            const newPage = Math.max(
                1,
                Math.min(parseInt(pageInputValue ?? pageNumber.toString(), 10), numPages ?? pageNumber),
            );
            onPageChange(newPage);
            setPageInputValue(undefined);
        }, [pageInputValue, numPages, pageNumber, onPageChange]);

        const handleKeyPress = React.useCallback(
            (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                    handlePageInputBlur();
                }
            },
            [handlePageInputBlur],
        );

        const goToPrevPage = React.useCallback(() => {
            onPageChange(Math.max(pageNumber - 1, 1));
        }, [onPageChange, pageNumber]);

        const goToNextPage = React.useCallback(() => {
            onPageChange(Math.min(pageNumber + 1, numPages ?? pageNumber));
        }, [onPageChange, pageNumber, numPages]);

        const skeleton = (
            <Skeleton
                variant="rectangular"
                width={width}
                height={width != null ? Math.min(400, 0.7 * width) : 400}
                animation="wave"
                sx={{ bgcolor: "grey.100" }}
            />
        );

        return (
            <>
                <Box
                    sx={{
                        flex: 1,
                        overflow: "hidden",
                        overflowY: "auto",
                        width: "100%",
                        borderRadius: 2,
                        border: 1,
                        borderColor: "neutrals.20",
                        maxHeight: "400px",
                    }}
                >
                    <Document
                        className="highlight-block ph-no-capture"
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={skeleton}
                        error={<Typography sx={{ p: 2 }}>Failed to load PDF</Typography>}
                    >
                        {numPages != null ? (
                            <Page
                                className="highlight-block ph-no-capture"
                                loading={skeleton}
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={width}
                            />
                        ) : (
                            skeleton
                        )}
                    </Document>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        pl: 1,
                        pr: 2,
                        bgcolor: "surface.0",
                        borderRadius: 2,
                        border: 1,
                        borderColor: "neutrals.25",
                        py: 0.5,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                        }}
                    >
                        <input
                            value={pageInputValue ?? pageNumber.toString()}
                            onChange={handlePageInputChange}
                            onBlur={handlePageInputBlur}
                            onKeyPress={handleKeyPress}
                            style={{
                                width: "35px",
                                padding: "6px 8px",
                                border: "1px solid #E0E0E0",
                                borderRadius: "4px",
                            }}
                        />
                        <Typography variant="caption" sx={{ color: "neutrals.60" }}>
                            of {numPages ?? "?"} {fileType === "xlsx" ? "Sheets" : "Pages"}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            alignSelf: "flex-end",
                            display: "flex",
                            alignItems: "center",
                            columnGap: 0.5,
                            borderRadius: 2,
                        }}
                    >
                        <NavigationButton direction="left" onClick={goToPrevPage} disabled={pageNumber <= 1} />
                        <NavigationButton
                            direction="right"
                            onClick={goToNextPage}
                            disabled={pageNumber >= (numPages ?? 1)}
                            sx={{ color: "secondary.main", bgcolor: "surface.0" }}
                        />
                        <IconButton
                            onClick={onFullScreenDialogOpen}
                            sx={{ color: "secondary.main", bgcolor: "surface.0" }}
                        >
                            <MaximizeIcon style={{ width: 20, height: 20 }} color={theme.palette.neutrals[80]} />
                        </IconButton>
                    </Box>
                </Box>
            </>
        );
    },
);

PDFViewer.displayName = "PDFViewer";

interface JumpToSectionProps {
    pageNumbers: number[];
    fileType: FileType | undefined;
    pdfUrl: string;
    selectedPageIndex: number | undefined;
    handlePageSelect: (index: number) => void;
}

const JumpToSection: React.FC<JumpToSectionProps> = ({
    pageNumbers,
    fileType,
    pdfUrl,
    selectedPageIndex,
    handlePageSelect,
}) => {
    return (
        <>
            <Typography
                variant="caption"
                sx={{
                    color: "neutrals.80",
                    alignSelf: "flex-start",
                    mt: 2,
                    fontWeight: 550,
                }}
            >
                Jump To
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    width: "100%",
                }}
            >
                {pageNumbers.map((pageNum, index) => (
                    <PageThumbnail
                        key={`${pdfUrl}-${pageNum}`}
                        pageNumber={pageNum}
                        pdfUrl={pdfUrl}
                        fileType={fileType}
                        isSelected={selectedPageIndex === index}
                        onClick={() => handlePageSelect(index)}
                    />
                ))}
            </Box>
        </>
    );
};

interface MetadataSectionProps {
    projectCode: string | undefined;
    timeCreated: string | undefined;
    projectId: string | undefined;
}

const MetadataSection: React.FC<MetadataSectionProps> = React.memo(
    ({ projectCode, timeCreated, projectId }: MetadataSectionProps) => {
        const [expanded, setExpanded] = React.useState(false);

        const handleToggleExpand = React.useCallback(() => {
            setExpanded(prev => !prev);
        }, []);

        const formattedLastUpdated = React.useMemo(() => {
            if (timeCreated == null) {
                return null;
            }
            return format(new Date(timeCreated), "MMM yyyy");
        }, [timeCreated]);

        const { data: projectInfo, isLoading: isProjectInfoLoading } = useQuery({
            queryKey: ["project-info", projectId],
            queryFn: () =>
                coreProjectRouterGetProjectInfo({
                    body: {
                        project_ids: projectId != null ? [projectId] : [],
                    },
                }),
            enabled: projectId != null,
        });

        const projectDetails = React.useMemo(() => {
            if (projectId == null || projectInfo?.data?.projects?.[projectId] == null) {
                return null;
            }
            return projectInfo.data.projects[projectId];
        }, [projectId, projectInfo]);

        if (projectCode == null && formattedLastUpdated == null && projectId == null) {
            return null;
        }

        return (
            <Box sx={{ width: "100%", mt: 1, pb: 4 }}>
                <ProjectDetails
                    projectCode={projectCode}
                    isProjectInfoLoading={isProjectInfoLoading}
                    projectDetails={projectDetails ?? undefined}
                    expanded={expanded}
                    handleToggleExpand={handleToggleExpand}
                />
                <Typography variant="caption" sx={{ fontWeight: 500, color: "neutrals.80" }}>
                    Document info
                </Typography>

                {formattedLastUpdated && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mt: 0.5, mb: 3 }}>
                        <Calendar size={12} />
                        <Typography variant="caption" sx={{ color: "neutrals.60" }} noWrap>
                            Last updated: {formattedLastUpdated}
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    },
);

MetadataSection.displayName = "MetadataSection";

interface ProjectDetailsProps {
    projectCode: string | undefined;
    isProjectInfoLoading: boolean;
    projectDetails: ProjectInfo | undefined;
    expanded: boolean;
    handleToggleExpand: () => void;
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
    projectCode,
    isProjectInfoLoading,
    projectDetails,
    expanded,
    handleToggleExpand,
}: ProjectDetailsProps) => {
    const projectName = projectDetails?.name;
    const projectClient = projectDetails?.client;
    const projectTargetOrganization = projectDetails?.target_organization;
    const projectStartDate = projectDetails?.start_date;
    const projectEndDate = projectDetails?.end_date;

    if (projectCode == null && projectName == null && projectStartDate == null && projectEndDate == null) {
        return null;
    }

    return (
        <>
            <Typography variant="caption" sx={{ fontWeight: 500, mb: 1, color: "neutrals.80" }}>
                Project Info
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mb: 1,
                    mt: 0.5,
                    flexWrap: "wrap",
                    overflow: "hidden",
                }}
            >
                {projectCode != null && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <Folder size={12} />
                        <Typography variant="caption" sx={{ color: "neutrals.60" }} noWrap>
                            {projectName != null ? `${projectName} (${projectCode})` : projectCode}
                        </Typography>
                    </Box>
                )}
                {projectClient != null && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <Apartment sx={{ width: 12, height: 12, color: "neutrals.60" }} />
                        <Typography variant="caption" sx={{ color: "neutrals.60" }} noWrap>
                            {projectClient.name}
                        </Typography>
                    </Box>
                )}
                {projectTargetOrganization != null && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <Gps size={12} />
                        <Typography variant="caption" sx={{ color: "neutrals.60" }} noWrap>
                            {projectTargetOrganization.name}
                        </Typography>
                    </Box>
                )}
                {projectStartDate != null && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <Calendar size={12} />
                        <Typography variant="caption" sx={{ color: "neutrals.60" }} noWrap>
                            Started: {format(new Date(projectStartDate), "dd MMM yyyy")}
                        </Typography>
                    </Box>
                )}
                {projectEndDate != null && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <Calendar size={12} />
                        <Typography variant="caption" sx={{ color: "neutrals.60" }} noWrap>
                            Ended: {format(new Date(projectEndDate), "dd MMM yyyy")}
                        </Typography>
                    </Box>
                )}
            </Box>

            {isProjectInfoLoading ? (
                <Box>
                    <Skeleton variant="text" height={16} sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" height={16} sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" height={16} sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" height={16} sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" height={16} sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" height={16} sx={{ mb: 0.5 }} />
                    <Skeleton variant="text" width="20%" height={16} />
                </Box>
            ) : projectDetails?.summary ? (
                <Box>
                    <Typography
                        variant="caption"
                        sx={{
                            color: "neutrals.60",
                            display: "-webkit-box",
                            WebkitLineClamp: expanded ? "unset" : 6,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            lineHeight: "1.5",
                        }}
                    >
                        {projectDetails.summary}
                    </Typography>
                    {!expanded && (
                        <Typography
                            component="span"
                            onClick={handleToggleExpand}
                            variant="caption"
                            sx={{
                                color: "secondary.main",
                                cursor: "pointer",
                                "&:hover": {
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            see more
                        </Typography>
                    )}
                    {expanded && (
                        <Typography
                            onClick={handleToggleExpand}
                            variant="caption"
                            sx={{
                                color: "secondary.main",
                                cursor: "pointer",
                                "&:hover": {
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            see less
                        </Typography>
                    )}
                </Box>
            ) : null}
            <Box sx={{ width: "100%", borderBottom: 1, borderColor: "neutrals.40", my: 2 }} />
        </>
    );
};

ProjectDetails.displayName = "ProjectDetails";
