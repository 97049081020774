import * as React from "react";
import { Box, Menu, MenuItem, Typography, SxProps, Theme } from "@mui/material";
import { Microscope, Setting4 } from "iconsax-react";
import { modelMenuItemSx, ModelPickerLayout } from "./externalModelPicker";

export enum InternalModelType {
    Default = "default",
    DeepResearch = "deepResearch",
}

interface InternalModelPickerProps {
    useDeepResearch: boolean;
    onDeepResearchToggle: () => void;
    sx?: SxProps<Theme>;
}

export const InternalModelPicker: React.FC<InternalModelPickerProps> = React.memo(
    ({ useDeepResearch, onDeepResearchToggle, sx }: InternalModelPickerProps) => {
        const [modelMenuAnchor, setModelMenuAnchor] = React.useState<null | HTMLElement>(null);

        const handleModelMenuOpen = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            setModelMenuAnchor(event.currentTarget);
        }, []);

        const handleModelMenuClose = React.useCallback(() => {
            setModelMenuAnchor(null);
        }, []);

        const handleSetDeepResearch = React.useCallback(
            (val: boolean) => {
                if (useDeepResearch !== val) {
                    onDeepResearchToggle();
                }
                handleModelMenuClose();
            },
            [onDeepResearchToggle, useDeepResearch, handleModelMenuClose],
        );

        const handleDefaultSelect = React.useCallback(() => {
            handleSetDeepResearch(false);
        }, [handleSetDeepResearch]);

        const handleDeepResearchSelect = React.useCallback(() => {
            handleSetDeepResearch(true);
        }, [handleSetDeepResearch]);

        const displayName = useDeepResearch ? "Deep research" : "Default";

        const icon = useDeepResearch ? (
            <Microscope size={20} variant="Bold" />
        ) : (
            <Setting4 size={20} variant="Outline" />
        );

        return (
            <>
                <ModelPickerLayout
                    displayName={displayName}
                    icon={icon}
                    isActive={useDeepResearch}
                    onMenuOpen={handleModelMenuOpen}
                    sx={sx}
                />

                <Menu
                    anchorEl={modelMenuAnchor}
                    open={Boolean(modelMenuAnchor)}
                    onClose={handleModelMenuClose}
                    sx={{ p: 1 }}
                    MenuListProps={{ sx: { py: 0.5 } }}
                >
                    <MenuItem onClick={handleDefaultSelect} sx={modelMenuItemSx} key="default">
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography
                                variant="caption"
                                fontWeight={!useDeepResearch ? 550 : "normal"}
                                color={!useDeepResearch ? "secondary.main" : "neutrals.80"}
                            >
                                Default
                            </Typography>
                            <Typography sx={{ color: "neutrals.60", fontSize: 10 }}>
                                Versatile internal search
                            </Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem onClick={handleDeepResearchSelect} sx={modelMenuItemSx} key="deep-research">
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography
                                variant="caption"
                                fontWeight={useDeepResearch ? 550 : "normal"}
                                color={useDeepResearch ? "secondary.main" : "neutrals.80"}
                            >
                                Deep Research
                            </Typography>
                            <Typography sx={{ color: "neutrals.60", fontSize: 10 }}>Deeper research agent</Typography>
                        </Box>
                    </MenuItem>
                </Menu>
            </>
        );
    },
);

InternalModelPicker.displayName = "InternalModelPicker";
