import * as React from "react";
import { Box, Theme, SxProps, useTheme } from "@mui/material";

export interface ProjectAnswerCardLayoutProps {
    /** Controls the border styles and highlight color when selected */
    isSelected: boolean;
    /** Click handler passed through to the root Box */
    onClick?: () => void;
    /** Additional SX you can pass in, merged with the layout's own styles */
    sx?: SxProps<Theme>;

    /**
     * Slots for each section of the card.
     * The parent decides what to render in each slot.
     */
    topLeftSlot?: React.ReactNode; // Project name, #id, tick, project-type tag
    topRightSlot?: React.ReactNode; // One or two icons, depending on type
    middleLeftSlot?: React.ReactNode; // The "fields" row, e.g. (icon + text) x3 or single
    middleRightSlot?: React.ReactNode; // The "fields" row, e.g. (icon + text) x3 or single
    // middleSlot?: React.ReactNode; // The "fields" row, e.g. (icon + text) x3 or single
    summarySlot?: React.ReactNode; // The summary text box, no lamp icon
    bottomSlot?: React.ReactNode; // Contributors, bottom-right
}

export const MIN_WIDTH = 320;

/**
 * ProjectAnswerCardLayout
 *
 * Contains the "fancy" styling (tab shape, border highlights, etc.).
 * The actual content is passed in via the slots.
 */
export function ProjectAnswerCardLayout({
    isSelected,
    onClick,
    sx,
    topLeftSlot,
    topRightSlot,
    middleLeftSlot,
    middleRightSlot,
    summarySlot,
    bottomSlot,
}: ProjectAnswerCardLayoutProps) {
    const theme = useTheme();

    return (
        <Box
            onClick={onClick}
            sx={[
                {
                    mt: 2,
                    px: 2,
                    py: 1.25,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: 1.5,
                    cursor: "pointer",
                    bgcolor: "surface.0",
                    border: 1,
                    borderColor: isSelected ? "selection.main" : "neutrals.25",
                    borderRadius: 2,
                    position: "relative",
                    minWidth: MIN_WIDTH,

                    /* Hover border color logic */
                    "&:hover": {
                        borderColor: isSelected ? "selection.main" : "neutrals.30",
                        "&::before": {
                            borderColor: isSelected ? "selection.main" : "neutrals.30",
                        },
                        "&::after": {
                            backgroundColor: isSelected ? "selection.main" : "neutrals.30",
                        },
                    },

                    /* The fancy tab shape at the top left */
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        top: -10,
                        left: -1,
                        width: "calc(25% + 1px)",
                        height: 10.5,
                        bgcolor: "surface.0",
                        borderTop: 1,
                        borderLeft: 1,
                        borderRight: 1,
                        borderColor: "inherit",
                        borderTopLeftRadius: theme.spacing(2),
                        borderTopRightRadius: 0,
                        clipPath: "polygon(0 0, 90% 0, 100% 100%, 0 100%)",
                    },

                    /* The diagonal line bridging the tab to the card top */
                    "&::after": {
                        content: '""',
                        position: "absolute",
                        left: "calc(22.5% - 0.5px)",
                        top: "-9.7px",
                        height: "calc(sin(45deg) * 19.5px)",
                        width: "1px",
                        backgroundColor: isSelected ? "selection.main" : "neutrals.25",
                        transform: "rotate(-45deg)",
                        transformOrigin: "top center",
                        zIndex: 2,
                    },

                    // Remove rounding on the top-left corner so the "tab" effect is distinct
                    borderTopLeftRadius: 0,
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    overflow: "hidden",
                    height: 100,
                    gap: 1.5,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        flex: 1,
                        minWidth: 0,
                        alignItems: "flex-start",
                        overflow: "hidden",
                    }}
                >
                    {topLeftSlot}
                    {middleLeftSlot}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                    }}
                >
                    {topRightSlot}
                    {middleRightSlot}
                </Box>
            </Box>

            {/* Summary row */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    gap: 1,
                    py: 1,
                    px: 1,
                    bgcolor: "secondary.light",
                    height: 75,
                    overflow: "hidden",
                    borderRadius: 1,
                }}
            >
                {summarySlot}
            </Box>

            {bottomSlot}
        </Box>
    );
}
