import * as React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { FileType } from "../shared/types";
import { Sources } from "./types";
import { CitedSourceBox } from "../shared/citedSourceBox";
import { ArrowUp2 } from "iconsax-react";
import { CitedSourcePage } from "../shared/citedSourceBox";
import { ArrowDown2 } from "iconsax-react";
import { CitedText } from "../shared/citedText";
import { DateRangeCallout, useDateRange } from "./dateRangeCallout";
import { getScrollbarSx } from "../shared/scrollbarProps";
import { Citation } from "../shared/citation";
import { CitedSourcePopover } from "../shared/citationPopovers/citedSourcePopover";
import { SearchExternallyCallout } from "./searchExternallyCallout";
const EMPTY_PAGES: CitedSourcePage[] = [];

interface TextAnswerSectionProps {
    aiAnswer: string | undefined;
    citedSources: Sources | undefined;
    isStreamingAnswer: boolean;
    isExpanded: boolean;
    showDateRange?: boolean;
    hasSuitableAnswer: boolean;
    onSearchWeb: () => void;
    /**
     * If this is provided, the answer is from project summaries, and clicking a citation will select the project with the given index.
     */
    onSelectProject: ((idx: number) => void) | undefined;
    onSelectSource: (idx: number, page: number | undefined) => void;
    onExpandedChange: (expanded: boolean) => void;
}

const CHARS_PER_LINE = 120;
function guesstimateLinesInMarkdown(markdown: string) {
    const lines = markdown.split("\n");
    let totalLines = 0;

    for (const line of lines) {
        // Handle list items by adding indentation
        const listMatch = line.match(/^(\s*[-*+]|\d+\.)\s/);
        const indentWidth = listMatch ? listMatch[0].length : 0;

        const effectiveLength = line.length + indentWidth;

        // Calculate how many lines this content will take up
        const linesForContent = Math.max(1, Math.ceil(effectiveLength / CHARS_PER_LINE));
        totalLines += linesForContent;
    }

    return totalLines;
}

export const MAX_HEIGHT = 152;

// const NON_TEXT_HEIGHT = 90;

const EXTRA_HEIGHT_WITH_NON_ANSWER_CALLOUT = 48 + 8;

export const TextAnswerSection: React.FC<TextAnswerSectionProps> = React.memo(
    ({
        aiAnswer,
        citedSources,
        isStreamingAnswer,
        isExpanded,
        showDateRange,
        hasSuitableAnswer,
        onSelectSource,
        onExpandedChange,
        onSearchWeb,
        onSelectProject,
    }: TextAnswerSectionProps) => {
        const theme = useTheme();
        const textRef = React.useRef<HTMLDivElement>(null);
        const [shouldShowExpandButton, setShouldShowExpandButton] = React.useState(
            // TODO: This hack helps when we pick something to preview and this re-renders
            // Without this we animate and shouldShowExpandButton starts false
            guesstimateLinesInMarkdown(aiAnswer ?? "") > 4,
        );

        React.useEffect(() => {
            const el = textRef.current;
            if (el) {
                // Check if text is being clamped
                setShouldShowExpandButton(el.scrollHeight > el.clientHeight);
            }
        }, [aiAnswer, isStreamingAnswer]);

        const maxHeight = hasSuitableAnswer ? MAX_HEIGHT : MAX_HEIGHT + EXTRA_HEIGHT_WITH_NON_ANSWER_CALLOUT;
        // const nonTextHeight = hasSuitableAnswer ? NON_TEXT_HEIGHT : NON_TEXT_HEIGHT + EXTRA_HEIGHT_WITH_NON_ANSWER_CALLOUT;

        const patchedShouldShowExpandButton = React.useMemo(() => {
            const lines = guesstimateLinesInMarkdown(aiAnswer ?? "");
            // TODO: Hacks
            return (
                (isStreamingAnswer && lines > 3) ||
                (!isStreamingAnswer && lines > 7) ||
                shouldShowExpandButton ||
                isExpanded
            );
        }, [aiAnswer, isStreamingAnswer, shouldShowExpandButton, isExpanded]);
        const dateRange = useDateRange(citedSources);

        const handleToggleExpand = React.useCallback(() => {
            onExpandedChange(!isExpanded);
        }, [isExpanded, onExpandedChange]);

        const isAnswerFromProjectSummaries = onSelectProject != null;

        const handleCitationClick = React.useCallback(
            (rank: number) => {
                if (isAnswerFromProjectSummaries) {
                    onSelectProject?.(rank);
                    return;
                }
                const sourcePages = citedSources?.flatMap((s, sourceIdx) => s.pages.map(p => ({ sourceIdx, page: p })));
                const clickedPage = sourcePages?.find(s => s.page.rank === rank);
                if (clickedPage == null) {
                    return;
                }
                onSelectSource(clickedPage.sourceIdx, clickedPage.page.page);
            },
            [citedSources, onSelectSource, isAnswerFromProjectSummaries, onSelectProject],
        );

        const renderCitation = React.useCallback(
            (rank: number, onCitationClick: ((rank: number) => void) | undefined) => {
                if (citedSources != null && citedSources.length > 0 && !isAnswerFromProjectSummaries) {
                    const source = citedSources.find(s => s.pages?.some(p => p.rank === rank));
                    if (source != null) {
                        const page = source.pages?.find(p => p.rank === rank);
                        return (
                            <CitedSourcePopover
                                fileName={source.file_name}
                                fileType={source.file_type ?? undefined}
                                page={page?.page ?? 1}
                                timeCreated={source.time_created ?? undefined}
                                projectCode={source.project_code ?? undefined}
                            >
                                <Citation index={rank} onClick={onCitationClick} />
                            </CitedSourcePopover>
                        );
                    }
                }
                return <Citation index={rank} onClick={onCitationClick} />;
            },
            [citedSources, isAnswerFromProjectSummaries],
        );

        return (
            <Box
                sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    rowGap: 2,
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        width: "100%",
                        maxHeight: isExpanded ? "none" : maxHeight,
                        minHeight: isExpanded ? "auto" : 125,
                        overflow: "hidden",
                        transition: "max-height 0.3s ease-out",
                        bgcolor: "surface.0",
                        px: 1.5,
                        py: 1.5,
                        borderRadius: 2,
                        border: 1,
                        borderColor: "neutrals.25",
                    }}
                >
                    {!hasSuitableAnswer && <SearchExternallyCallout onSearchWeb={onSearchWeb} sx={{ mb: 1 }} />}
                    {aiAnswer == null || aiAnswer.length === 0 ? (
                        <PulsatingDot />
                    ) : (
                        <Typography
                            ref={textRef}
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: isExpanded ? "none" : 3,
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            <CitedText
                                text={aiAnswer ?? ""}
                                onCitationClick={handleCitationClick}
                                renderCitation={renderCitation}
                            />
                        </Typography>
                    )}
                    {showDateRange && isExpanded && dateRange != null && <DateRangeCallout dateRange={dateRange} />}
                    {isExpanded && (
                        <Box
                            sx={{
                                display: "flex",
                                overflowX: "auto",
                                mt: 2,
                                gap: 1,
                                ...getScrollbarSx("surface.0", theme.palette.mode === "dark"),
                            }}
                        >
                            {citedSources?.map((source, index) => (
                                <TextAnswerSectionCitedSourceBox
                                    key={index}
                                    fileName={source.file_name}
                                    blobName={source.blob_name ?? undefined}
                                    fileType={source.file_type ?? undefined}
                                    isUserFile={source.is_user_file}
                                    timeCreated={source.time_created}
                                    pages={source.pages ?? EMPTY_PAGES}
                                    sourceIdx={index}
                                    onSelect={onSelectSource}
                                />
                            ))}
                        </Box>
                    )}
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={handleToggleExpand}
                        sx={{
                            textTransform: "none",
                            borderColor: "neutrals.30",
                            transition: isStreamingAnswer ? "all 0.5s ease-in-out" : "none",
                            visibility: patchedShouldShowExpandButton ? "visible" : "hidden",
                            opacity: patchedShouldShowExpandButton ? 1 : 0,
                            height: patchedShouldShowExpandButton ? "auto" : 0,
                            "&:hover": {
                                bgcolor: "action.hover",
                                borderColor: "neutrals.30",
                            },
                            mt: isAnswerFromProjectSummaries ? 1 : isExpanded ? 2 : 1.5,
                            flexShrink: 0,
                            flexGrow: 0,
                        }}
                    >
                        <Typography variant="caption" color="secondary.main" fontWeight={500} sx={{ mr: 0.25 }}>
                            {isExpanded ? "Show less" : "Show more"}
                        </Typography>
                        {isExpanded ? (
                            <ArrowUp2 variant="Bold" size={12} color={theme.palette.secondary.main} />
                        ) : (
                            <ArrowDown2 variant="Bold" size={12} color={theme.palette.secondary.main} />
                        )}
                    </Button>
                </Box>
            </Box>
        );
    },
);

TextAnswerSection.displayName = "TextAnswerSection";

interface TextAnswerSectionCitedSourceBoxProps {
    fileName: string;
    blobName: string | undefined;
    fileType: FileType | undefined;
    isUserFile: boolean;
    timeCreated: string | undefined;
    pages: CitedSourcePage[];
    sourceIdx: number;
    onSelect: (sourceIdx: number, page: number | undefined) => void;
}

const TextAnswerSectionCitedSourceBox: React.FC<TextAnswerSectionCitedSourceBoxProps> = ({
    fileName,
    blobName,
    fileType,
    isUserFile,
    timeCreated,
    pages,
    sourceIdx,
    onSelect,
}) => {
    const handleSelect = React.useCallback(
        (page: number | undefined) => onSelect(sourceIdx, page),
        [sourceIdx, onSelect],
    );
    const blobNameForThumbnail = React.useMemo(() => {
        return blobName?.replace(".pdf", ".jpg");
    }, [blobName]);

    return (
        <CitedSourceBox
            fileName={fileName}
            blobName={blobNameForThumbnail}
            fileType={fileType}
            isUserFile={isUserFile}
            timeCreated={timeCreated}
            pages={pages}
            onSelect={handleSelect}
        />
    );
};

const PulsatingDot: React.FC = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
            }}
        >
            <Box
                sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    bgcolor: "secondary.main",
                    animation: "pulsate 1.5s ease-in-out infinite",
                    "@keyframes pulsate": {
                        "0%": {
                            transform: "scale(0.95)",
                            opacity: 0.7,
                        },
                        "50%": {
                            transform: "scale(1.05)",
                            opacity: 0.9,
                        },
                        "100%": {
                            transform: "scale(0.95)",
                            opacity: 0.7,
                        },
                    },
                }}
            />
        </Box>
    );
};
