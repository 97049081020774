import * as React from "react";
import { Box, Button, Menu, MenuItem, Typography, SxProps, Theme, lighten } from "@mui/material";
import { Setting4, ArrowDown2, Microscope } from "iconsax-react";
import { useTheme } from "@mui/material/styles";
import { Model } from "./types";
import { comparatorOnFields } from "../utils/comparators";
import { tenantQueryOptions } from "./queryOptions";
import { useQuery } from "@tanstack/react-query";
import { getModelCategory, getModelDisplayName, modelNames, SIMPLIFIED_MODEL_CATEGORIES } from "./models";

// Common model menu item styling
export const modelMenuItemSx = {
    display: "flex",
    alignItems: "center",
    columnGap: 1,
    px: 1.5,
};

// Shared layout for model pickers
interface ModelPickerLayoutProps {
    displayName: string;
    icon: React.ReactNode;
    isActive: boolean;
    onMenuOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
    sx?: SxProps<Theme>;
}

export const ModelPickerLayout: React.FC<ModelPickerLayoutProps> = ({
    displayName,
    icon,
    isActive,
    onMenuOpen,
    sx,
}) => {
    const theme = useTheme();

    return (
        <Button
            size="small"
            disableRipple
            onClick={onMenuOpen}
            sx={[
                {
                    display: "flex",
                    gap: 0.75,
                    alignItems: "center",
                    justifyContent: "start",
                    borderRadius: 4,
                    typography: "body2",
                    color: isActive ? "secondary.main" : "neutrals.70",
                    border: 1,
                    borderColor: theme => (isActive ? lighten(theme.palette.secondary.main, 0.5) : "neutrals.20"),
                    bgcolor: isActive ? "surface.25" : "transparent",
                    py: 0.5,
                    pl: 1,
                    pr: 1,
                    minWidth: 0,
                    height: 32,
                    "&:hover": {
                        bgcolor: isActive ? "surface.25" : "transparent",
                    },
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            {icon}
            <Typography variant="body2" component="span" fontWeight={isActive ? 450 : "normal"}>
                {displayName}
            </Typography>
            <ArrowDown2 size={12} color={theme.palette.neutrals[80]} />
        </Button>
    );
};

// External model picker props
interface ModelPickerProps {
    model: Model;
    onModelChange: (model: Model) => void;
    useDeepResearch: boolean;
    onDeepResearchToggle: () => void;
    sx?: SxProps<Theme>;
    isDirectBaseModelChat?: boolean;
}

export const ModelPicker: React.FC<ModelPickerProps> = React.memo(
    ({
        model,
        onModelChange,
        useDeepResearch,
        onDeepResearchToggle,
        sx,
        isDirectBaseModelChat = true,
    }: ModelPickerProps) => {
        const theme = useTheme();
        const [modelMenuAnchor, setModelMenuAnchor] = React.useState<null | HTMLElement>(null);

        const handleModelMenuOpen = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            setModelMenuAnchor(event.currentTarget);
        }, []);

        const handleModelMenuClose = React.useCallback(() => {
            setModelMenuAnchor(null);
        }, []);

        const handleSetDeepResearch = React.useCallback(
            (val: boolean) => {
                if (useDeepResearch !== val) {
                    onDeepResearchToggle();
                }
            },
            [onDeepResearchToggle, useDeepResearch],
        );

        const handleModelSelect = React.useCallback(
            (newModel: Model) => {
                onModelChange(newModel);
                handleSetDeepResearch(false);
                handleModelMenuClose();
            },
            [onModelChange, handleSetDeepResearch, handleModelMenuClose],
        );

        const handleEnableDeepResearch = React.useCallback(() => {
            handleSetDeepResearch(true);
            handleModelMenuClose();
        }, [handleSetDeepResearch, handleModelMenuClose]);

        const tenant = useQuery(tenantQueryOptions);

        const enableModelPicker = tenant.data?.enable_model_picker ?? false;
        const enableDeepResearch = tenant.data?.enable_deep_research ?? false;
        const enableWebSearch = tenant.data?.enable_web_search ?? false;
        const enableSimplifiedModelPicker = tenant.data?.enable_simplified_model_picker ?? false;
        const showDeepResearchModelOption = isDirectBaseModelChat && enableDeepResearch && enableWebSearch;

        const modelMenuItems = React.useMemo(() => {
            if (enableSimplifiedModelPicker) {
                return getSimplifiedModelMenuItems({
                    model,
                    handleModelSelect,
                    showDeepResearchModelOption,
                    useDeepResearch,
                    onEnableDeepResearch: handleEnableDeepResearch,
                    theme,
                });
            }

            const items = [
                ...Object.entries(modelNames)
                    .sort(comparatorOnFields(modelEntry => [modelEntry[1].order ?? Number.POSITIVE_INFINITY]))
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([m, modelEntry]) => !modelEntry.hideInMenu || m === model)
                    .map(([m]) => (
                        <ModelMenuItem
                            key={m}
                            model={m as Model}
                            isSelected={m === model}
                            onModelChange={handleModelSelect}
                        />
                    )),
            ];

            if (showDeepResearchModelOption) {
                items.push(
                    <MenuItem onClick={handleEnableDeepResearch} sx={modelMenuItemSx} key="deep-research">
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
                                <Typography
                                    variant="caption"
                                    fontWeight={useDeepResearch ? 550 : "normal"}
                                    color={useDeepResearch ? "secondary.main" : "neutrals.80"}
                                >
                                    Deep Research
                                </Typography>
                            </Box>
                            <Typography sx={{ color: "neutrals.60", fontSize: 10 }}>Deep web research </Typography>
                        </Box>
                    </MenuItem>,
                );
            }

            return items;
        }, [
            enableSimplifiedModelPicker,
            showDeepResearchModelOption,
            model,
            handleModelSelect,
            useDeepResearch,
            handleEnableDeepResearch,
            theme,
        ]);

        const modelDisplayName = React.useMemo(() => {
            if (useDeepResearch) return "Deep research";

            return getModelDisplayName(model, enableSimplifiedModelPicker);
        }, [model, useDeepResearch, enableSimplifiedModelPicker]);

        if (!enableModelPicker) {
            return null;
        }

        const icon = useDeepResearch ? (
            <Microscope size={20} variant="Bold" />
        ) : (
            <Setting4 size={20} variant="Outline" />
        );

        return (
            <>
                <ModelPickerLayout
                    displayName={modelDisplayName}
                    icon={icon}
                    isActive={useDeepResearch}
                    onMenuOpen={handleModelMenuOpen}
                    sx={sx}
                />

                <Menu
                    anchorEl={modelMenuAnchor}
                    open={Boolean(modelMenuAnchor)}
                    onClose={handleModelMenuClose}
                    sx={{ p: 1 }}
                    MenuListProps={{ sx: { py: 0.5 } }}
                >
                    {modelMenuItems}
                </Menu>
            </>
        );
    },
);

ModelPicker.displayName = "ModelPicker";

interface ModelMenuItemProps {
    model: Model;
    isSelected: boolean;
    onModelChange: (model: Model) => void;
}

const ModelMenuItem: React.FC<ModelMenuItemProps> = ({ model, isSelected, onModelChange }) => {
    return (
        <MenuItem onClick={() => onModelChange(model)} sx={modelMenuItemSx}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption" fontWeight={isSelected ? 550 : "normal"}>
                    {modelNames[model].name}
                </Typography>
                <Typography sx={{ color: "neutrals.60", fontSize: 10 }}>{modelNames[model].description}</Typography>
            </Box>
        </MenuItem>
    );
};

// Simplified model picker hooks and helpers
interface SimplifiedModelMenuItemsProps {
    model: Model;
    handleModelSelect: (model: Model) => void;
    showDeepResearchModelOption: boolean;
    useDeepResearch: boolean;
    onEnableDeepResearch: () => void;
    theme: Theme;
}

const getSimplifiedModelMenuItems = ({
    model,
    handleModelSelect,
    showDeepResearchModelOption,
    useDeepResearch,
    onEnableDeepResearch,
}: SimplifiedModelMenuItemsProps) => {
    const currentCategory = getModelCategory(model);

    const items = [
        <MenuItem
            key="default"
            onClick={() => handleModelSelect(SIMPLIFIED_MODEL_CATEGORIES.default.model)}
            sx={modelMenuItemSx}
        >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                    variant="caption"
                    fontWeight={currentCategory === "default" && !useDeepResearch ? 550 : "normal"}
                >
                    {SIMPLIFIED_MODEL_CATEGORIES.default.name}
                </Typography>
                <Typography sx={{ color: "neutrals.60", fontSize: 10 }}>
                    {SIMPLIFIED_MODEL_CATEGORIES.default.description}
                </Typography>
            </Box>
        </MenuItem>,

        <MenuItem
            key="reasoning"
            onClick={() => handleModelSelect(SIMPLIFIED_MODEL_CATEGORIES.reasoning.model)}
            sx={modelMenuItemSx}
        >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                    variant="caption"
                    fontWeight={currentCategory === "reasoning" && !useDeepResearch ? 550 : "normal"}
                >
                    {SIMPLIFIED_MODEL_CATEGORIES.reasoning.name}
                </Typography>
                <Typography sx={{ color: "neutrals.60", fontSize: 10 }}>
                    {SIMPLIFIED_MODEL_CATEGORIES.reasoning.description}
                </Typography>
            </Box>
        </MenuItem>,
    ];

    if (showDeepResearchModelOption) {
        items.push(
            <MenuItem onClick={onEnableDeepResearch} sx={modelMenuItemSx} key="deep-research">
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
                        <Typography
                            variant="caption"
                            fontWeight={useDeepResearch ? 550 : "normal"}
                            color={useDeepResearch ? "secondary.main" : "neutrals.80"}
                        >
                            {SIMPLIFIED_MODEL_CATEGORIES.deepResearch.name}
                        </Typography>
                    </Box>
                    <Typography sx={{ color: "neutrals.60", fontSize: 10 }}>
                        {SIMPLIFIED_MODEL_CATEGORIES.deepResearch.description}
                    </Typography>
                </Box>
            </MenuItem>,
        );
    }

    return items;
};
