import { queryOptions } from "@tanstack/react-query";
import {
    coreChatRouterGetChat,
    coreChatRouterListChats,
    coreFileStorageRouterListFiles,
    corePersonaRouterListPersonas,
    coreHistoryRouterGetRecentHistory,
    coreHistoryRouterSearchHistory,
    coreBlobRouterGetBlobInfoBatch,
    coreDeepResearchRouterGetDeepResearchRun,
    coreBlobRouterGenerateSasUrl,
} from "../backend-client/generated/sdk.gen";
import { coreAuthRouterGetCurrentUser, coreAuthRouterGetTenant } from "../backend-client/generated/sdk.gen";
import { DEFAULT_PERSONA } from "../data/defaultPersona";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../auth/authentication";
import { PersonData } from "../search/types";

export const chatQueryOptions = (chatId: string) =>
    queryOptions({
        queryKey: ["chat", chatId],
        queryFn: () => coreChatRouterGetChat({ path: { chat_id: chatId } }).then(response => response.data),
    });

export const chatsQueryOptions = queryOptions({
    queryKey: ["chats"],
    queryFn: () => coreChatRouterListChats().then(response => response.data),
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
});

const CURRENT_USER_CACHE_KEY = "cached_current_user";
export const currentUserQueryOptions = queryOptions({
    queryKey: ["currentUser"],
    queryFn: () =>
        coreAuthRouterGetCurrentUser({
            throwOnError: true,
            credentials: "include",
        }).then(response => {
            // Save to localStorage when we get new data
            localStorage.setItem(CURRENT_USER_CACHE_KEY, JSON.stringify(response.data));
            return response.data;
        }),
    placeholderData: () => {
        // Load from localStorage if available
        const cached = localStorage.getItem(CURRENT_USER_CACHE_KEY);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return cached ? JSON.parse(cached) : undefined;
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
});

const TENANT_CACHE_KEY = "cached_tenant";
export const tenantQueryOptions = queryOptions({
    queryKey: ["tenant"],
    queryFn: () =>
        coreAuthRouterGetTenant({ throwOnError: true }).then(response => {
            // Save to localStorage when we get new data
            localStorage.setItem(TENANT_CACHE_KEY, JSON.stringify(response.data));
            return response.data;
        }),
    placeholderData: () => {
        // Load from localStorage if available
        const cached = localStorage.getItem(TENANT_CACHE_KEY);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return cached ? JSON.parse(cached) : undefined;
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) != null,
});

export const personasQueryOptions = queryOptions({
    queryKey: ["personas"],
    queryFn: () => corePersonaRouterListPersonas().then(response => response.data),
    select: data => [...(data ?? []), DEFAULT_PERSONA],
});

export const getFilesQueryOptions = (folderId: string | undefined) =>
    queryOptions({
        queryKey: ["files", folderId],
        queryFn: () =>
            coreFileStorageRouterListFiles({
                query: { folder_id: folderId },
            }),
    });

export const recentHistoryQueryOptions = (type: "all" | "chats" | "searches") =>
    queryOptions({
        queryKey: ["history", "recent", type],
        queryFn: () =>
            coreHistoryRouterGetRecentHistory({
                body: { type },
            }).then(response => response.data?.items ?? []),
    });

export const searchHistoryQueryOptions = (query: string, type: "all" | "chats" | "searches") =>
    queryOptions({
        queryKey: ["history", "search", query, type],
        queryFn: () =>
            coreHistoryRouterSearchHistory({
                body: { query, type },
            }).then(response => response.data?.items ?? []),
        enabled: query.trim().length > 0,
    });

export const projectDocumentNamesQueryOptions = (project: PersonData["projects"][number]) =>
    queryOptions({
        queryKey: ["projectDocumentNames", project.file_ids],
        queryFn: async () => {
            const blobNames = project.file_ids.map(fileId => `${fileId}.pdf`);
            const response = await coreBlobRouterGetBlobInfoBatch({
                throwOnError: true,
                body: {
                    blob_names: project.file_ids.map(fileId => `${fileId}.pdf`),
                    container_type: "pdfs",
                },
            });
            return blobNames.map(name => response.data.results[name]?.name ?? undefined);
        },
    });

export const deepResearchRunQueryOptions = (deepResearchRunId: string) =>
    queryOptions({
        queryKey: ["deepResearchRun", deepResearchRunId],
        queryFn: () =>
            coreDeepResearchRouterGetDeepResearchRun({
                path: { deep_research_run_id: deepResearchRunId },
            }).then(response => response.data),
    });

export interface PdfSasUrlParams {
    blobName: string;
    isUserFile: boolean;
}

export const pdfSasUrlQueryOptions = ({ blobName, isUserFile }: PdfSasUrlParams) => {
    const DOCUMENT_EXTENSIONS = [".pdf", ".doc", ".docx", ".txt", ".xlsx", ".xls", ".ppt", ".pptx"];
    const hasValidExtension = DOCUMENT_EXTENSIONS.some(ext => blobName.toLowerCase().endsWith(ext));
    const fileNameWithEnding = hasValidExtension ? blobName : `${blobName}.pdf`;

    return queryOptions({
        queryKey: ["pdfSasUrl", fileNameWithEnding, isUserFile],
        queryFn: () =>
            coreBlobRouterGenerateSasUrl({
                throwOnError: true,
                path: { blob_name: encodeURIComponent(fileNameWithEnding) },
                query: {
                    container_type: isUserFile ? "user-pdfs-and-images" : "pdfs",
                },
            }).then(response => response.data.url),
        staleTime: 60 * 60 * 1000, // 1 hour
        gcTime: 2 * 60 * 60 * 1000, // 2 hours
    });
};
