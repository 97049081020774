import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { getShouldUsePropelAuth } from "../shouldUsePropelAuth";

export const PropelAuthLoggedOut: React.FC = () => {
    const handleLoginClick = React.useCallback(() => {
        if (getShouldUsePropelAuth() && process.env.REACT_APP_AUTH_URL) {
            window.location.href = process.env.REACT_APP_AUTH_URL;
        }
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                px: 3,
            }}
        >
            <Typography variant="h4" gutterBottom>
                You&apos;ve been logged out
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, textAlign: "center", maxWidth: 600 }}>
                Your session has expired.
            </Typography>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleLoginClick}
                size="large"
                sx={{ borderRadius: 10 }}
            >
                Log back in
            </Button>
        </Box>
    );
};
