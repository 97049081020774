import * as React from "react";
import { Typography } from "@mui/material";
import { PreviewSectionLayout } from "../../shared/previewSectionLayout";
import { AnswerAsSlide } from "./AnswerAsSlide";

interface AnswerAsSlidePreviewSectionProps {
    content: string;
    onClose: () => void;
}

export const AnswerAsSlidePreviewSection: React.FC<AnswerAsSlidePreviewSectionProps> = ({ content, onClose }) => {
    const header = React.useMemo(() => {
        return (
            <Typography variant="h6" color="secondary.main" noWrap>
                Slide Preview
            </Typography>
        );
    }, []);

    return (
        <PreviewSectionLayout header={header} onClose={onClose}>
            <AnswerAsSlide content={content} />
        </PreviewSectionLayout>
    );
};
