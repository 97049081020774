import React from "react";
import { Box } from "@mui/material";

export interface FileSourceItemLayoutProps {
    // "Slots" for each area that might differ between real & skeleton
    thumbnailSlot: React.ReactNode;
    titleSlot: React.ReactNode;
    metadataSlot: React.ReactNode;
    contentPreviewSlot?: React.ReactNode;
    pagesSlot?: React.ReactNode;
    focusedChatButtonSlot?: React.ReactNode;

    // If you still need click handlers, pass them too
    onClick?: () => void;
}

export function FileSourceItemLayout({
    thumbnailSlot,
    titleSlot,
    metadataSlot,
    contentPreviewSlot,
    pagesSlot,
    focusedChatButtonSlot,
    onClick,
}: FileSourceItemLayoutProps) {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                alignItems: "flex-start",
                gap: 1.5,
                overflow: "hidden",
                bgcolor: "surface.0",
                borderRadius: 2,
                border: 1,
                borderColor: "neutrals.25",
                px: 1,
                pt: 1,
                pb: 2,
                cursor: "pointer",
                "&:hover .add-to-chat": {
                    opacity: 1,
                },
            }}
            onClick={onClick}
        >
            {/* Thumbnail area */}
            <Box sx={{ mt: 0.6 }}>{thumbnailSlot}</Box>

            {/* Main content area */}
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden" }}>
                {/* Title */}
                {titleSlot}

                {/* Metadata (e.g. date, folder/project, tags, etc.) */}
                <Box sx={{ display: "flex", flexWrap: "wrap", mt: 0.25, gap: 1 }}>{metadataSlot}</Box>

                {/* Content preview if any */}
                {contentPreviewSlot && <Box sx={{ mt: 1 }}>{contentPreviewSlot}</Box>}

                {/* Pages if any */}
                {pagesSlot && <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>{pagesSlot}</Box>}
            </Box>

            {/* Button to add/remove from chat */}
            {focusedChatButtonSlot}
        </Box>
    );
}
