import * as React from "react";
import { faker } from "@faker-js/faker";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { Source } from "./types";
import { comparatorOnFields } from "../utils/comparators";
import { azureDocIntelligenceContentCleaner } from "../utils/contentCleaner";
import { DocumentThumbnail } from "./documentThumbnail";
import { FILE_EXTENSIONS_REGEX } from "./fileExtensions";
import { Add, Calendar, Folder, Minus } from "iconsax-react";
import { unique } from "../utils/arrays";
import { FileSourceItemLayout } from "./FileSourceItemLayout";

export const FileSourceItem: React.FC<{
    source: Source;
    idx: number;
    isAddedToFocusedChat: boolean;
    onClick: (idx: number, page: number | undefined) => void;
    onToggleFocusedChat: (idx: number) => void;
    isFileSelected: boolean;
    selectedPage: number | undefined;
}> = ({ source, idx, isAddedToFocusedChat, onClick, onToggleFocusedChat, isFileSelected, selectedPage }) => {
    const fakeUpdatedDate = React.useMemo(() => faker.date.recent({ days: 800 }), []);
    const realUpdatedDate = React.useMemo(
        () => (source.time_created != null ? new Date(source.time_created) : fakeUpdatedDate),
        [fakeUpdatedDate, source.time_created],
    );

    const formattedDate = React.useMemo(() => {
        const fourWeeksAgo = new Date();
        fourWeeksAgo.setDate(fourWeeksAgo.getDate() - 4 * 7);

        if (realUpdatedDate > fourWeeksAgo) {
            return formatDistanceToNow(realUpdatedDate, { addSuffix: true });
        }

        return realUpdatedDate.toLocaleString("en-US", { month: "long", year: "numeric" });
    }, [realUpdatedDate]);

    const handleClickAnywhere = React.useCallback(() => {
        onClick(idx, undefined);
    }, [idx, onClick]);

    const handleClickPage = React.useCallback(
        (page: number) => {
            onClick(idx, page);
        },
        [idx, onClick],
    );

    const sortedPages = React.useMemo(() => {
        return source.pages.sort(comparatorOnFields(p => [p.page]));
    }, [source.pages]);

    const cleanContent = React.useMemo(
        () => (source.pages.length > 0 ? azureDocIntelligenceContentCleaner(source.pages[0].content) : undefined),
        [source.pages],
    );

    const handleAddToFocusedChat = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onToggleFocusedChat(idx);
        },
        [idx, onToggleFocusedChat],
    );

    const shouldShowPages = React.useMemo(() => {
        if (source.file_type === "xlsx") {
            const uniquePages = new Set(source.pages.map(p => p.page));
            return uniquePages.size > 1;
        }
        return source.pages.length !== 0;
    }, [source.pages, source.file_type]);

    const uniquePagesForDisplay = React.useMemo(() => {
        return unique(sortedPages, p => p.page.toString());
    }, [sortedPages]);

    const theme = useTheme();

    // =============== Define Slot “Content” ===============

    // Thumbnail slot
    const thumbnailSlot = (
        <DocumentThumbnail
            blobName={source.thumbnail_url}
            fileType={source.file_type ?? undefined}
            isUserFile={source.is_user_file}
            sx={{ cursor: "pointer" }}
        />
    );

    // Title slot
    const titleSlot = (
        <Typography variant="body1" fontWeight={450} noWrap color="neutrals.80" sx={{ mt: 0.25, cursor: "pointer" }}>
            {source.file_name.replace(FILE_EXTENSIONS_REGEX, "")}
        </Typography>
    );

    // Metadata slot (date, project code, tags, etc.)
    const metadataSlot = (
        <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Calendar size={12} color={theme.palette.neutrals[60]} />
                <Typography variant="caption" color="neutrals.60" noWrap>
                    {formattedDate}
                </Typography>
            </Box>

            {(source.project_code != null || source.project_name != null) && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Folder size={12} color={theme.palette.neutrals[60]} />
                    <Typography
                        className="highlight-mask ph-no-capture"
                        variant="caption"
                        sx={{ color: "neutrals.60" }}
                    >
                        {source.project_name != null
                            ? `${source.project_name}${
                                  source.project_code != null ? ` (${formatProjectCode(source.project_code)})` : ""
                              }`
                            : formatProjectCode(source.project_code ?? "")}
                    </Typography>
                </Box>
            )}

            {source.tags?.map((tag, index) => (
                <Typography
                    variant="caption"
                    sx={{ color: "neutrals.60", bgcolor: "surface.75", borderRadius: 4, py: 0.25, px: 1 }}
                    key={index}
                >
                    • {tag}
                </Typography>
            ))}
        </>
    );

    // Content preview slot
    const contentPreviewSlot =
        cleanContent != null ? (
            <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    cursor: "pointer",
                }}
                onClick={handleClickAnywhere}
            >
                {cleanContent}
            </Typography>
        ) : undefined;

    // Pages slot (if any)
    const pagesSlot = shouldShowPages ? (
        <>
            {uniquePagesForDisplay.map(p => (
                <Box
                    key={`${source.file_name}-${p.page}-${p.rank}`}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        border: 1,
                        borderColor: isFileSelected && p.page === selectedPage ? "selection.main" : "neutrals.30",
                        borderRadius: 1,
                        bgcolor: isFileSelected && p.page === selectedPage ? "neutrals.25" : undefined,
                        px: 1,
                        cursor: "pointer",
                        "&:hover": {
                            bgcolor: "action.hover",
                        },
                    }}
                    onClick={e => {
                        e.stopPropagation();
                        handleClickPage(p.page);
                    }}
                >
                    <Typography variant="caption" color="neutrals.90">
                        {p.page_name ?? `Pg. ${p.page}`}
                    </Typography>
                </Box>
            ))}
        </>
    ) : undefined;

    // Focused chat button slot
    const focusedChatButtonSlot = (
        <Button
            className="add-to-chat"
            startIcon={
                isAddedToFocusedChat ? (
                    <Minus size={16} color={theme.palette.selection.main} />
                ) : (
                    <Add size={16} color={theme.palette.selection.main} />
                )
            }
            size="small"
            onClick={handleAddToFocusedChat}
            sx={{
                position: "absolute",
                top: 8,
                right: 8,
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                pr: 1,
                borderRadius: 8,
                bgcolor: "surface.0",
                border: 1,
                borderColor: "neutrals.25",
                color: "selection.main",
                cursor: "pointer",
                opacity: isAddedToFocusedChat ? 1 : 0,
                transition: "opacity 0.2s",
                "&:hover": {
                    bgcolor: "action.hover",
                },
                "& .MuiButton-startIcon": {
                    marginRight: 0,
                },
            }}
        >
            <Typography variant="caption" fontWeight={500}>
                {isAddedToFocusedChat ? "Remove from chat" : "Add to chat"}
            </Typography>
        </Button>
    );

    // Render the layout, passing in the “slots”
    return (
        <FileSourceItemLayout
            thumbnailSlot={thumbnailSlot}
            titleSlot={titleSlot}
            metadataSlot={metadataSlot}
            contentPreviewSlot={contentPreviewSlot}
            pagesSlot={pagesSlot}
            focusedChatButtonSlot={focusedChatButtonSlot}
            onClick={handleClickAnywhere}
        />
    );
};

function formatProjectCode(projectCode: string) {
    const isNumber = /^\d+$/.test(projectCode);
    return isNumber ? `${projectCode}` : projectCode;
}
