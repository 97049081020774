import React from "react";
import { Skeleton, Box } from "@mui/material";
import { ProjectAnswerCardLayout } from "./ProjectAnswerCardLayout";

const NO_OP = () => {
    return;
};

export function ProjectAnswerCardSkeleton() {
    // 1. The top-left slot: (project name, #id, optional tag, etc.)
    const topLeftSlot = (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {/* Row with project name + ID */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Skeleton variant="text" width={90} height={20} />
                <Skeleton variant="text" width={40} height={16} />
            </Box>
        </Box>
    );

    // 2. The top-right slot: (either one or two icons). We'll just show two squares as a placeholder.
    const topRightSlot = (
        <Box sx={{ display: "flex", gap: 1 }}>
            <Skeleton variant="rectangular" width={56} height={56} />
        </Box>
    );

    // 3. Middle slot: you might have 3 fields (client, target, date) or 1 field—just show skeleton lines here.
    const middleLeftSlot = (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Skeleton variant="text" width={120} height={18} />
            <Skeleton variant="text" width={100} height={18} />
            <Skeleton variant="text" width={80} height={18} />
        </Box>
    );

    const typeTag = <Skeleton variant="rectangular" width={56} height={20} />;

    // 4. Summary slot: previously the “AI callout”. Just a multiline skeleton block.
    const summarySlot = (
        <Box sx={{ width: "100%" }}>
            <Skeleton variant="text" width="90%" />
            <Skeleton variant="text" width="70%" />
        </Box>
    );

    const bottomSlot = (
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
            <Skeleton variant="text" width={80} height={16} />
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                {Array.from({ length: 3 }).map((_, i) => (
                    <Skeleton
                        key={i}
                        variant="circular"
                        width={24}
                        height={24}
                        sx={{
                            ml: i === 0 ? 0 : -0.8,
                            border: 1,
                            borderColor: "white",
                        }}
                    />
                ))}
            </Box>
        </Box>
    );

    return (
        <ProjectAnswerCardLayout
            isSelected={false}
            onClick={NO_OP}
            topLeftSlot={topLeftSlot}
            topRightSlot={topRightSlot}
            middleLeftSlot={middleLeftSlot}
            middleRightSlot={typeTag}
            summarySlot={summarySlot}
            bottomSlot={bottomSlot}
        />
    );
}
