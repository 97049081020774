import * as React from "react";
import { Box, IconButton, Menu, MenuItem, Typography, SxProps, Theme } from "@mui/material";
import { ArrowDown2, Global } from "iconsax-react";
import { useTheme } from "@mui/material/styles";
import { Model } from "./types";
import { tenantQueryOptions } from "./queryOptions";
import { useQuery } from "@tanstack/react-query";
import { Apartment, ApartmentSharp } from "@mui/icons-material";
import { ModelPicker } from "./externalModelPicker";
import { InternalModelPicker } from "./internalModelPicker";

interface ContextModeButtonProps {
    isDirectBaseModelChat: boolean;
    onToggle: (() => void) | undefined;
    model: Model;
    onModelChange: (model: Model) => void;
    menuPosition: "top" | "bottom";
    sx?: SxProps<Theme>;
    useDeepResearch: boolean;
    onDeepResearchToggle: () => void;
}

/** ContextModeButton is one or more buttons that allows the user to select the internal/external context mode for the chat. It also allows you to select the model for the chat when in external context mode. */
export const ContextModeButton: React.FC<ContextModeButtonProps> = React.memo(
    ({
        isDirectBaseModelChat,
        onToggle,
        model,
        onModelChange,
        sx,
        menuPosition,
        useDeepResearch,
        onDeepResearchToggle,
    }: ContextModeButtonProps) => {
        const theme = useTheme();
        const [contextMenuAnchor, setContextMenuAnchor] = React.useState<null | HTMLElement>(null);

        const handleContextMenuOpen = React.useCallback(
            (event: React.MouseEvent<HTMLElement>) => {
                if (onToggle == null) {
                    return;
                }
                event.stopPropagation();
                setContextMenuAnchor(event.currentTarget);
            },
            [onToggle],
        );

        const handleContextMenuClose = React.useCallback(() => {
            setContextMenuAnchor(null);
        }, []);

        const handleSelectIsBaseModelChat = React.useCallback(() => {
            if (onToggle != null && !isDirectBaseModelChat) {
                onToggle();
            }
            handleContextMenuClose();
        }, [onToggle, handleContextMenuClose, isDirectBaseModelChat]);

        const handleSelectIsTenantContext = React.useCallback(() => {
            if (onToggle != null && isDirectBaseModelChat) {
                onToggle();
            }
            handleContextMenuClose();
        }, [onToggle, handleContextMenuClose, isDirectBaseModelChat]);

        const tenant = useQuery(tenantQueryOptions);
        const enableDeepResearch = tenant.data?.enable_deep_research ?? false;

        return (
            <Box
                component="span"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                sx={[{ display: "flex", alignItems: "center", gap: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
            >
                <Box
                    onClick={handleContextMenuOpen}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        bgcolor: onToggle != null ? "surface.25" : "transparent",
                        py: 0.5,
                        pl: onToggle != null ? 1 : 0.5,
                        pr: 0.5,
                        borderRadius: 4,
                        cursor: onToggle != null ? "pointer" : "default",
                        // width: onToggle != null ? 170 : 130,
                        minWidth: 0,
                        height: 32,
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
                            {isDirectBaseModelChat ? (
                                <Global
                                    size={20}
                                    variant={onToggle != null ? "Bold" : "Outline"}
                                    color={onToggle != null ? theme.palette.secondary.main : theme.palette.neutrals[80]}
                                />
                            ) : (
                                <ApartmentSharp
                                    sx={{
                                        color:
                                            onToggle != null
                                                ? theme.palette.secondary.main
                                                : theme.palette.neutrals[80],
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                            )}
                            <Typography
                                variant="body2"
                                noWrap
                                color={onToggle != null ? "secondary.main" : "neutrals.80"}
                                fontWeight={onToggle != null ? 450 : "normal"}
                            >
                                {isDirectBaseModelChat ? "External context" : `${tenant?.data?.name ?? "Firm"} context`}
                            </Typography>
                        </Box>
                        {onToggle != null && (
                            <IconButton size="small" disableRipple disableFocusRipple disableTouchRipple>
                                <ArrowDown2 size={12} color={theme.palette.secondary.main} />
                            </IconButton>
                        )}
                    </Box>
                </Box>

                <Menu
                    anchorEl={contextMenuAnchor}
                    open={Boolean(contextMenuAnchor)}
                    onClose={handleContextMenuClose}
                    MenuListProps={{ sx: { py: 0.5 } }}
                    anchorOrigin={{ vertical: menuPosition === "top" ? "top" : "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: menuPosition === "top" ? "bottom" : "top", horizontal: "center" }}
                >
                    <MenuItem
                        onClick={handleSelectIsTenantContext}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            typography: "body2",
                            pl: 1.5,
                            cursor: onToggle != null ? "pointer" : "default",
                            bgcolor: isDirectBaseModelChat ? "transparent" : "surface.25",
                            color: isDirectBaseModelChat ? "neutrals.80" : "secondary.main",
                            fontWeight: isDirectBaseModelChat ? "normal" : 450,
                            "&:hover": {
                                bgcolor: onToggle != null ? undefined : "transparent",
                            },
                        }}
                        disableRipple={!onToggle}
                        disableTouchRipple={!onToggle}
                    >
                        <Apartment
                            sx={{
                                color: isDirectBaseModelChat
                                    ? theme.palette.neutrals[80]
                                    : theme.palette.secondary.main,
                                width: 16,
                                height: 16,
                            }}
                        />
                        {tenant?.data?.name ?? "Firm"} context
                    </MenuItem>
                    <MenuItem
                        onClick={handleSelectIsBaseModelChat}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            typography: "body2",
                            pl: 1.5,
                            cursor: onToggle != null ? "pointer" : "default",
                            bgcolor: !isDirectBaseModelChat ? "transparent" : "surface.25",
                            color: isDirectBaseModelChat ? "secondary.main" : "neutrals.80",
                            fontWeight: isDirectBaseModelChat ? 450 : "normal",
                            "&:hover": {
                                bgcolor: onToggle != null ? undefined : "transparent",
                            },
                        }}
                        disableRipple={!onToggle}
                        disableTouchRipple={!onToggle}
                    >
                        <Global
                            size={16}
                            variant="Bold"
                            color={isDirectBaseModelChat ? theme.palette.secondary.main : theme.palette.neutrals[80]}
                        />
                        External context
                    </MenuItem>
                </Menu>

                {isDirectBaseModelChat && (
                    <ModelPicker
                        model={model}
                        onModelChange={onModelChange}
                        useDeepResearch={useDeepResearch}
                        onDeepResearchToggle={onDeepResearchToggle}
                    />
                )}

                {!isDirectBaseModelChat && enableDeepResearch && (
                    <InternalModelPicker
                        useDeepResearch={useDeepResearch}
                        onDeepResearchToggle={onDeepResearchToggle}
                    />
                )}
            </Box>
        );
    },
);

ContextModeButton.displayName = "ContextModeButton";
