import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import "./App.css";

declare module "@mui/material/styles" {
    interface Palette {
        surface: {
            100: string;
            75: string;
            50: string;
            25: string;
            0: string;
        };
        neutrals: {
            100: string;
            90: string;
            80: string;
            70: string;
            60: string;
            50: string;
            40: string;
            30: string;
            25: string;
            20: string;
            10: string;
            5: string;
        };
        citations: {
            main: string;
        };
        selection: {
            main: string;
        };
        orange: {
            main: string;
        };
    }

    interface PaletteOptions {
        surface: {
            100: string;
            75: string;
            50: string;
            25: string;
            0: string;
        };
        neutrals: {
            100: string;
            90: string;
            80: string;
            70: string;
            60: string;
            50: string;
            40: string;
            30: string;
            25: string;
            20: string;
            10: string;
            5: string;
        };
        citations: {
            main: string;
        };
        selection: {
            main: string;
        };
        orange: {
            main: string;
        };
    }

    interface Theme {
        name: "default" | "blue-1" | "black-1" | "blue-2";
    }

    interface ThemeOptions {
        name?: "default" | "blue-1" | "black-1" | "blue-2";
    }
}

export const defaultTheme = createTheme({
    name: "default",
    palette: {
        primary: {
            light: "#98A2B3",
            main: "#F8F8F7",
        },
        secondary: {
            light: "#F7F8F6",
            main: "#365934",
        },
        success: {
            main: "#7AB143",
        },
        error: {
            main: red.A400,
        },
        surface: {
            100: "#E3DAC8",
            75: "#F0EDE6",
            50: "#F4F2EF",
            25: "#F8F8F7",
            0: "#FFFFFF",
        },
        neutrals: {
            100: "#101323",
            90: "#1D2939",
            80: "#344054",
            70: "#475467",
            60: "#667085",
            50: "#98A2B3",
            40: "#D0D5DD",
            30: "#E7E6E4",
            25: "#F0F0EF",
            20: "#F2F4F7",
            10: "#F9FAFB",
            5: "#FCFCFD",
        },
        selection: {
            main: "#669461",
        },
        text: {
            primary: "#344054",
        },
        citations: {
            main: "#0288d1",
        },
        orange: {
            main: "#FCCA6E",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 16,
                },
            },
        },
    },
});

export const blueCustomTheme = createTheme({
    name: "blue-1",
    palette: {
        primary: {
            light: "#98A2B3",
            main: "#F7F8F8",
        },
        secondary: {
            // TODO: Freestyle
            light: "#F1F9FA",
            main: "#066C83",
        },
        success: {
            main: "#7AB143",
        },
        error: {
            main: "#F62D44",
        },
        surface: {
            100: "#C8DEE3",
            75: "#E6EFF0",
            50: "#EFF3F4",
            25: "#F7F8F8",
            0: "#FFFFFF",
        },
        neutrals: {
            100: "#101323",
            90: "#1D2939",
            80: "#344054",
            70: "#475467",
            60: "#667085",
            50: "#98A2B3",
            40: "#D0D5DD",
            30: "#E4E6E7",
            25: "#EFF0F0",
            20: "#F2F4F7",
            10: "#F9FAFB",
            5: "#FCFCFD",
        },
        selection: {
            main: "#F2672C",
        },
        text: {
            primary: "#344054",
        },
        citations: {
            main: "#F2672C",
        },
        orange: {
            main: "#FCCA6E",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 16,
                },
            },
        },
    },
});

export const blueCustomTheme2 = createTheme({
    name: "blue-2",
    palette: {
        primary: {
            light: "#98A2B3",
            main: "#F7F8F8",
        },
        secondary: {
            light: "#F1F9FA",
            main: "#114362",
        },
        success: {
            main: "#7AB143",
        },
        error: {
            main: "#F62D44",
        },
        surface: {
            100: "#B5D5E5",
            75: "#D4E4F2",
            50: "#E7F1F9",
            25: "#F2F8FC",
            0: "#FFFFFF",
        },
        neutrals: {
            100: "#101323",
            90: "#1D2939",
            80: "#344054",
            70: "#475467",
            60: "#667085",
            50: "#98A2B3",
            40: "#D0D5DD",
            30: "#E4E6E7",
            25: "#EFF0F0",
            20: "#F2F4F7",
            10: "#F9FAFB",
            5: "#FCFCFD",
        },
        selection: {
            main: "#F2672C",
        },
        text: {
            primary: "#344054",
        },
        citations: {
            main: "#F2672C",
        },
        orange: {
            main: "#FCCA6E",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 16,
                },
            },
        },
    },
});

export const blackCustomTheme = createTheme({
    name: "black-1",
    palette: {
        primary: {
            light: "#CCCCCC", // Light gray
            main: "#FFFFFF", // White (inverted from black)
            contrastText: "#000000", // Black text on white
        },
        secondary: {
            light: "#333333", // Dark gray
            main: "#000000", // Black
            contrastText: "#FFFFFF", // Ensures text is readable on primary color
        },
        success: {
            main: "#4A4A4A", // Medium dark gray
            contrastText: "#FFFFFF",
        },
        error: {
            main: "#B00020", // Dark red converted to dark gray
            contrastText: "#FFFFFF",
        },
        surface: {
            100: "#CCCCCC", // Light gray
            75: "#E6E6E6", // Very light gray
            50: "#F5F5F5", // Almost white
            25: "#F9F9F9", // White
            0: "#FFFFFF",
        },
        neutrals: {
            // Start of Selection
            100: "#000000", // Black
            90: "#010101", // Almost black
            80: "#0A0A0A", // Very dark gray
            70: "#232323", // Dark gray
            60: "#3D3D3D", // Medium dark gray
            50: "#565656", // Medium gray
            40: "#707070", // Gray
            30: "#898989", // Light gray
            25: "#A3A3A3", // Lighter gray
            20: "#A3A3A3", // Lighter gray
            10: "#CCCCCC", // Dark gray
            5: "#D6D6D6", // Near white
        },
        selection: {
            main: "#666666", // Medium gray
        },
        text: {
            primary: "#000000", // Kept black text
            secondary: "#333333", // Dark gray text
            disabled: "#666666", // Medium gray for disabled text
        },
        citations: {
            main: "#666666", // Medium gray
        },
        orange: {
            main: "#FCCA6E",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 16,
                },
            },
        },
    },
});

const darkNeutrals = {
    100: "#FCFCFD",
    90: "#F9FAFB",
    80: "#F2F4F7",
    70: "#E7E6E4",
    60: "#D0D5DD",
    50: "#98A2B3",
    40: "#667085",
    30: "#475467",
    25: "#344054",
    20: "#1D2939",
    10: "#101323",
    5: "#080A0F",
} as const;

export const defaultDarkTheme = createTheme({
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        mode: "dark",
        primary: {
            main: "#111c10", // dark green
            contrastText: "#FFFFFF",
        },
        secondary: {
            light: "#81d979", // light green
            main: "#6fb869", // Darker green
            contrastText: "#FFFFFF",
        },
        surface: {
            100: "#0A1F0C", // Darkest green
            75: "#0A1A0C",
            50: "#122815",
            25: "#1A321D",
            0: "#223C26", // Lightest green
        },
        neutrals: darkNeutrals,
        background: {
            default: "#0A1F0C",
            paper: "#0A1F0C",
        },
        text: {
            primary: "#dbdbdb",
            secondary: "#E0E0E0",
        },
    },
});

export const blueDarkTheme = createTheme({
    ...blueCustomTheme,
    palette: {
        ...blueCustomTheme.palette,
        mode: "dark",
        primary: {
            main: "#0F2734", // Original secondary
        },
        secondary: {
            light: "#143444", // Lighter blue
            main: "#7bc3d4", // Lighter blue
        },
        neutrals: darkNeutrals,
        surface: {
            100: "#0A1B24", // Dark blue
            75: "#143344",
            50: "#0F2734",
            25: "#143344",
            0: "#1d495e", // Lightest blue
        },
        background: {
            default: "#0A1B24",
            paper: "#0A1B24",
        },
        text: {
            primary: "#dbdbdb",
            secondary: "#E0E0E0",
        },
    },
});

export const blackDarkTheme = createTheme({
    ...blackCustomTheme,
    palette: {
        ...blackCustomTheme.palette,
        mode: "dark",
        primary: {
            main: "#000000",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#333333",
        },
        neutrals: darkNeutrals,
        surface: {
            100: "#000000", // True black surfaces
            75: "#0D0D0D",
            50: "#1A1A1A",
            25: "#262626",
            0: "#323232",
        },
        background: {
            default: "#000000",
            paper: "#0D0D0D",
        },
        text: {
            primary: "#FFFFFF",
            secondary: "#E0E0E0",
        },
    },
});

export const blueDarkTheme2 = createTheme({
    ...blueCustomTheme2,
    palette: {
        ...blueCustomTheme2.palette,
        mode: "dark",
        primary: {
            main: "#0A1F34", // Darker version of the blue theme
        },
        secondary: {
            light: "#143444", // Lighter blue
            main: "#71AEDD", // Using the light blue from blueCustomTheme2
        },
        neutrals: darkNeutrals,
        surface: {
            100: "#081624", // Darkest blue
            75: "#0F2A44",
            50: "#0A1F34",
            25: "#0F2A44",
            0: "#1A3D66", // Lightest blue
        },
        background: {
            default: "#081624",
            paper: "#081624",
        },
        text: {
            primary: "#dbdbdb",
            secondary: "#E0E0E0",
        },
    },
});
