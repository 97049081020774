import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useAuthInfo, useRedirectFunctions } from "@propelauth/react";
import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { isProduction } from "../utils/isProduction";

export const PropelAuthSignIn: React.FC = () => {
    const authInfo = useAuthInfo();
    const { redirectToLoginPage } = useRedirectFunctions();
    const hasRedirected = React.useRef(false);

    React.useLayoutEffect(() => {
        // Redirect to PropelAuth login page immediately when component mounts
        if (authInfo.isLoggedIn != null && !authInfo.isLoggedIn && !hasRedirected.current) {
            console.log("Redirecting to PropelAuth login page");
            console.log(process.env.REACT_APP_AUTH_URL);
            redirectToLoginPage();
            hasRedirected.current = true;
        }
    }, [authInfo.isLoggedIn, redirectToLoginPage]);

    // Return an empty div while redirecting
    return (
        <>
            <Helmet>
                <title>Sign In - AnswerGrid</title>
            </Helmet>
            {/* // TODO: Fix */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100vw",
                }}
            >
                <CircularProgress sx={{ color: "secondary.main", width: 48, height: 48 }} />
                {!isProduction && <Typography>Redirecting to external log in page from /login...</Typography>}
            </Box>
        </>
    );
};
