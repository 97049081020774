import { createClient } from "@propelauth/javascript";
import * as React from "react";
import { useAsyncData } from "../utils/hooks";
import { getShouldUsePropelAuth } from "../shouldUsePropelAuth";
import { useNavigate } from "react-router-dom";

const isPropelAuth = getShouldUsePropelAuth();

export const LOCAL_STORAGE_ACCESS_TOKEN = "newton.accessToken";

export const propelAuthClient = isPropelAuth
    ? createClient({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          authUrl: process.env.REACT_APP_AUTH_URL!,
          disableRefreshOnFocus: true,
          enableBackgroundTokenRefresh: false,
      })
    : null;

export const isHomegrownLoggedIn = () => {
    return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) != null;
};

export const isPropelLoggedIn = () => {
    return (
        propelAuthClient?.getAuthenticationInfoOrNull().then(info => info?.accessToken != null) ??
        Promise.resolve(false)
    );
};

export const hasToken = async () => {
    if (isPropelAuth) {
        return isPropelLoggedIn();
    }
    return Promise.resolve(isHomegrownLoggedIn());
};

export const useIsLoggedIn = () => {
    const loginChangeCounter = React.useRef(0);
    React.useEffect(() => {
        if (propelAuthClient == null) {
            return;
        }

        const observer = () => {
            loginChangeCounter.current++;
        };

        propelAuthClient.addLoggedInChangeObserver(observer);

        return () => {
            propelAuthClient?.removeLoggedInChangeObserver(observer);
        };
    }, []);

    return useAsyncData(() => hasToken(), [loginChangeCounter.current]);
};

export const useLogout = () => {
    const navigate = useNavigate();

    return React.useCallback(async () => {
        if (window.confirm("Are you sure you want to sign out?")) {
            if (isPropelAuth) {
                await propelAuthClient?.logout(true);
            } else {
                localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
                navigate("/login");
            }
        }
    }, [navigate]);
};

export function getToken() {
    if (isPropelAuth) {
        return (
            propelAuthClient?.getAuthenticationInfoOrNull().then(info => info?.accessToken ?? undefined) ??
            Promise.resolve(undefined)
        );
    }
    return Promise.resolve(localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) ?? undefined);
}

export const useToken = () => {
    const loginChangeCounter = React.useRef(0);

    React.useEffect(() => {
        if (propelAuthClient == null) {
            return;
        }

        const observer = () => {
            loginChangeCounter.current++;
        };

        propelAuthClient.addLoggedInChangeObserver(observer);

        return () => {
            propelAuthClient?.removeLoggedInChangeObserver(observer);
        };
    }, []);

    return useAsyncData(async () => {
        if (isPropelAuth) {
            const info = await propelAuthClient?.getAuthenticationInfoOrNull();
            return info?.accessToken ?? undefined;
        }
        return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) ?? undefined;
    }, [loginChangeCounter.current]);
};
