import { H } from "highlight.run";
import { coreAuthRouterGetCurrentUser } from "../backend-client/generated/sdk.gen";
import posthog from "posthog-js";

const SESSION_USER_IDENTIFIED = "userIdentified";

export const identifyUserForSession = async (): Promise<void> => {
    // Check if we've already identified the user this session
    if (sessionStorage.getItem(SESSION_USER_IDENTIFIED)) {
        return;
    }

    try {
        const userResponse = await coreAuthRouterGetCurrentUser({
            throwOnError: true,
            credentials: "include",
        });

        // Identify user in Highlight
        H.identify(userResponse.data.username, {
            email: userResponse.data.email,
            id: userResponse.data.id.toString(),
        });

        posthog.identify(userResponse.data.username, {
            email: userResponse.data.email,
            name: userResponse.data.first_name + " " + userResponse.data.last_name,
        });

        // Mark that we've identified the user this session
        sessionStorage.setItem(SESSION_USER_IDENTIFIED, "true");
    } catch (error) {
        console.error("Failed to identify user:", error);
    }
};
