import { Model } from "./types";

export const DEFAULT_MODEL_FOR_BASE_CHAT = "chatgpt-4o-latest";

export function isNonStreamingReasoningModel(model: Model) {
    return model === "o1" || model === "o1-mini" || model === "o3-mini";
}

export const modelNames: Record<Model, { name: string; description: string; hideInMenu?: boolean; order?: number }> = {
    "chatgpt-4o-latest": { name: "GPT-4o", description: "Great for most tasks", order: 0 },
    "gpt-4o": { name: "GPT-4o", description: "Great for most tasks", hideInMenu: true, order: 1 },
    o1: { name: "o1", description: "Uses advanced reasoning", order: 2 },
    "o3-mini": { name: "o3-mini", description: "Fast at advanced reasoning", order: 3 },
    "gpt-4o-mini": { name: "GPT-4o mini", description: "Faster for everyday tasks", order: 4, hideInMenu: true },
    "o1-mini": { name: "o1-mini", description: "Faster at reasoning", hideInMenu: true, order: 5 },
    "deepseek-r1": { name: "DeepSeek R1", description: "Fast reasoning model", order: 6, hideInMenu: true },
};

export const SIMPLIFIED_MODEL_CATEGORIES = {
    default: {
        name: "Default",
        description: "Great for most tasks",
        model: "chatgpt-4o-latest" as Model,
        models: ["gpt-4o", "chatgpt-4o-latest", "gpt-4o-mini"] as Model[],
    },
    reasoning: {
        name: "Reasoning",
        description: "Uses advanced reasoning",
        model: "o1" as Model,
        models: ["o1", "o1-mini", "o3-mini", "deepseek-r1"] as Model[],
    },
    deepResearch: {
        name: "Deep Research",
        description: "Deep web research",
    },
};

export const getModelCategory = (model: Model): "default" | "reasoning" | "default" => {
    if (SIMPLIFIED_MODEL_CATEGORIES.default.models.includes(model)) {
        return "default";
    }

    if (SIMPLIFIED_MODEL_CATEGORIES.reasoning.models.includes(model)) {
        return "reasoning";
    }

    // If the model doesn't match any category, default to "default"
    return "default";
};

export const getModelDisplayName = (model: Model, useSimplifiedNames: boolean): string => {
    if (!useSimplifiedNames) {
        // Return the detailed model name from modelNames
        return modelNames[model].name;
    }

    const category = getModelCategory(model);
    return SIMPLIFIED_MODEL_CATEGORIES[category].name;
};
