import * as React from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BACKEND_URL } from "../backend-client/url";
import {
    coreChatRouterGetChat,
    CoreChatRouterRespondToUserResponse,
    coreFileStorageRouterGetFiles,
} from "../backend-client/generated";
import { SESSION_STORAGE_NEW_CHAT_QUERY } from "./sessionStorage";
import { chatQueryOptions, chatsQueryOptions, tenantQueryOptions } from "./queryOptions";
import {
    AssistantChatMessage,
    UserChatMessage,
    AssistantChatMessageSource,
    ChatOutSchema,
    CoreFileStorageRouterGetFilesResponse,
    ChatRespondToUserSchema,
    ProjectWithAiSummary,
    MemberWithProjects,
    AssistantChatMessageWebSearchSource,
    AdministrativeChatMessage,
    ChatSourceFilters,
    ChangeDeepResearchModeAdministrativeAction,
    ChangeContextModeAdministrativeAction,
    ChangeModelAdministrativeAction,
} from "../backend-client/generated/types.gen";
import { ChatSplitLayout } from "./chatSplitLayout";
import { ChatHeaderForTenant } from "./chatHeaderForTenant";
import { SelectedFile } from "./context/focusDocuments";
import { ChatInput } from "./chatInput";
import { DEFAULT_PERSONA_ID } from "../data/defaultPersona";
import { SelectedPersona } from "./selectedPersona";
import { getToken } from "../auth/authentication";
import { PreviewSection } from "../shared/previewSection";
import { Model } from "./types";
import { FileType, SourceType } from "../shared/types";
import {
    areFiltersEmpty,
    EMPTY_SOURCE_FILTERS,
    getSourceFiltersMandatory,
    asUndefinedIfEmpty,
} from "./getSourceFilters";
import { PersonData } from "../search/types";
import { ProjectAnswerPreviewSection } from "../search/preview/projectAnswerPreviewSection";
import { PeopleAnswerPreviewSection } from "../search/preview/peopleAnswerPreviewSection";
import {
    SelectedDeepResearchRunData,
    SelectedPersonData,
    SelectedProjectData,
    SelectedSourceInfo,
    SelectedSystemMessageSlideData,
} from "./selectionState";
import { SystemMessage } from "./systemMessage";
import { convertPersonToCard, convertProjectToCard } from "./converters";
import { DEFAULT_MODEL_FOR_BASE_CHAT, getModelDisplayName, isNonStreamingReasoningModel } from "./models";
import { isEqual } from "lodash";
import { AnswerAsSlidePreviewSection } from "./preview/answerAsSlidePreviewSection";
import { DeepResearchPreviewSection } from "./preview/deepResearchPreviewSection";

const DEFAULT_SELECTED_PERSONA: SelectedPersona = {
    id: DEFAULT_PERSONA_ID,
    type: "tenant",
};

export const ChatView: React.FC = () => {
    return (
        <SidebarLayout>
            <ChatViewLayoutContent />
        </SidebarLayout>
    );
};

function filterOutNonCitedSources(
    sources: RequiredAssistantChatMessageSource[],
    citedRanks: Set<number>,
): RequiredAssistantChatMessageSource[] {
    return sources
        .map(s => ({
            ...s,
            pages: s.pages?.filter(p => citedRanks.has(p.rank)) ?? [],
        }))
        .filter(s => s.pages.length > 0);
}

function filterOutNonCitedProjects(projects: ProjectWithAiSummary[], citedIdx: Set<number>): ProjectWithAiSummary[] {
    return projects.filter((p, idx) => citedIdx.has(idx));
}

type RequiredAssistantChatMessageSource = Required<AssistantChatMessageSource>;

function getModeInfo(
    // chat: ChatOutSchema | undefined,
    isDirectBaseModelChat: boolean,
    oldIsDirectBaseModelChat: boolean,
    model: Model | undefined,
    oldModel: Model | undefined,
    currentSourceFilters: ChatSourceFilters,
    haveFiltersChanged: boolean,
    useDeepResearch: boolean,
    oldUseDeepResearch: boolean,
): ChatRespondToUserSchema["mode_info"] | undefined {
    const modeInfo: ChatRespondToUserSchema["mode_info"] =
        oldIsDirectBaseModelChat !== isDirectBaseModelChat
            ? isDirectBaseModelChat
                ? { type: "direct_base_model_chat" as const, model, use_deep_research: useDeepResearch }
                : {
                      type: "internal" as const,
                      source_filters: currentSourceFilters,
                      use_deep_research: useDeepResearch,
                  }
            : isDirectBaseModelChat && (model !== oldModel || useDeepResearch !== oldUseDeepResearch)
              ? {
                    type: "direct_base_model_chat" as const,
                    model: model !== oldModel ? model : undefined,
                    use_deep_research: useDeepResearch !== oldUseDeepResearch ? useDeepResearch : undefined,
                }
              : !isDirectBaseModelChat && (haveFiltersChanged || useDeepResearch !== oldUseDeepResearch)
                ? {
                      type: "internal" as const,
                      source_filters: haveFiltersChanged ? currentSourceFilters : undefined,
                      use_deep_research: useDeepResearch !== oldUseDeepResearch ? useDeepResearch : undefined,
                  }
                : undefined;
    return modeInfo;
}

// TODO: Not for surek, since it can complete running but have no sources, but it's a good heuristic
function isDeepResearchProbablyInProgress(chat: ChatOutSchema | undefined) {
    const lastMessage = chat?.messages[chat.messages.length - 1];
    if (lastMessage == null || lastMessage.role !== "assistant") {
        return false;
    }
    const lastAssistantMessage = lastMessage;
    return lastAssistantMessage.deep_research_run_id != null && lastAssistantMessage.sources.length === 0;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChatViewLayoutContentProps {}

const ChatViewLayoutContent: React.FC<ChatViewLayoutContentProps> = () => {
    const { chatId } = useParams<{ chatId: string }>();
    const [selectedSourceInfo, setSelectedSourceInfo] = React.useState<SelectedSourceInfo | undefined>(undefined);
    const [query, setQuery] = React.useState<string>("");
    const [lastQuery, setLastQuery] = React.useState<string | undefined>(undefined);
    const [isStreaming, setIsStreaming] = React.useState(false);
    const [isTimeSensitive, setIsTimeSensitive] = React.useState(false);
    const [streamedResponse, setStreamedResponse] = React.useState<string>("");
    const [streamedSources, setStreamedSources] = React.useState<RequiredAssistantChatMessageSource[] | undefined>(
        undefined,
    );
    const [streamedProjects, setStreamedProjects] = React.useState<ProjectWithAiSummary[] | undefined>(undefined);
    const [streamedPeople, setStreamedPeople] = React.useState<MemberWithProjects[] | undefined>(undefined);
    const [streamedWebSources, setStreamedWebSources] = React.useState<
        AssistantChatMessageWebSearchSource[] | undefined
    >(undefined);
    const [streamedReasoningTime, setStreamedReasoningTime] = React.useState<number | undefined>(undefined);
    const [streamedIsSearchingTheWeb, setStreamedIsSearchingTheWeb] = React.useState<boolean | undefined>(undefined);
    const [streamedHasSatisfactoryAnswer, setStreamedHasSatisfactoryAnswer] = React.useState<boolean | undefined>(
        undefined,
    );
    const [streamedDeepResearchRunId, setStreamedDeepResearchRunId] = React.useState<string | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const [model, setModel] = React.useState<Model | undefined>();

    const [projectFilters, setProjectFilters] = React.useState<string[]>([]);
    const [sourceTypes, setSourceTypes] = React.useState<SourceType[]>([]);
    const [fileTypes, setFileTypes] = React.useState<FileType[]>([]);
    const [earliestDate, setEarliestDate] = React.useState<Date | undefined>(undefined);
    const [isFiltersExpanded, setIsFiltersExpanded] = React.useState(false);

    const [selectedProjectData, setSelectedProjectData] = React.useState<SelectedProjectData | undefined>(undefined);

    const [selectedPersonData, setSelectedPersonData] = React.useState<SelectedPersonData | undefined>(undefined);

    const [selectedSystemMessageSlideData, setSelectedSystemMessageSlideData] = React.useState<
        SelectedSystemMessageSlideData | undefined
    >(undefined);

    const [selectedDeepResearchRunData, setSelectedDeepResearchRunData] = React.useState<
        SelectedDeepResearchRunData | undefined
    >(undefined);

    const scrollRef = React.useRef<HTMLDivElement>(null);
    const scrollPositionRef = React.useRef(0);

    const [isDirectBaseModelChat, setIsDirectBaseModelChat] = React.useState<boolean>(false);
    const [useDeepResearch, setUseDeepResearch] = React.useState<boolean>(false);

    const {
        data: chat,
        isLoading,
        isError,
    } = useQuery({
        ...chatQueryOptions(chatId ?? ""),
        refetchInterval: state =>
            isStreaming || isDeepResearchProbablyInProgress(state.state.data) ? false : undefined,
        refetchOnWindowFocus: state => !isStreaming && !isDeepResearchProbablyInProgress(state.state.data),
    });

    useDerivedState(
        chat,
        setModel,
        setProjectFilters,
        setSourceTypes,
        setFileTypes,
        setEarliestDate,
        setIsDirectBaseModelChat,
        setUseDeepResearch,
    );

    const queryClient = useQueryClient();

    const handleQueryChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []);

    const handleDirectBaseModelChatToggle = React.useCallback(() => {
        setIsDirectBaseModelChat(prev => !prev);
    }, []);

    const handleDeepResearchToggle = React.useCallback(() => {
        setUseDeepResearch(prev => !prev);
    }, []);

    const handleFetchResponse = React.useCallback(
        async (submittedQuery: string, submittedIsDirectBaseModelChat: boolean) => {
            if (chatId == null || submittedQuery.trim().length === 0) {
                return;
            }

            setIsStreaming(true);
            setStreamedResponse("");
            setStreamedSources(undefined);
            setStreamedProjects(undefined);
            setStreamedPeople(undefined);
            setStreamedWebSources(undefined);
            setStreamedReasoningTime(undefined);
            setStreamedIsSearchingTheWeb(undefined);
            setLastQuery(submittedQuery);
            setIsTimeSensitive(false);
            setStreamedHasSatisfactoryAnswer(undefined);
            setStreamedDeepResearchRunId(undefined);

            const token = await getToken();
            if (token == null) {
                console.error("No auth token found");
                setIsStreaming(false);
                return;
            }

            const url = `${BACKEND_URL}/api/chat/respond/${chatId}`;
            const abortController = new AbortController();

            const currentSourceFilters = getSourceFiltersMandatory(
                projectFilters,
                sourceTypes,
                fileTypes,
                earliestDate,
            );
            const haveFiltersChanged = !isEqual(currentSourceFilters, chat?.source_filters ?? EMPTY_SOURCE_FILTERS);

            const modeInfo = getModeInfo(
                submittedIsDirectBaseModelChat,
                chat?.is_direct_base_model_chat ?? false,
                model,
                chat?.model ?? undefined,
                currentSourceFilters,
                haveFiltersChanged,
                useDeepResearch,
                chat?.use_deep_research ?? false,
            );
            // Create administrative messages based on mode changes

            const administrativeMessages: AdministrativeChatMessage[] = [];

            // Handle deep research mode changes across cases
            if (useDeepResearch !== (chat?.use_deep_research ?? false)) {
                administrativeMessages.push({
                    role: "administrative",
                    administrative_action: {
                        type: "change_deep_research_mode",
                        use_deep_research: useDeepResearch,
                    },
                });
            }

            if (modeInfo?.type === "internal") {
                if (!chat?.is_direct_base_model_chat) {
                    // Already in internal mode
                    if (haveFiltersChanged) {
                        administrativeMessages.push({
                            role: "administrative",
                            administrative_action: {
                                type: "change_source_filters",
                                old_filters: chat?.source_filters ?? null,
                                new_filters: currentSourceFilters ?? null,
                            },
                        });
                    }
                } else {
                    // Switching from direct base model to internal mode
                    administrativeMessages.push({
                        role: "administrative",
                        administrative_action: {
                            type: "change_context_mode",
                            is_direct_base_model_chat: false,
                        },
                    });
                    // Only add filter change message if filters are not empty
                    if (!areFiltersEmpty(currentSourceFilters)) {
                        administrativeMessages.push({
                            role: "administrative",
                            administrative_action: {
                                type: "change_source_filters",
                                old_filters: null,
                                new_filters: currentSourceFilters ?? null,
                            },
                        });
                    }
                }
            } else if (modeInfo?.type === "direct_base_model_chat" && !chat?.is_direct_base_model_chat) {
                // Switching from internal to direct base model mode
                administrativeMessages.push({
                    role: "administrative",
                    administrative_action: {
                        type: "change_context_mode",
                        is_direct_base_model_chat: true,
                    },
                });
                if (modeInfo.model && modeInfo.model !== chat?.model) {
                    administrativeMessages.push({
                        role: "administrative",
                        administrative_action: {
                            type: "change_model",
                            model: modeInfo.model,
                        },
                    });
                }
            } else if (
                modeInfo?.type === "direct_base_model_chat" &&
                chat?.is_direct_base_model_chat &&
                modeInfo.model != null &&
                modeInfo.model !== chat?.model
            ) {
                // Staying in direct base model mode but changing the model
                administrativeMessages.push({
                    role: "administrative",
                    administrative_action: {
                        type: "change_model",
                        model: modeInfo.model ?? DEFAULT_MODEL_FOR_BASE_CHAT,
                    },
                });
            }

            // Update cache with administrative messages before making the request
            if (administrativeMessages.length > 0) {
                queryClient.setQueryData(chatQueryOptions(chatId).queryKey, oldData => {
                    if (oldData == null) return oldData;
                    return {
                        ...oldData,
                        is_direct_base_model_chat:
                            modeInfo?.type === "direct_base_model_chat"
                                ? true
                                : modeInfo?.type === "internal"
                                  ? false
                                  : oldData.is_direct_base_model_chat,
                        model:
                            modeInfo?.type === "direct_base_model_chat"
                                ? modeInfo.model
                                : modeInfo?.type === "internal"
                                  ? undefined
                                  : oldData.model,
                        source_filters:
                            modeInfo?.type === "internal"
                                ? asUndefinedIfEmpty(modeInfo.source_filters ?? undefined)
                                : oldData.source_filters,
                        messages:
                            oldData.messages.length > 0
                                ? [...oldData.messages, ...administrativeMessages]
                                : oldData.messages,
                        use_deep_research:
                            modeInfo?.type === "internal" && modeInfo.use_deep_research != null
                                ? modeInfo.use_deep_research
                                : oldData.use_deep_research,
                    };
                });
            }

            const body: ChatRespondToUserSchema = {
                query: submittedQuery,
                mode_info: modeInfo,
            };

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(body),
                    signal: abortController.signal,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.body == null) {
                    throw new Error("No body returned by the server.");
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");
                let buffer = "";
                let textBuffer = "";
                let completeResponse = "";
                let completeSources: RequiredAssistantChatMessageSource[] | undefined = undefined;
                let completeProjects: ProjectWithAiSummary[] | undefined = undefined;
                let completePeople: MemberWithProjects[] | undefined = undefined;
                let completeWebSources: AssistantChatMessageWebSearchSource[] | undefined = undefined;
                let streamedIsTimeSensitive = false;
                let streamedReasoningTime: number | undefined = undefined;
                let completeHasSatisfactoryAnswer: boolean | undefined = undefined;
                let completeDeepResearchRunId: string | undefined = undefined;

                const processChunk = async ({
                    done,
                    value,
                }: {
                    done: boolean;
                    value?: Uint8Array;
                }): Promise<Uint8Array | undefined> => {
                    if (done) {
                        if (textBuffer.length > 0) {
                            completeResponse += textBuffer;
                            setStreamedResponse(prev => prev + textBuffer);
                        }
                        return;
                    }

                    if (value) {
                        buffer += decoder.decode(value, { stream: true });
                        const lines = buffer.split("\n");
                        buffer = lines.pop() ?? "";

                        for (const line of lines) {
                            if (line.trim()) {
                                try {
                                    const parsed = JSON.parse(line) as CoreChatRouterRespondToUserResponse;
                                    switch (parsed.t) {
                                        case "r_chnk": {
                                            textBuffer += parsed.d;
                                            const match = textBuffer.match(/(.*?[.,!?;:\s\]}])/);
                                            if (match) {
                                                const completeText = match[1];
                                                textBuffer = textBuffer.slice(completeText.length);
                                                completeResponse += completeText;
                                                setStreamedResponse(prev => prev + completeText);
                                            }
                                            break;
                                        }
                                        case "sources":
                                            completeSources = parsed.d.map(source => ({
                                                name: source.file_name,
                                                pages: source.pages,
                                                file_type: source.file_type ?? null,
                                                blob_name: source.blob_name ?? null,
                                                is_user_file: source.is_user_file ?? null,
                                                time_created: source.time_created ?? null,
                                                project_code: source.project_code ?? null,
                                            }));
                                            setStreamedSources(completeSources);
                                            break;
                                        case "projects":
                                            completeProjects = parsed.d;
                                            setStreamedProjects(completeProjects);
                                            break;
                                        case "status":
                                            if (parsed.d === "completed" || parsed.d === "no-results") {
                                                setIsStreaming(false);
                                            }
                                            break;
                                        case "citations": {
                                            const usedRanks = new Set(parsed.d);
                                            completeSources =
                                                completeSources != null
                                                    ? filterOutNonCitedSources(completeSources, usedRanks)
                                                    : undefined;

                                            completeProjects =
                                                completeProjects != null
                                                    ? filterOutNonCitedProjects(completeProjects, usedRanks)
                                                    : undefined;

                                            break;
                                        }
                                        case "error":
                                            console.error("Error from stream:", parsed.d);
                                            setIsStreaming(false);
                                            break;
                                        case "is_time_sensitive":
                                            streamedIsTimeSensitive = parsed.d;
                                            setIsTimeSensitive(streamedIsTimeSensitive);
                                            break;
                                        case "people":
                                            completePeople = parsed.d;
                                            setStreamedPeople(completePeople);
                                            break;
                                        case "web_sources":
                                            completeWebSources = parsed.d;
                                            setStreamedWebSources(completeWebSources);
                                            break;
                                        case "reasoning_time":
                                            streamedReasoningTime = parsed.d;
                                            setStreamedReasoningTime(streamedReasoningTime);
                                            break;
                                        case "is_web_search":
                                            setStreamedIsSearchingTheWeb(parsed.d);
                                            break;
                                        case "has_answer":
                                            setStreamedHasSatisfactoryAnswer(parsed.d);
                                            completeHasSatisfactoryAnswer = parsed.d;
                                            break;
                                        case "deep_research_run_id":
                                            completeDeepResearchRunId = parsed.d;
                                            setStreamedDeepResearchRunId(completeDeepResearchRunId);
                                            break;
                                    }
                                } catch (err) {
                                    console.error("Failed to parse JSON line:", err, line);
                                }
                            }
                        }
                    }

                    return reader.read().then(processChunk);
                };

                // After we've finished reading the stream, we can apply the same logic from the former effect
                await reader.read().then(processChunk);

                if (completeResponse.trim().length > 0 && chatId != null) {
                    const numMessagesBeforeCompletion = queryClient.getQueryData(chatQueryOptions(chatId).queryKey)
                        ?.messages.length;

                    const lastUserMessage: UserChatMessage = {
                        role: "user",
                        content: submittedQuery,
                    };
                    const newAssistantMessage: AssistantChatMessage = {
                        role: "assistant",
                        content: completeResponse,
                        sources: completeSources ?? [],
                        people: completePeople,
                        web_sources: completeWebSources,
                        are_sources_recency_sensitive: streamedIsTimeSensitive,
                        projects: completeProjects,
                        reasoning_time: streamedReasoningTime,
                        has_satisfactory_internal_answer: completeHasSatisfactoryAnswer ?? true,
                        deep_research_run_id: completeDeepResearchRunId,
                    };
                    queryClient.setQueryData(chatQueryOptions(chatId).queryKey, oldData => {
                        if (oldData == null) return oldData;
                        return {
                            ...oldData,
                            messages: [...oldData.messages, lastUserMessage, newAssistantMessage],
                        };
                    });

                    setStreamedResponse("");
                    setLastQuery(undefined);
                    setStreamedSources(undefined);
                    setStreamedProjects(undefined);
                    setStreamedPeople(undefined);
                    setStreamedWebSources(undefined);
                    setStreamedReasoningTime(undefined);
                    setStreamedIsSearchingTheWeb(undefined);
                    setIsTimeSensitive(false);
                    setStreamedHasSatisfactoryAnswer(undefined);
                    setStreamedDeepResearchRunId(undefined);
                    // We auto-set the name on the BE for the first message, so we need to refetch if this was the first exchange
                    if (numMessagesBeforeCompletion != null && numMessagesBeforeCompletion === 0) {
                        try {
                            const refetchResponse = await coreChatRouterGetChat({ path: { chat_id: chatId } });
                            const refetchedChat = refetchResponse.data;
                            if (refetchedChat != null) {
                                queryClient.setQueryData(chatQueryOptions(chatId).queryKey, old => {
                                    if (old == null) return old;
                                    return {
                                        ...old,
                                        name: refetchedChat.name,
                                        messages:
                                            old.messages.length > numMessagesBeforeCompletion
                                                ? old.messages
                                                : [...old.messages, lastUserMessage, newAssistantMessage],
                                    };
                                });

                                queryClient.setQueryData(chatsQueryOptions.queryKey, old => {
                                    if (old == null) return old;
                                    return old.map(c =>
                                        c.unique_id === chatId ? { ...c, name: refetchedChat.name } : c,
                                    );
                                });
                            }
                        } catch (error) {
                            console.error("Failed to fetch chat:", error);
                        }
                    }
                }
            } catch (error) {
                if (error instanceof Error) {
                    if (error.name === "AbortError") {
                        return;
                    }
                    console.error("Stream failed:", error);
                    setIsStreaming(false);
                }
            }

            return () => {
                abortController.abort();
            };
        },
        [
            chat?.is_direct_base_model_chat,
            chat?.model,
            chat?.source_filters,
            chat?.use_deep_research,
            chatId,
            earliestDate,
            fileTypes,
            model,
            projectFilters,
            queryClient,
            sourceTypes,
            useDeepResearch,
        ],
    );

    const handleSubmitCurrentQuery = React.useCallback(() => {
        void handleFetchResponse(query, isDirectBaseModelChat);
        setQuery("");
    }, [handleFetchResponse, query, isDirectBaseModelChat]);

    const handleSelectSource = React.useCallback((systemMessageIdx: number, sourceIdx: number, page: number) => {
        setSelectedSourceInfo({ systemMessageIdx, sourceIdx, page });
        setSelectedProjectData(undefined);
        setSelectedPersonData(undefined);
        setSelectedSystemMessageSlideData(undefined);
        setSelectedDeepResearchRunData(undefined);
    }, []);

    const handleSelectProject = React.useCallback((systemMessageIdx: number, projectIdx: number, fileIdx?: number) => {
        setSelectedProjectData({ systemMessageIdx, projectIdx, fileIdx });
        setSelectedSourceInfo(undefined);
        setSelectedPersonData(undefined);
        setSelectedSystemMessageSlideData(undefined);
        setSelectedDeepResearchRunData(undefined);
    }, []);

    const handleClosePreview = React.useCallback(() => {
        setSelectedSourceInfo(undefined);
        setSelectedProjectData(undefined);
        setSelectedPersonData(undefined);
        setSelectedSystemMessageSlideData(undefined);
        setSelectedDeepResearchRunData(undefined);
    }, []);

    const handleSelectSystemMessageSlide = React.useCallback((systemMessageIdx: number) => {
        setSelectedSystemMessageSlideData({ systemMessageIdx });
        setSelectedSourceInfo(undefined);
        setSelectedProjectData(undefined);
        setSelectedPersonData(undefined);
        setSelectedDeepResearchRunData(undefined);
    }, []);

    const handleSelectDeepResearchRun = React.useCallback((systemMessageIdx: number) => {
        setSelectedDeepResearchRunData({ systemMessageIdx });
        setSelectedSourceInfo(undefined);
        setSelectedProjectData(undefined);
        setSelectedPersonData(undefined);
        setSelectedSystemMessageSlideData(undefined);
    }, []);

    const handlePageChange = React.useCallback((newPage: number) => {
        setSelectedSourceInfo(prev => {
            if (prev == null) return undefined;
            return { ...prev, page: newPage };
        });
    }, []);

    const disabledReason = React.useMemo(() => {
        if (query.trim().length === 0) return "Please enter a message";
        if (isStreaming) return "Sending message…";
        if (isLoading) return "Loading chat…";
        return null;
    }, [query, isStreaming, isLoading]);

    // Handle "new" chat in search params
    React.useEffect(() => {
        if (searchParams.has("new")) {
            const storedQuery = sessionStorage.getItem(SESSION_STORAGE_NEW_CHAT_QUERY);

            if (storedQuery != null && storedQuery.trim().length > 0) {
                void handleFetchResponse(storedQuery, isDirectBaseModelChat);
                sessionStorage.removeItem(SESSION_STORAGE_NEW_CHAT_QUERY);
                setSearchParams({}, { replace: true });
            }
        }
    }, [searchParams, handleFetchResponse, setSearchParams, isDirectBaseModelChat]);

    const selectedSource = React.useMemo((): AssistantChatMessageSource | undefined => {
        if (selectedSourceInfo?.sourceIdx == null && selectedSourceInfo?.systemMessageIdx == null) return undefined;
        const message = chat?.messages[selectedSourceInfo.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        return message.sources[selectedSourceInfo.sourceIdx];
    }, [chat?.messages, selectedSourceInfo?.systemMessageIdx, selectedSourceInfo?.sourceIdx]);

    const selectedProject = React.useMemo(() => {
        if (selectedProjectData == null) return undefined;
        const message = chat?.messages[selectedProjectData.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        const project = message.projects?.[selectedProjectData.projectIdx];

        return project != null ? convertProjectToCard(project) : undefined;
    }, [chat?.messages, selectedProjectData]);

    const selectedPerson = React.useMemo((): PersonData | undefined => {
        if (selectedPersonData == null) return undefined;
        const message = chat?.messages[selectedPersonData.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        const person = message.people?.[selectedPersonData.personIdx];
        return person != null ? convertPersonToCard(person) : undefined;
    }, [chat?.messages, selectedPersonData]);

    const selectedSlideAnswer = React.useMemo(() => {
        if (selectedSystemMessageSlideData == null) return undefined;
        const message = chat?.messages[selectedSystemMessageSlideData.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        return message.content;
    }, [chat?.messages, selectedSystemMessageSlideData]);

    const selectedDeepResearchRunId = React.useMemo(() => {
        if (selectedDeepResearchRunData == null) return undefined;
        const message = chat?.messages[selectedDeepResearchRunData.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        return message.deep_research_run_id;
    }, [chat?.messages, selectedDeepResearchRunData]);

    const saveScrollPosition = React.useCallback(() => {
        if (scrollRef.current && !isStreaming) {
            scrollPositionRef.current = scrollRef.current.scrollTop;
        }
    }, [isStreaming]);

    const isInitialLoad = React.useRef(true);

    React.useEffect(() => {
        if (
            scrollRef.current &&
            chat?.messages.length != null &&
            chat.messages.length > 0 &&
            isInitialLoad.current &&
            !scrollPositionRef.current
        ) {
            const element = scrollRef.current;
            element.scrollTop = element.scrollHeight;
            isInitialLoad.current = false;
        }
    }, [chat?.messages.length, scrollRef]);

    const scrollToBottom = React.useCallback((element: HTMLDivElement) => {
        requestAnimationFrame(() => {
            element.scrollTo({
                top: element.scrollHeight,
                behavior: "smooth",
            });
        });
    }, []);

    const debouncedScrollToBottom = React.useMemo(() => {
        let timeoutId: number;
        return (element: HTMLDivElement) => {
            if (timeoutId) {
                cancelAnimationFrame(timeoutId);
            }
            timeoutId = requestAnimationFrame(() => {
                scrollToBottom(element);
            });
        };
    }, [scrollToBottom]);

    React.useEffect(() => {
        if (isStreaming && scrollRef.current) {
            debouncedScrollToBottom(scrollRef.current);
        }
    }, [isStreaming, debouncedScrollToBottom, streamedResponse]);

    // TODO: REMOVE PATCHEDCHAT AND JUST USE CHAT
    const patchedChat = React.useMemo(() => {
        if (isStreaming && lastQuery != null && chat != null) {
            const userMessage: UserChatMessage = { role: "user", content: lastQuery };
            return { ...chat, messages: [...chat.messages, userMessage] };
        }
        return chat;
    }, [chat, isStreaming, lastQuery]);

    const { data: tenant } = useQuery(tenantQueryOptions);

    const initialData = React.useMemo((): CoreFileStorageRouterGetFilesResponse | null => {
        if (chat?.user_file_scope == null) return null;
        return chat.user_file_scope.map(fileId => ({
            unique_id: fileId,
            blob_name: fileId,
            file_name: fileId,
            file_type: "pdf",
            is_indexing: false,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
        }));
    }, [chat?.user_file_scope]);

    const { data: files } = useQuery({
        queryKey: ["files", chat?.user_file_scope],
        queryFn: async () => {
            if (chat?.user_file_scope == null) return null;
            return coreFileStorageRouterGetFiles({
                body: { file_ids: chat.user_file_scope },
            }).then(res => res.data);
        },
        enabled: chat?.user_file_scope != null && chat.user_file_scope.length > 0,
        select: (data): SelectedFile[] =>
            data?.map<SelectedFile>(file => ({
                id: file.unique_id,
                name: file.file_name,
                fileType: file.file_type,
                isIndexing: file.is_indexing,
                createdAt: file.created_at,
                updatedAt: file.updated_at,
            })) ?? [],
        initialData,
    });

    const selectedPersona = React.useMemo((): SelectedPersona => {
        if (patchedChat?.user_persona != null) {
            return { id: patchedChat.user_persona.unique_id, type: "user" };
        }
        if (patchedChat?.tenant_persona != null) {
            return { id: patchedChat.tenant_persona.unique_id, type: "tenant" };
        }
        return DEFAULT_SELECTED_PERSONA;
    }, [patchedChat]);

    const handleSelectFileForProjectPreview = React.useCallback(
        (fileIdx: number | undefined) => {
            setSelectedProjectData(prev => {
                if (prev == null) return prev;
                return { ...prev, fileIdx };
            });
        },
        [setSelectedProjectData],
    );

    const handleDeselectProject = React.useCallback(() => {
        setSelectedProjectData(undefined);
    }, [setSelectedProjectData]);

    const handleSelectPerson = React.useCallback(
        (systemMessageIdx: number, personIdx: number, projectIdx?: number, fileIdx?: number) => {
            setSelectedPersonData({ systemMessageIdx, personIdx, projectIdx, fileIdx });
            setSelectedSourceInfo(undefined);
            setSelectedProjectData(undefined);
        },
        [],
    );

    const handleSelectProjectAndOptionalFileForPerson = React.useCallback(
        (projectIdx: number | undefined, fileIdx?: number) => {
            setSelectedPersonData(prev => {
                if (prev == null) return prev;
                return { ...prev, projectIdx, fileIdx };
            });
            setSelectedSourceInfo(undefined);
            setSelectedProjectData(undefined);
        },
        [],
    );

    const handleDeselectPerson = React.useCallback(() => {
        setSelectedPersonData(undefined);
    }, [setSelectedPersonData]);

    const handleWebSearchInNewQuery = React.useCallback(() => {
        if (isStreaming) {
            return;
        }
        const userMessages = chat?.messages.filter((msg): msg is UserChatMessage => msg.role === "user");
        const lastUserMessage = userMessages?.[userMessages.length - 1];
        if (lastUserMessage != null) {
            setIsDirectBaseModelChat(true);
            void handleFetchResponse(lastUserMessage.content, true);
            setQuery("");
        }
    }, [isStreaming, chat?.messages, handleFetchResponse, setIsDirectBaseModelChat]);

    if (chatId == null) {
        return (
            <SidebarLayout>
                <Typography>No chat selected</Typography>
            </SidebarLayout>
        );
    }

    const mainContent = (
        <ChatViewContent
            chat={chat}
            lastQuery={lastQuery}
            isDirectBaseModelChat={isDirectBaseModelChat}
            isLoading={isLoading}
            isError={isError}
            isStreaming={isStreaming}
            isTimeSensitive={isTimeSensitive}
            selectedSourceInfo={selectedSourceInfo}
            selectedProjectData={selectedProjectData}
            selectedPersonData={selectedPersonData}
            selectedSystemMessageSlideData={selectedSystemMessageSlideData}
            selectedDeepResearchRunData={selectedDeepResearchRunData}
            streamedResponse={streamedResponse}
            streamedSources={streamedSources}
            streamedProjects={streamedProjects}
            streamedWebSources={streamedWebSources}
            streamedPeople={streamedPeople}
            streamedReasoningTime={streamedReasoningTime}
            streamedIsSearchingTheWeb={streamedIsSearchingTheWeb}
            streamedHasSatisfactoryAnswer={streamedHasSatisfactoryAnswer}
            streamedDeepResearchRunId={streamedDeepResearchRunId}
            query={query}
            disabledReason={disabledReason}
            model={model}
            useDeepResearch={useDeepResearch}
            onWebSearchInNewQuery={handleWebSearchInNewQuery}
            onQueryChange={handleQueryChange}
            onPersonSelect={handleSelectPerson}
            onSubmit={handleSubmitCurrentQuery}
            onSourceSelect={handleSelectSource}
            onProjectSelect={handleSelectProject}
            onModelChange={setModel}
            onDirectBaseModelChatToggle={handleDirectBaseModelChatToggle}
            onDeepResearchToggle={handleDeepResearchToggle}
            onSelectSystemMessageSlide={handleSelectSystemMessageSlide}
            onSelectDeepResearchRun={handleSelectDeepResearchRun}
        />
    );

    const header = (
        <ChatHeaderForTenant
            tenant={tenant}
            chatId={chatId}
            chatName={patchedChat?.name}
            isLoading={isLoading}
            tenantFocusedDocumentNames={chat?.file_scope}
            // TODO: what happens if a fiel is deleted that was used in focus? We should still show
            focusedDocuments={files}
            onFocusedDocumentsChange={undefined}
            selectedPersona={selectedPersona}
            onSelectedPersonaChange={undefined}
            disabledEditingFiltersReason={isStreaming ? "Sending message…" : undefined}
            projects={projectFilters}
            sourceTypes={sourceTypes}
            fileTypes={fileTypes}
            earliestDate={earliestDate}
            isFiltersExpanded={isFiltersExpanded}
            hideSourceFilters={isDirectBaseModelChat}
            onProjectsChange={setProjectFilters}
            onSourceTypesChange={setSourceTypes}
            onFileTypesChange={setFileTypes}
            onEarliestDateChange={setEarliestDate}
            onFiltersExpandedChange={setIsFiltersExpanded}
        />
    );

    return (
        <ChatSplitLayout
            header={header}
            mainContent={mainContent}
            preview={
                selectedSource != null ? (
                    <ChatPreviewSection
                        source={selectedSource}
                        page={selectedSourceInfo?.page ?? 1}
                        onPageChange={handlePageChange}
                        onClose={handleClosePreview}
                    />
                ) : selectedPerson != null ? (
                    <PeopleAnswerPreviewSection
                        person={selectedPerson}
                        projectIdx={selectedPersonData?.projectIdx}
                        fileIdx={selectedPersonData?.fileIdx}
                        onSelect={handleSelectProjectAndOptionalFileForPerson}
                        onClose={handleDeselectPerson}
                    />
                ) : selectedProject != null ? (
                    <ProjectAnswerPreviewSection
                        project={selectedProject}
                        fileIdx={selectedProjectData?.fileIdx}
                        onSelect={handleSelectFileForProjectPreview}
                        onClose={handleDeselectProject}
                    />
                ) : selectedSlideAnswer != null ? (
                    <AnswerAsSlidePreviewSection content={selectedSlideAnswer} onClose={handleClosePreview} />
                ) : selectedDeepResearchRunId != null ? (
                    <DeepResearchPreviewSection
                        deepResearchRunId={selectedDeepResearchRunId}
                        onClose={handleClosePreview}
                    />
                ) : undefined
            }
            mainContentProps={{
                bgcolor: "primary.main",
                borderRadius: 3,
                border: 1,
                borderColor: "neutrals.30",
            }}
            scrollRef={scrollRef}
            onScroll={saveScrollPosition}
        />
    );
};

interface ChatViewContentProps {
    chat: ChatOutSchema | undefined;
    lastQuery: string | undefined;
    isLoading: boolean;
    isError: boolean;
    isStreaming: boolean;
    isTimeSensitive: boolean;
    streamedResponse: string;
    streamedSources: AssistantChatMessageSource[] | undefined;
    streamedProjects: ProjectWithAiSummary[] | undefined;
    streamedWebSources: AssistantChatMessageWebSearchSource[] | undefined;
    query: string;
    disabledReason: string | null;
    selectedSourceInfo: SelectedSourceInfo | undefined;
    selectedProjectData: SelectedProjectData | undefined;
    selectedPersonData: SelectedPersonData | undefined;
    selectedSystemMessageSlideData: SelectedSystemMessageSlideData | undefined;
    selectedDeepResearchRunData: SelectedDeepResearchRunData | undefined;
    streamedPeople: MemberWithProjects[] | undefined;
    streamedReasoningTime: number | undefined;
    streamedIsSearchingTheWeb: boolean | undefined;
    streamedHasSatisfactoryAnswer: boolean | undefined;
    streamedDeepResearchRunId: string | undefined;
    model: Model | undefined;
    isDirectBaseModelChat: boolean;
    useDeepResearch: boolean;
    onWebSearchInNewQuery: () => void;
    onDirectBaseModelChatToggle: () => void;
    onDeepResearchToggle: () => void;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
    onSourceSelect: (systemMessageIdx: number, sourceIdx: number, page: number) => void;
    onProjectSelect: (systemMessageIdx: number, projectIdx: number, fileIdx?: number) => void;
    onSelectSystemMessageSlide: (systemMessageIdx: number) => void;
    onSelectDeepResearchRun: (systemMessageIdx: number) => void;
    onModelChange: (model: Model) => void;
    onPersonSelect: (systemMessageIdx: number, personIdx: number, projectIdx?: number) => void;
}

const ChatViewContent: React.FC<ChatViewContentProps> = ({
    chat,
    lastQuery,
    isLoading,
    isError,
    isStreaming,
    isTimeSensitive,
    isDirectBaseModelChat,
    streamedResponse,
    streamedSources,
    streamedProjects,
    streamedWebSources,
    streamedPeople,
    streamedReasoningTime,
    streamedIsSearchingTheWeb,
    streamedHasSatisfactoryAnswer,
    streamedDeepResearchRunId,
    query,
    disabledReason,
    selectedSourceInfo,
    selectedProjectData,
    selectedPersonData,
    selectedSystemMessageSlideData,
    selectedDeepResearchRunData,
    model,
    useDeepResearch,
    onWebSearchInNewQuery,
    onQueryChange,
    onSubmit,
    onSourceSelect,
    onProjectSelect,
    onModelChange,
    onPersonSelect,
    onSelectSystemMessageSlide,
    onSelectDeepResearchRun,
    onDirectBaseModelChatToggle,
    onDeepResearchToggle,
}) => {
    const { data: tenant } = useQuery(tenantQueryOptions);

    const partitionedMessages = React.useMemo(() => {
        if (!chat?.messages) return [];

        const result: (
            | { type: "content"; msg: UserChatMessage | AssistantChatMessage; idx: number }
            | { type: "admin"; msg: { role: "admin"; messages: AdministrativeChatMessage[] } }
        )[] = [];

        for (let i = 0; i < chat.messages.length; i++) {
            const message = chat.messages[i];

            if (message.role === "administrative") {
                const adminMessages: AdministrativeChatMessage[] = [message];
                // Look ahead for adjacent administrative messages to group them together
                while (i + 1 < chat.messages.length && chat.messages[i + 1].role === "administrative") {
                    i++;
                    adminMessages.push(chat.messages[i] as AdministrativeChatMessage);
                }
                result.push({ type: "admin", msg: { role: "admin", messages: adminMessages } });
            } else {
                result.push({ type: "content", msg: message, idx: i });
            }
        }

        return result;
    }, [chat?.messages]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "space-between",
                flexGrow: 1,
                flexShrink: 1,
                minWidth: 0,
                borderRadius: 3,
                // mb: 2,
                // height: "100%",
                bgcolor: "primary.main",
            }}
        >
            <Box
                // ref={scrollRef}
                // onScroll={onScroll}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflowY: "auto",
                    px: 2,
                    pt: 2,
                }}
            >
                {isLoading ? (
                    <></>
                ) : isError ? (
                    <Typography>Error loading chat</Typography>
                ) : (
                    <>
                        {partitionedMessages.map((message, _partitionIdx) => {
                            if (message.type === "admin") {
                                return (
                                    <AdminMessage
                                        key={`admin_${_partitionIdx}`}
                                        actions={message.msg.messages}
                                        enableSimplifiedModelPicker={tenant?.enable_simplified_model_picker ?? false}
                                    />
                                );
                            }
                            if (message.type === "content" && message.msg.role === "user") {
                                return <UserMessage key={`user_${message.idx}`} content={message.msg.content} />;
                            }
                            if (message.type === "content" && message.msg.role === "assistant") {
                                return (
                                    <SystemMessage
                                        key={`assistant_${message.idx}`}
                                        chatId={chat?.unique_id ?? "unknown"}
                                        content={message.msg.content}
                                        deepResearchRunId={message.msg.deep_research_run_id ?? undefined}
                                        sources={message.msg.sources}
                                        people={message.msg.people ?? undefined}
                                        projects={message.msg.projects ?? undefined}
                                        webSources={message.msg.web_sources ?? undefined}
                                        reasoningTime={message.msg.reasoning_time ?? undefined}
                                        selectedSourceInfo={
                                            selectedSourceInfo?.systemMessageIdx === message.idx
                                                ? selectedSourceInfo
                                                : undefined
                                        }
                                        selectedProjectData={
                                            selectedProjectData?.systemMessageIdx === message.idx
                                                ? selectedProjectData
                                                : undefined
                                        }
                                        selectedPersonData={
                                            selectedPersonData?.systemMessageIdx === message.idx
                                                ? selectedPersonData
                                                : undefined
                                        }
                                        selectedDeepResearchRunData={
                                            selectedDeepResearchRunData?.systemMessageIdx === message.idx
                                                ? selectedDeepResearchRunData
                                                : undefined
                                        }
                                        isSelectedForSlideExport={
                                            selectedSystemMessageSlideData?.systemMessageIdx === message.idx
                                        }
                                        systemMessageIdx={message.idx}
                                        hasSatisfactoryAnswer={message.msg.has_satisfactory_internal_answer ?? true}
                                        isLastMessage={message.idx === partitionedMessages.length - 1}
                                        isNonStreamingReasoningModel={false}
                                        isSearchingTheWeb={undefined}
                                        areSourcesRecencySensitive={message.msg.are_sources_recency_sensitive ?? false}
                                        onSourceSelect={onSourceSelect}
                                        onProjectSelect={onProjectSelect}
                                        onPersonSelect={onPersonSelect}
                                        onSelectSystemMessageSlide={onSelectSystemMessageSlide}
                                        onWebSearchInNewQuery={onWebSearchInNewQuery}
                                        onSelectDeepResearchRun={onSelectDeepResearchRun}
                                    />
                                );
                            }
                        })}
                        {lastQuery != null && <UserMessage content={lastQuery} />}
                        {isStreaming && (
                            <SystemMessage
                                areSourcesRecencySensitive={isTimeSensitive}
                                content={streamedResponse}
                                chatId={chat?.unique_id ?? "unknown"}
                                deepResearchRunId={streamedDeepResearchRunId}
                                reasoningTime={streamedReasoningTime}
                                sources={streamedSources}
                                projects={streamedProjects}
                                webSources={streamedWebSources}
                                people={streamedPeople}
                                hasSatisfactoryAnswer={streamedHasSatisfactoryAnswer ?? true}
                                isLastMessage={true}
                                isSelectedForSlideExport={false}
                                systemMessageIdx={chat?.messages.length ?? 0}
                                selectedSourceInfo={undefined}
                                selectedProjectData={undefined}
                                selectedPersonData={undefined}
                                selectedDeepResearchRunData={
                                    selectedDeepResearchRunData?.systemMessageIdx === chat?.messages.length
                                        ? selectedDeepResearchRunData
                                        : undefined
                                }
                                onSourceSelect={undefined}
                                onProjectSelect={undefined}
                                onPersonSelect={undefined}
                                onSelectSystemMessageSlide={undefined}
                                onWebSearchInNewQuery={undefined}
                                onSelectDeepResearchRun={undefined}
                                isStreaming={true}
                                isNonStreamingReasoningModel={isNonStreamingReasoningModel(
                                    model ?? DEFAULT_MODEL_FOR_BASE_CHAT,
                                )}
                                isSearchingTheWeb={streamedIsSearchingTheWeb}
                            />
                        )}
                    </>
                )}
            </Box>
            <Box
                sx={{
                    position: "sticky",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: "primary.main",
                    pb: 2,
                    maxWidth: "100%",
                    zIndex: 10,
                }}
            >
                <ChatInput
                    query={query}
                    onQueryChange={onQueryChange}
                    onQuerySubmit={onSubmit}
                    disabledReason={disabledReason}
                    attachDisabledReason={
                        (tenant?.can_chat_with_docs ?? false)
                            ? "You can only attach files to your first message. To attach new files, start a new chat"
                            : undefined
                    }
                    isDirectBaseModelChat={isDirectBaseModelChat}
                    onDirectBaseModelChatToggle={onDirectBaseModelChatToggle}
                    useDeepResearch={useDeepResearch}
                    onDeepResearchToggle={onDeepResearchToggle}
                    model={model}
                    onModelChange={onModelChange}
                />
            </Box>
        </Box>
    );
};

// New components
const UserMessage: React.FC<{ content: string }> = ({ content }) => (
    <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Box
            sx={{
                maxWidth: "75%",
                px: 2,
                py: 1.5,
                borderRadius: 2,
                bgcolor: "surface.75",
            }}
        >
            <Typography whiteSpace="pre-wrap">{content}</Typography>
        </Box>
    </Box>
);

const AdminMessage: React.FC<{ actions: AdministrativeChatMessage[]; enableSimplifiedModelPicker: boolean }> = ({
    actions,
    enableSimplifiedModelPicker,
}) => {
    const tenant = useQuery(tenantQueryOptions);
    const tenantName = tenant.data?.name ?? "internal";

    const message = React.useMemo(() => {
        // Special case for context mode + model change combination
        if (actions.length === 2) {
            const contextModeAction = actions.find(
                a => a.administrative_action.type === "change_context_mode",
            )?.administrative_action;
            const modelAction = actions.find(a => a.administrative_action.type === "change_model")
                ?.administrative_action as ChangeModelAdministrativeAction | undefined;

            if (contextModeAction != null && modelAction != null) {
                const modelDisplayName = getModelDisplayName(modelAction.model, enableSimplifiedModelPicker);
                return `Context changed to ${contextModeAction.type == "change_context_mode" && contextModeAction.is_direct_base_model_chat ? "external" : tenantName} with model ${modelDisplayName}`;
            }
        }
        if (actions.length === 2) {
            const contextModeAction = actions.find(a => a.administrative_action.type === "change_context_mode")
                ?.administrative_action as ChangeContextModeAdministrativeAction | undefined;
            const deepResearchModeAction = actions.find(
                a => a.administrative_action.type === "change_deep_research_mode",
            )?.administrative_action as ChangeDeepResearchModeAdministrativeAction | undefined;

            if (contextModeAction != null && deepResearchModeAction != null) {
                return `Context changed to ${contextModeAction.is_direct_base_model_chat ? "external" : tenantName} with deep research ${deepResearchModeAction.use_deep_research ? "enabled" : "disabled"}`;
            }
        }

        // Handle single messages or other combinations
        return actions
            .map(action => {
                switch (action.administrative_action.type) {
                    case "change_model": {
                        const modelDisplayName = getModelDisplayName(
                            action.administrative_action.model,
                            enableSimplifiedModelPicker,
                        );
                        return `Model changed to ${modelDisplayName}`;
                    }
                    case "change_context_mode":
                        return `Context changed to ${action.administrative_action.is_direct_base_model_chat ? "external" : tenantName}`;
                    case "change_source_filters":
                        return "Source filters updated";
                    case "change_deep_research_mode":
                        return action.administrative_action.use_deep_research
                            ? "Deep research mode enabled"
                            : "Deep research mode disabled";
                    default:
                        return "Unknown administrative action";
                }
            })
            .join(", ");
    }, [actions, tenantName, enableSimplifiedModelPicker]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                my: 2,
                px: 2,
            }}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    height: "1px",
                    bgcolor: "neutrals.40",
                }}
            />
            <Typography
                variant="caption"
                sx={{
                    color: "neutrals.60",
                    whiteSpace: "nowrap",
                }}
            >
                {message}
            </Typography>
            <Box
                sx={{
                    flexGrow: 1,
                    height: "1px",
                    bgcolor: "neutrals.40",
                }}
            />
        </Box>
    );
};

const headerSx: SxProps<Theme> = { mb: 2 };

const ChatPreviewSection: React.FC<{
    source: AssistantChatMessageSource;
    page: number;
    onPageChange: (newPage: number) => void;
    onClose: () => void;
}> = ({ source, page, onPageChange, onClose }) => {
    const previewSource = React.useMemo(() => {
        return {
            file_name: source.name,
            pages: source.pages ?? [],
            blob_name: source.blob_name ?? undefined,
            is_user_file: source.is_user_file ?? false,
            time_created: source.time_created ?? undefined,
            project_code: source.project_code ?? undefined,
        };
    }, [source]);

    const header = React.useMemo(() => {
        return (
            <Typography variant="h6" color="secondary.main" noWrap>
                {source.name}
            </Typography>
        );
    }, [source.name]);

    return (
        <PreviewSection
            source={previewSource}
            page={page}
            onPageChange={onPageChange}
            onClose={onClose}
            header={header}
            headerSx={headerSx}
        />
    );
};

function useDerivedState(
    chat: ChatOutSchema | undefined,
    setModel: (model: Model) => void,
    setProjects: (projects: string[]) => void,
    setSourceTypes: (sourceTypes: SourceType[]) => void,
    setFileTypes: (fileTypes: FileType[]) => void,
    setEarliestDate: (earliestDate: Date | undefined) => void,
    setIsDirectBaseModelChat: (isDirectBaseModelChat: boolean) => void,
    setUseDeepResearch: (useDeepResearch: boolean) => void,
) {
    React.useEffect(() => {
        if (chat?.model != null) {
            setModel(chat.model);
        }
    }, [chat?.model, setModel]);

    React.useEffect(() => {
        if (chat?.source_filters?.projects != null) {
            setProjects(chat.source_filters.projects);
        }
    }, [chat?.source_filters?.projects, setProjects]);

    React.useEffect(() => {
        if (chat?.source_filters?.source_types != null) {
            setSourceTypes(chat.source_filters.source_types);
        }
    }, [chat?.source_filters?.source_types, setSourceTypes]);

    React.useEffect(() => {
        if (chat?.source_filters?.file_types != null) {
            setFileTypes(chat.source_filters.file_types);
        }
    }, [chat?.source_filters?.file_types, setFileTypes]);

    React.useEffect(() => {
        if (chat?.source_filters?.earliest_date != null) {
            setEarliestDate(new Date(chat.source_filters.earliest_date));
        }
    }, [chat?.source_filters?.earliest_date, setEarliestDate]);

    React.useEffect(() => {
        if (chat?.is_direct_base_model_chat != null) {
            setIsDirectBaseModelChat(chat.is_direct_base_model_chat);
        }
    }, [chat?.is_direct_base_model_chat, setIsDirectBaseModelChat]);

    React.useEffect(() => {
        if (chat?.use_deep_research != null) {
            setUseDeepResearch(chat.use_deep_research);
        }
    }, [chat?.use_deep_research, setUseDeepResearch]);
}
