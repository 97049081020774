import * as React from "react";

import { tenantQueryOptions } from "./chat/queryOptions";

import { useQuery } from "@tanstack/react-query";
import { LOCAL_STORAGE_ACCESS_TOKEN, useIsLoggedIn } from "./auth/authentication";

const PLATFORM_DOMAIN = "platform.answergrid.ai";

export const SUBDOMAIN_REDIRECT_QUERY_PARAM = "subdomainRedirect";

// TODO: Fix redirect based on propel auth

export function useSubdomainRedirect() {
    const [isLoggedIn] = useIsLoggedIn();
    const [hasQueried, setHasQueried] = React.useState(false);

    const tenant = useQuery({
        ...tenantQueryOptions,
        placeholderData: undefined,
        staleTime: Infinity,
        gcTime: 0,
        select: data => data.subdomain ?? undefined,
        enabled: !hasQueried && isLoggedIn.state === "loaded" && isLoggedIn.data,
    });

    React.useEffect(() => {
        if (tenant.isSuccess || tenant.isError) {
            setHasQueried(true);
        }
    }, [tenant.isSuccess, tenant.isError]);

    // First useEffect for redirect
    React.useEffect(() => {
        const currentHostname = window.location.hostname;
        const isPlatformDomain = hasPlatformDomain(currentHostname);

        if (isPlatformDomain && tenant.data != null) {
            const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
            if (token) {
                document.cookie = constructCookieString(token);
            }

            const currentPath = window.location.pathname.replace(/\/$/, "");
            const currentSearch = window.location.search;
            const searchParams = new URLSearchParams(currentSearch);
            searchParams.append(SUBDOMAIN_REDIRECT_QUERY_PARAM, "true");
            const newUrl = `https://${tenant.data}.answergrid.ai${currentPath}?${searchParams.toString()}`;
            window.location.href = newUrl;
        }
    }, [tenant.data]);

    useReceivingSubdomainRedirect();
}

function hasPlatformDomain(hostname: string) {
    return hostname === PLATFORM_DOMAIN || hostname.endsWith(`.${PLATFORM_DOMAIN}`);
}

const COOKIE_NAME = "authToken";

function constructCookieString(token: string) {
    return `${COOKIE_NAME}=${encodeURIComponent(token)}; domain=.answergrid.ai; path=/; Secure; SameSite=None; Max-Age=300`;
}

// ON the receiving side, we need to extract the token from the cookie and set it in localStorage
function useReceivingSubdomainRedirect() {
    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const hasRedirectParam = searchParams.has(SUBDOMAIN_REDIRECT_QUERY_PARAM);

        if (hasRedirectParam && !hasPlatformDomain(window.location.hostname)) {
            try {
                if (localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) == null) {
                    // More robust cookie parsing
                    const cookieValue = document.cookie
                        .split("; ")
                        .find(row => row.startsWith(`${COOKIE_NAME}=`))
                        ?.split("=")[1];

                    if (cookieValue) {
                        const token = decodeURIComponent(cookieValue);
                        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, token);
                    } else {
                        console.error("No auth token found in cookie");
                    }
                }

                searchParams.delete(SUBDOMAIN_REDIRECT_QUERY_PARAM);
                const newSearch = searchParams.toString();
                const newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ""}`;
                window.location.href = newUrl;
            } catch (error) {
                console.error("Error processing auth cookie:", error);
            }
        }
    }, []);
}
