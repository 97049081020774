import * as React from "react";
import { Box, IconButton, Input, Theme, SxProps, Tooltip } from "@mui/material";
import { ArrowUp, Paperclip } from "iconsax-react";
import { useTheme } from "@mui/material/styles";
import { getScrollbarSx } from "../shared/scrollbarProps";
import { useQuery } from "@tanstack/react-query";
import { tenantQueryOptions } from "./queryOptions";
import { Model } from "./types";
import { ContextModeButton } from "./contextModeButton";
import { DEFAULT_MODEL_FOR_BASE_CHAT } from "./models";
import { getAllowedContexts } from "../utils/allowedContexts";

interface ChatInputProps {
    query: string;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onQuerySubmit: () => void;
    disabledReason: string | null;
    onAttach?: () => void;
    attachDisabledReason?: string;
    isDirectBaseModelChat: boolean;
    onDirectBaseModelChatToggle: (() => void) | undefined;
    sx?: SxProps<Theme>;
    model: Model | undefined;
    onModelChange: (model: Model) => void;
    useDeepResearch: boolean;
    onDeepResearchToggle: () => void;
}

const SEND_ICON_BUTTON_SIZE = 32;
const SEND_ICON_SIZE = 20;

export const ChatInput: React.FC<ChatInputProps> = ({
    query,
    onQueryChange,
    onQuerySubmit,
    onAttach,
    attachDisabledReason,
    disabledReason,
    isDirectBaseModelChat,
    onDirectBaseModelChatToggle,
    sx,
    model,
    onModelChange,
    useDeepResearch,
    onDeepResearchToggle,
}) => {
    const theme = useTheme();

    const tenant = useQuery(tenantQueryOptions);

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                onQuerySubmit();
            }
        },
        [onQuerySubmit],
    );

    const canChatWithDocs = tenant.data?.can_chat_with_docs ?? false;
    const allowedContexts = tenant.data != null ? getAllowedContexts(tenant.data) : "internal-only";

    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleModelChange = React.useCallback(
        (model: Model) => {
            onModelChange(model);
            requestAnimationFrame(() => {
                inputRef.current?.focus();
            });
        },
        [onModelChange],
    );

    const handleToggleContextMode = React.useMemo(() => {
        if (onDirectBaseModelChatToggle == null) {
            return undefined;
        }
        return () => {
            onDirectBaseModelChatToggle?.();
            requestAnimationFrame(() => {
                inputRef.current?.focus();
            });
        };
    }, [onDirectBaseModelChatToggle]);

    React.useEffect(() => {
        const handleGlobalKeyDown = (event: KeyboardEvent) => {
            if (
                event.key === "s" &&
                event.shiftKey &&
                (event.metaKey || event.ctrlKey) &&
                handleToggleContextMode != null
            ) {
                event.preventDefault();
                handleToggleContextMode();
            }
            if (event.key === "e" && event.shiftKey && (event.metaKey || event.ctrlKey) && !isDirectBaseModelChat) {
                event.preventDefault();
                onDeepResearchToggle();
            }
        };

        document.addEventListener("keydown", handleGlobalKeyDown);
        return () => document.removeEventListener("keydown", handleGlobalKeyDown);
    }, [handleToggleContextMode, onDeepResearchToggle, isDirectBaseModelChat]);

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "surface.0",
                    borderRadius: 5,
                    border: 1,
                    borderColor: "neutrals.25",
                    ml: 4,
                    mr: 2,
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Input
                autoFocus
                placeholder="Message AnswerGrid"
                value={query}
                onChange={onQueryChange}
                onKeyDown={handleKeyDown}
                fullWidth
                disableUnderline
                multiline
                maxRows={8}
                inputRef={inputRef}
                sx={{
                    pl: 2,
                    pt: 1.5,
                    pb: 2.5,
                    pr: 1.25,
                    "& .MuiInputBase-input": {
                        ...getScrollbarSx("white"),
                    },
                }}
            />
            <Box sx={{ display: "flex", alignItems: "center", px: 1.25, pb: 1.25 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    {(allowedContexts === "internal-and-external" || allowedContexts === "external-only") && (
                        <ContextModeButton
                            isDirectBaseModelChat={isDirectBaseModelChat}
                            onToggle={handleToggleContextMode}
                            model={model ?? DEFAULT_MODEL_FOR_BASE_CHAT}
                            menuPosition="top"
                            onModelChange={handleModelChange}
                            useDeepResearch={useDeepResearch}
                            onDeepResearchToggle={onDeepResearchToggle}
                        />
                    )}
                    {(canChatWithDocs || allowedContexts === "internal-only") && (
                        <AttachButton
                            onAttach={onAttach}
                            disabledReason={attachDisabledReason}
                            sx={{ ml: canChatWithDocs ? 0 : -0.25 }}
                        />
                    )}
                </Box>
                <Box sx={{ flex: 1 }} />
                <Tooltip title={disabledReason || ""}>
                    <span>
                        <IconButton
                            sx={{ m: 0, p: 0 }}
                            disabled={disabledReason != null}
                            onClick={onQuerySubmit}
                            size="small"
                        >
                            <Box
                                sx={{
                                    bgcolor: disabledReason != null ? "divider" : "secondary.main",
                                    borderRadius: "50%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: SEND_ICON_BUTTON_SIZE,
                                    height: SEND_ICON_BUTTON_SIZE,
                                }}
                            >
                                <ArrowUp size={SEND_ICON_SIZE} color={theme.palette.common.white} />
                            </Box>
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
        </Box>
    );
};

interface AttachButtonProps {
    onAttach: (() => void) | undefined;
    disabledReason?: string;
    sx?: SxProps<Theme>;
}

const AttachButton: React.FC<AttachButtonProps> = ({ onAttach, disabledReason, sx }) => {
    const theme = useTheme();
    return (
        <Tooltip title={disabledReason ?? ""}>
            <span>
                <IconButton
                    size="small"
                    disableRipple
                    disabled={onAttach == null}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    sx={[{ ml: 1, "&:hover": { backgroundColor: "transparent" } }, ...(Array.isArray(sx) ? sx : [sx])]}
                    onClick={onAttach}
                >
                    <Paperclip
                        size={24}
                        color={onAttach == null ? theme.palette.neutrals[60] : theme.palette.neutrals[80]}
                    />
                </IconButton>
            </span>
        </Tooltip>
    );
};
