import * as React from "react";
import { Box, Skeleton } from "@mui/material";
import { PersonCardLayout, COMPACT_PROFILE_SIZE, PROFILE_SIZE } from "./PersonCardLayout";
import { ReactComponent as BackgroundPattern } from "../assets/profile-card-pattern.svg";

interface PersonCardSkeletonProps {
    variant?: "default" | "compact";
}

export function PersonCardSkeleton({ variant = "default" }: PersonCardSkeletonProps) {
    const profileSize = variant === "compact" ? COMPACT_PROFILE_SIZE : PROFILE_SIZE;

    // Background pattern - we can use the real one since it's decorative
    const backgroundPatternSlot = (
        <BackgroundPattern
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0.5,
            }}
        />
    );

    // Profile image skeleton
    const profileImageSlot = (
        <Skeleton
            variant="circular"
            width={profileSize}
            height={profileSize}
            sx={{ border: 2, borderColor: "surface.0" }}
        />
    );

    // Name skeleton
    const nameSlot = <Skeleton variant="text" width={100} sx={{ mx: "auto" }} />;

    const titleAndLocationSlot =
        variant === "default" ? (
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, mb: 3 }}>
                <Skeleton variant="text" width={100} sx={{}} />
                <Skeleton variant="text" width={80} sx={{}} />
            </Box>
        ) : (
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", rowGap: 1 }}>
                <Skeleton variant="text" width={100} sx={{}} />
                <Skeleton variant="text" width={80} sx={{}} />
            </Box>
        );

    // Project skeletons - show 2 project cards
    const projectsSlot = (
        <>
            <Skeleton variant="rectangular" height={80} sx={{ borderRadius: 2 }} />
            <Skeleton variant="rectangular" height={80} sx={{ borderRadius: 2 }} />
        </>
    );

    // View projects button skeleton
    const viewProjectsButtonSlot =
        variant === "default" ? (
            <Skeleton variant="rectangular" height={36} sx={{ mt: 1, borderRadius: 5, mx: 1 }} />
        ) : undefined;

    return (
        <PersonCardLayout
            variant={variant}
            backgroundPatternSlot={backgroundPatternSlot}
            profileImageSlot={profileImageSlot}
            nameSlot={nameSlot}
            titleAndLocationSlot={titleAndLocationSlot}
            projectsSlot={projectsSlot}
            viewProjectsButtonSlot={viewProjectsButtonSlot}
        />
    );
}
