import * as React from "react";
import { Box, Typography, useTheme, Tooltip } from "@mui/material";
import { ArrowLeft2 } from "iconsax-react";

export const SearchViewHeader: React.FC<{
    text: string | React.ReactNode;
    tooltip?: string;
    action?: React.ReactNode;
    onBack?: () => void;
}> = ({ text, tooltip, action, onBack }) => {
    const theme = useTheme();

    const handleBack = React.useCallback(() => {
        onBack?.();
    }, [onBack]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
                {onBack != null && (
                    <Box
                        component="button"
                        onClick={handleBack}
                        sx={{
                            cursor: "pointer",
                            background: "none",
                            border: "none",
                            padding: 0,
                            display: "flex",
                            alignItems: "center",
                            mr: 1,
                            mb: 1,
                            "&:hover": {
                                opacity: 0.7,
                            },
                        }}
                    >
                        <ArrowLeft2 size={20} color={theme.palette.neutrals[60]} />
                    </Box>
                )}
                <Typography variant="h6" gutterBottom sx={{ color: "secondary.main" }} noWrap>
                    {text}
                </Typography>
                {tooltip != null && (
                    <Tooltip title={tooltip}>
                        <span style={{ marginLeft: theme.spacing(1) }}>
                            <Box
                                sx={{
                                    height: 14,
                                    width: 14,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mb: "5px",
                                    ml: "1px",
                                    color: "neutrals.50",
                                    border: 1,
                                    borderColor: "neutrals.50",
                                    borderRadius: "50%",
                                }}
                            >
                                <Typography sx={{ fontSize: 9, fontWeight: 450 }}>i</Typography>
                            </Box>
                        </span>
                    </Tooltip>
                )}
            </Box>
            {action}
        </Box>
    );
};
