import { ChatSourceFilters } from "../backend-client/generated";
import { FileType, SourceType } from "../shared/types";

export function getSourceFilters(
    projects: string[],
    sourceTypes: SourceType[],
    fileTypes: FileType[],
    earliestDate: Date | undefined,
): ChatSourceFilters | undefined {
    if (projects.length === 0 && sourceTypes.length === 0 && fileTypes.length === 0 && earliestDate == null) {
        return undefined;
    }
    return getSourceFiltersMandatory(projects, sourceTypes, fileTypes, earliestDate);
}

export function getSourceFiltersMandatory(
    projects: string[],
    sourceTypes: SourceType[],
    fileTypes: FileType[],
    earliestDate: Date | undefined,
): ChatSourceFilters {
    return {
        projects,
        source_types: sourceTypes,
        file_types: fileTypes,
        earliest_date: earliestDate?.toISOString().split("T")[0] ?? null,
    };
}

export const EMPTY_SOURCE_FILTERS: ChatSourceFilters = {
    projects: [],
    source_types: [],
    file_types: [],
    earliest_date: null,
};

export function areFiltersEmpty(filters: ChatSourceFilters | undefined): boolean {
    if (filters == null) {
        return true;
    }
    return (
        filters.projects?.length === 0 &&
        filters.source_types?.length === 0 &&
        filters.file_types?.length === 0 &&
        filters.earliest_date == null
    );
}

export function asUndefinedIfEmpty(filters: ChatSourceFilters | undefined): ChatSourceFilters | undefined {
    if (areFiltersEmpty(filters)) {
        return undefined;
    }
    return filters;
}
