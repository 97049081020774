import * as React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { CircularProgress, Box } from "@mui/material";
import { useAuthenticationSuccess } from "../hooks/useAuthenticationSuccess";
import { useIsLoggedIn } from "../auth/authentication";

export const PropelAuthCallback = () => {
    const queryClient = useQueryClient();
    const handleAuthenticationSuccess = useAuthenticationSuccess(queryClient);

    const [isLoggedIn] = useIsLoggedIn();

    React.useEffect(() => {
        // You might need to verify the auth response here
        // For example, check URL params from Propal

        // Call your existing authentication success handler
        if (isLoggedIn.state === "loading" || isLoggedIn.state === "unloaded") {
            return;
        }
        if (isLoggedIn.state === "error") {
            console.error("Error loading logged in state", isLoggedIn.error);
            return;
        }
        if (isLoggedIn.state === "loaded") {
            if (isLoggedIn.data) {
                void handleAuthenticationSuccess();
            } else {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                window.location.href = process.env.REACT_APP_AUTH_URL!;
            }
        }
    }, [handleAuthenticationSuccess, isLoggedIn]);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <CircularProgress />
        </Box>
    );
};
