import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import {
    blackCustomTheme,
    blackDarkTheme,
    blueCustomTheme,
    defaultDarkTheme,
    blueDarkTheme,
    defaultTheme,
    blueCustomTheme2,
    blueDarkTheme2,
} from "./theme";
import "./App.css";
import { QueryCache, QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { Navigate, RouterProvider, RouterProviderProps, createBrowserRouter } from "react-router-dom";
import { SnackbarProviderProps, enqueueSnackbar } from "notistack";
import { AnswerGridSnackbarProvider } from "./answerGridSnackbarProvider";
import { H } from "highlight.run";
import { ErrorBoundary } from "@highlight-run/react";
import { HelmetProvider } from "react-helmet-async";
import { SearchHomeView } from "./search/searchHomeView";
import { SearchView } from "./search/searchView";
import { NewChatView } from "./chat/newChatView";
import { ProtectedRoute } from "./routing/protectedRoute";
import { ChatView } from "./chat/chatView";
import { BACKEND_URL } from "./backend-client/url";
import { client } from "./backend-client/generated/client.gen";
import { LOCAL_STORAGE_ACCESS_TOKEN, propelAuthClient, useIsLoggedIn } from "./auth/authentication";
import { SharedChatView } from "./chat/sharedChatView";
import { tenantQueryOptions } from "./chat/queryOptions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { ChangePassword } from "./user-administration/ChangePassword";
import { ForgotPassword } from "./user-administration/ForgotPassword";
import { SignUpViaInvitation } from "./user-administration/SignUpViaInvitation";
import { LoggedOutRoute } from "./routing/loggedOutRoute";
import { SignIn } from "./user-administration/SignIn";
import { PropelAuthSignIn } from "./propel-user-administration/PropelAuthSignIn";
import { SettingsView } from "./settings/settingsView";
import { useMediaQuery } from "@mui/material";
import { PostHogConfig } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { AuthProvider } from "@propelauth/react";
import { getShouldUsePropelAuth } from "./shouldUsePropelAuth";
import { isProduction } from "./utils/isProduction";
import { PropelAuthCallback } from "./propel-user-administration/propelAuthCallback";
import { PropelAuthLoggedOut } from "./propel-user-administration/propelAuthLoggedOut";

const posthogOptions: Partial<PostHogConfig> = {
    api_host: "https://us.i.posthog.com",
    person_profiles: "always",
    disable_session_recording: !isProduction,
    session_recording: isProduction
        ? {
              maskAllInputs: false,
              maskInputOptions: {
                  password: true, // Highly recommended as a minimum!!
              },
          }
        : {},
};

const ANCHOR_ORIGIN: SnackbarProviderProps["anchorOrigin"] = {
    vertical: "bottom",
    horizontal: "right",
};

const rootElement = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(rootElement!);

client.setConfig({
    baseUrl: BACKEND_URL,
    throwOnError: true,
});

if (getShouldUsePropelAuth()) {
    client.interceptors.request.use(async config => {
        if (propelAuthClient == null) {
            throw new Error("PropelAuth client not initialized");
        }
        const token = await propelAuthClient.getAuthenticationInfoOrNull().then(info => info?.accessToken);
        if (token != null) {
            config.headers.set("Authorization", `Bearer ${token}`);
        }
        return config;
    });
    client.interceptors.response.use(async response => {
        if (response.status === 401) {
            await propelAuthClient?.logout(true);
        }
        return response;
    });
} else {
    client.interceptors.request.use(config => {
        const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
        if (token != null) {
            config.headers.set("Authorization", `Bearer ${token}`);
        }
        return config;
    });

    client.interceptors.response.use(response => {
        if (response.status === 401 && window.location.pathname !== "/login") {
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
            window.location.assign("/login");
        }
        return response;
    });
}

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error, query) => {
            console.error(error);
            if (query.meta?.toastOnFailure == null || !query.meta.toastOnFailure) {
                return;
            }
            enqueueSnackbar(
                error.message != null
                    ? `An unexpected error occurred: ${error.message}`
                    : "An unexpected error occurred",
                {
                    variant: "error",
                },
            );
        },
    }),
});

const router: RouterProviderProps["router"] = createBrowserRouter([
    {
        element: <LoggedOutRoute homePath="/" />,
        children: getShouldUsePropelAuth()
            ? [
                  {
                      path: "/login",
                      element: <PropelAuthSignIn />,
                      ErrorBoundary: ErrorBoundary,
                  },
                  {
                      path: "/callback",
                      element: <PropelAuthCallback />,
                      ErrorBoundary: ErrorBoundary,
                  },
                  {
                      path: "/logged-out",
                      element: <PropelAuthLoggedOut />,
                      ErrorBoundary: ErrorBoundary,
                  },
              ]
            : [
                  {
                      path: "/forgot-password",
                      element: <ForgotPassword />,
                      ErrorBoundary: ErrorBoundary,
                  },
                  {
                      path: "/invitation",
                      element: <SignUpViaInvitation />,
                      ErrorBoundary: ErrorBoundary,
                  },
                  {
                      path: "/login",
                      element: <SignIn />,
                      ErrorBoundary: ErrorBoundary,
                  },
              ],
    },
    {
        element: (
            <ProtectedRoute
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                authenticationPath={getShouldUsePropelAuth() ? process.env.REACT_APP_AUTH_URL! : "/login"}
                isExternalPath={getShouldUsePropelAuth()}
            />
        ),
        children: [
            {
                path: "/home",
                element: <Navigate to="/" />,
            },
            {
                path: "/",
                element: <SearchHomeView />,
                ErrorBoundary: ErrorBoundary,
            },
            {
                path: "/chat",
                element: <NewChatView />,
                ErrorBoundary: ErrorBoundary,
            },
            {
                path: "/chat/:chatId",
                element: <ChatView />,
                ErrorBoundary: ErrorBoundary,
            },
            {
                path: "/search",
                element: <SearchView />,
                ErrorBoundary: ErrorBoundary,
            },
            {
                path: "/share/:chatId",
                element: <SharedChatView />,
                ErrorBoundary: ErrorBoundary,
            },
            {
                path: "/change-password",
                element: <ChangePassword />,
                ErrorBoundary: ErrorBoundary,
            },
            {
                path: "/settings",
                element: <SettingsView />,
                ErrorBoundary: ErrorBoundary,
            },
            // Add other protected routes here
        ],
    },
]);

const backendUrls =
    process.env.REACT_APP_BACKEND_URL != null
        ? [process.env.REACT_APP_BACKEND_URL, process.env.REACT_APP_BACKEND_URL.replace("https://", "http://")]
        : [];

H.init("ve6lm8re", {
    serviceName: "newton-frontend-app",
    tracingOrigins: isProduction
        ? // TODO: Remove the render ones
          ["https://newton-be.onrender.com", "newton-be.onrender.com", ...backendUrls]
        : ["localhost", "127.0.0.1"],
    environment: isProduction ? "production" : "development",
    disableSessionRecording: !isProduction,
    // TODO: Audit for any PII to remove
    privacySetting: "none",
    networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [
            // insert full or partial urls that you don't want to record here
            // Out of the box, Highlight will not record these URLs (they can be safely removed):
            "https://www.googleapis.com/identitytoolkit",
            "https://securetoken.googleapis.com",
        ],
    },
});

const App: React.FC = () => {
    const propelAuthProviderConfig = getShouldUsePropelAuth()
        ? {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              authUrl: process.env.REACT_APP_AUTH_URL!,
          }
        : undefined;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <HelmetProvider>
                {/* TODO: Move to env variable */}
                <PostHogProvider apiKey="phc_PjUMvEpEma5bx7Vg2ypi1jdcd1zuid3gAIXkVF3Uq65" options={posthogOptions}>
                    <QueryClientProvider client={queryClient}>
                        <AnswerGridThemeProvider>
                            <CssBaseline />
                            <AnswerGridSnackbarProvider anchorOrigin={ANCHOR_ORIGIN}>
                                {propelAuthProviderConfig != null ? (
                                    <AuthProvider authUrl={propelAuthProviderConfig.authUrl}>
                                        <RouterProvider router={router} />
                                    </AuthProvider>
                                ) : (
                                    <RouterProvider router={router} />
                                )}
                            </AnswerGridSnackbarProvider>
                        </AnswerGridThemeProvider>
                    </QueryClientProvider>
                </PostHogProvider>
            </HelmetProvider>
        </LocalizationProvider>
    );
};

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

const ALLOW_DARK_MODE_LOCAL_STORAGE_KEY = "answergrid-allow-dark-mode";

const AnswerGridThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTenantTheme();
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

function useTenantTheme() {
    const [isLoggedIn] = useIsLoggedIn();
    const tenant = useQuery({
        ...tenantQueryOptions,
        select: data => data.theme,
        enabled: isLoggedIn.state === "loaded" && isLoggedIn.data,
    });

    const allowDarkMode = React.useMemo(() => localStorage.getItem(ALLOW_DARK_MODE_LOCAL_STORAGE_KEY) === "true", []);

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const isDarkMode = allowDarkMode && prefersDarkMode;

    switch (tenant.data) {
        case "blue-1":
            return isDarkMode ? blueDarkTheme : blueCustomTheme;
        case "blue-2":
            return isDarkMode ? blueDarkTheme2 : blueCustomTheme2;
        case "default":
            return isDarkMode ? defaultDarkTheme : defaultTheme;
        case "black-1":
            return isDarkMode ? blackDarkTheme : blackCustomTheme;
        case undefined:
            return isDarkMode ? defaultDarkTheme : defaultTheme;
    }
}
