import * as React from "react";
import { Box, Typography, useTheme, Button, Theme, SxProps } from "@mui/material";
import { User } from "iconsax-react";
import { PersonData } from "./types";
import { countries } from "countries-list";
import { ReactComponent as BackgroundPattern } from "../assets/profile-card-pattern.svg";
import { comparatorOnFields } from "../utils/comparators";
import { ProjectForPerson, ProjectForPersonProps } from "./projectForPerson";
import { COMPACT_PROFILE_SIZE, PROFILE_SIZE, PersonCardLayout, PersonTitleAndLocation } from "./PersonCardLayout";

type PersonCardProps = {
    person: PersonData;
    idx: number;
    onSelect: (idx: number, projectIdx: number | undefined) => void;
    sx?: SxProps<Theme>;
    variant?: "default" | "compact";
};

export const PersonCard = React.memo(({ person, idx, onSelect, sx, variant = "default" }: PersonCardProps) => {
    const locationText = React.useMemo(() => {
        if (person.city == null && person.country == null) return null;
        const countryName = person.country ? getCountryName(person.country) : null;
        return [person.city, countryName].filter(Boolean).join(", ");
    }, [person.city, person.country]);

    const projectsSortedByStartDate = React.useMemo(() => {
        return [...person.projects]
            .map((project, idx) => ({ ...project, idx }))
            .sort(
                comparatorOnFields(project => [
                    project.start_date ? -new Date(project.start_date).getTime() : 0,
                    project.end_date ? -new Date(project.end_date).getTime() : 0,
                ]),
            );
    }, [person.projects]);

    const handleSelectPerson = React.useCallback(() => {
        onSelect(idx, undefined);
    }, [idx, onSelect]);

    const handleSelectProject = React.useCallback(
        (projectIdx: number) => {
            onSelect(idx, projectIdx);
        },
        [idx, onSelect],
    );

    const backgroundPatternSlot = React.useMemo(
        () => <BackgroundPattern style={{ width: "100%", height: "100%" }} />,
        [],
    );

    const profileImageSlot = React.useMemo(
        () => (
            <ProfilePicture
                imageUrl={person.profile_picture_url ?? undefined}
                firstName={person.first_name}
                lastName={person.last_name}
                size={variant === "compact" ? COMPACT_PROFILE_SIZE : PROFILE_SIZE}
            />
        ),
        [person.profile_picture_url, person.first_name, person.last_name, variant],
    );

    const nameSlot = React.useMemo(
        () => (
            <Typography
                variant="body1"
                color="neutrals.80"
                fontWeight={500}
                align="center"
                sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {person.first_name} {person.last_name}
            </Typography>
        ),
        [person.first_name, person.last_name],
    );

    const titleSlot = React.useMemo(() => person.title, [person.title]);

    const locationSlot = React.useMemo(() => locationText, [locationText]);

    // const titleAndLocationSlot = React.useMemo(
    //     () =>
    //         variant === "default" ? (
    //             <Typography
    //                 variant="body2"
    //                 color="neutrals.60"
    //                 align="center"
    //                 sx={{
    //                     display: "-webkit-box",
    //                     WebkitLineClamp: 2,
    //                     WebkitBoxOrient: "vertical",
    //                     overflow: "hidden",
    //                     textOverflow: "ellipsis",
    //                 }}
    //             >
    //                 {person.title} {locationText && `- ${locationText}`}
    //             </Typography>
    //         ) : (
    //             <>
    //                 <Typography variant="body2" color="neutrals.60" align="center">
    //                     {person.title}
    //                 </Typography>
    //                 <Typography variant="body2" color="neutrals.50" align="center">
    //                     {locationText != null && ` ${locationText}`}
    //                 </Typography>
    //             </>
    //         ),
    //     [variant, person.title, locationText],
    // );

    const projectsSlot = React.useMemo(
        () =>
            projectsSortedByStartDate
                .slice(0, 2)
                .map(project => (
                    <ProjectForPersonWithIdx
                        key={project.id}
                        project={project}
                        personId={person.unique_id}
                        projectIdx={project.idx}
                        onSelect={handleSelectProject}
                        hideCollaborators={variant === "compact"}
                    />
                )),
        [projectsSortedByStartDate, person.unique_id, handleSelectProject, variant],
    );

    const viewProjectsButtonSlot = React.useMemo(
        () => (
            <Button
                variant="outlined"
                onClick={handleSelectPerson}
                sx={{
                    mt: 1,
                    color: "secondary.main",
                    borderColor: "secondary.main",
                    textTransform: "none",
                    mx: 1,
                    borderRadius: 5,
                }}
            >
                View {person.projects.length} relevant projects
            </Button>
        ),
        [handleSelectPerson, person.projects.length],
    );

    return (
        <PersonCardLayout
            variant={variant}
            sx={sx}
            onClick={handleSelectPerson}
            backgroundPatternSlot={backgroundPatternSlot}
            profileImageSlot={profileImageSlot}
            nameSlot={nameSlot}
            titleAndLocationSlot={
                <PersonTitleAndLocation variant={variant} title={titleSlot} locationText={locationSlot ?? undefined} />
            }
            projectsSlot={projectsSlot}
            viewProjectsButtonSlot={viewProjectsButtonSlot}
        />
    );
});

interface ProjectForPersonWithIdxProps extends Omit<ProjectForPersonProps, "onSelect"> {
    personId: string;
    projectIdx: number;
    onSelect: (projectIdx: number) => void;
    hideCollaborators?: boolean;
}

const ProjectForPersonWithIdx = React.memo(
    ({ project, personId, projectIdx, onSelect, hideCollaborators }: ProjectForPersonWithIdxProps) => {
        const handleSelect = React.useCallback(() => {
            onSelect(projectIdx);
        }, [projectIdx, onSelect]);

        return (
            <ProjectForPerson
                project={project}
                personId={personId}
                onSelect={handleSelect}
                hideCollaborators={hideCollaborators}
            />
        );
    },
);

ProjectForPersonWithIdx.displayName = "ProjectForPersonWithIdx";

PersonCard.displayName = "PersonCard";

type ProfilePictureProps = {
    imageUrl?: string;
    firstName: string;
    lastName: string;
    size: number;
};

const ProfilePicture = React.memo(({ imageUrl, firstName, lastName, size }: ProfilePictureProps) => {
    const [imageError, setImageError] = React.useState(false);
    const theme = useTheme();

    const handleImageError = React.useCallback(() => {
        setImageError(true);
    }, []);

    if (imageError || imageUrl == null) {
        return (
            <Box
                sx={{
                    width: size,
                    height: size,
                    borderRadius: "50%",
                    bgcolor: "neutrals.10",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <User size={size / 2} color={theme.palette.secondary.main} variant="Bold" />
            </Box>
        );
    }

    return (
        <Box
            component="img"
            src={imageUrl}
            alt={`${firstName} ${lastName}`}
            onError={handleImageError}
            sx={{
                width: size,
                height: size,
                borderRadius: "50%",
                objectFit: "cover",
            }}
        />
    );
});

ProfilePicture.displayName = "ProfilePicture";

const getCountryName = (isoCode: string) => {
    const country = (countries as Record<string, { name: string }>)[isoCode.toUpperCase()];
    return country?.name ?? isoCode;
};
