import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useIsLoggedIn } from "../auth/authentication";
import { Box, CircularProgress, Typography } from "@mui/material";
import { isProduction } from "../utils/isProduction";

interface LoggedOutRouteProps {
    homePath: string;
}

/**
 * A route that only renders its children if the user is not logged in.
 * For example, the forgot password page should only be accessible when the user is logged out.
 */
export const LoggedOutRoute: React.FC<LoggedOutRouteProps> = ({ homePath }) => {
    const [isLoggedIn] = useIsLoggedIn();

    // TODO: Is this correct?
    if (isLoggedIn.state === "loading" || isLoggedIn.state === "unloaded") {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100vw",
                }}
            >
                <CircularProgress sx={{ color: "secondary.main", width: 48, height: 48 }} />
                {!isProduction && <Typography>Loading logged in state...</Typography>}
            </Box>
        );
    }

    if (isLoggedIn.state === "loaded" && isLoggedIn.data) {
        return <Navigate to={homePath} />;
    } else {
        return <Outlet />;
    }
};
