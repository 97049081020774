import * as React from "react";
import { Box, Theme, SxProps, Typography } from "@mui/material";
import { ReactComponent as BackgroundPattern } from "../assets/profile-card-pattern.svg";

export const CARD_WIDTH = 320;
export const COMPACT_CARD_WIDTH = 270;
export const PROFILE_SIZE = 104;
export const COMPACT_PROFILE_SIZE = 90;
export const PATTERN_HEIGHT = 76;
export const COMPACT_PATTERN_HEIGHT = 60;

export interface PersonCardLayoutProps {
    // Core props
    variant?: "default" | "compact";
    sx?: SxProps<Theme>;
    onClick?: () => void;

    // Slots for content
    backgroundPatternSlot: React.ReactNode;
    profileImageSlot: React.ReactNode;
    nameSlot: React.ReactNode;
    // titleSlot: React.ReactNode;
    // locationSlot: React.ReactNode;
    titleAndLocationSlot: React.ReactNode;
    projectsSlot: React.ReactNode;
    viewProjectsButtonSlot?: React.ReactNode;
}

export function PersonCardLayout({
    variant = "default",
    sx,
    profileImageSlot,
    nameSlot,
    titleAndLocationSlot,
    projectsSlot,
    viewProjectsButtonSlot,
}: PersonCardLayoutProps) {
    const patternHeight = variant === "compact" ? COMPACT_PATTERN_HEIGHT : PATTERN_HEIGHT;
    const profileSize = variant === "compact" ? COMPACT_PROFILE_SIZE : PROFILE_SIZE;

    return (
        <Box
            sx={[
                {
                    minWidth: variant === "compact" ? COMPACT_CARD_WIDTH : CARD_WIDTH,
                    maxWidth: variant === "compact" ? COMPACT_CARD_WIDTH : CARD_WIDTH,
                    height: variant === "compact" ? 400 : 450,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    bgcolor: "surface.0",
                    border: 1,
                    borderColor: "neutrals.25",
                    borderRadius: 2,
                    position: "relative",
                    // overflow: "hidden",
                    pb: 1,
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Box
                sx={theme => ({
                    position: "relative",
                    minHeight: `calc(${patternHeight}px + ${profileSize / 2}px + ${theme.spacing(1.5)})`,
                    // overflow: "hidden",
                    width: "100%",
                    overflow: "hidden",
                })}
            >
                <BackgroundPattern
                    style={{
                        left: 1,
                        right: 1,
                        top: 1,
                        width: "calc(100%)",
                        height: patternHeight,
                        borderTopLeftRadius: 7,
                        borderTopRightRadius: 7,
                    }}
                />

                <Box
                    sx={{
                        position: "absolute",
                        left: "50%",
                        top: `calc(${patternHeight}px - ${profileSize / 2}px)`,
                        transform: "translateX(-50%)",
                        p: 0.5,
                        bgcolor: "surface.0",
                        borderRadius: "50%",
                    }}
                >
                    {profileImageSlot}
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: 1,
                    px: 1,
                    overflow: "auto",
                    zIndex: 2,
                }}
            >
                <Typography
                    variant="body1"
                    color="neutrals.80"
                    fontWeight={500}
                    align="center"
                    sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        mb: variant === "compact" ? 0 : 0.5,
                    }}
                >
                    {nameSlot}
                </Typography>

                {/* {variant === "default" && (
                    <Typography
                        variant="body2"
                        color="neutrals.60"
                        align="center"
                        sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            mb: 3,
                        }}
                    >
                        {titleSlot}
                        {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {/* {locationSlot != null && ` - ${locationSlot}`}
                    </Typography>
                )}
                {variant === "compact" && (
                    <>
                        <Typography variant="body2" color="neutrals.60" align="center">
                            {titleSlot}
                        </Typography>

                        <Typography variant="body2" color="neutrals.50" align="center" sx={{ mb: 2 }}>
                            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {/* {locationSlot != null && `- ${locationSlot}`}
                        </Typography>
                    </>
                )} */}
                {titleAndLocationSlot}

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}>{projectsSlot}</Box>
            </Box>

            {viewProjectsButtonSlot != null && viewProjectsButtonSlot}
        </Box>
    );
}

interface PersonTitleAndLocationProps {
    variant: "default" | "compact";
    title: string;
    locationText?: string;
}

export const PersonTitleAndLocation = React.memo(({ variant, title, locationText }: PersonTitleAndLocationProps) => {
    if (variant === "default") {
        return (
            <Typography
                variant="body2"
                color="neutrals.60"
                align="center"
                sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    mb: 3,
                }}
            >
                {title} {locationText && `- ${locationText}`}
            </Typography>
        );
    }

    return (
        <>
            <Typography variant="body2" color="neutrals.60" align="center">
                {title}
            </Typography>

            <Typography variant="body2" color="neutrals.50" align="center" sx={{ mb: 2 }}>
                {locationText != null && `${locationText}`}
            </Typography>
        </>
    );
});

PersonTitleAndLocation.displayName = "PersonTitleAndLocation";
