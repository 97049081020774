import { TenantSchema } from "../backend-client/generated";

type AllowedContexts = "internal-and-external" | "internal-only" | "external-only";

export function getAllowedContexts(tenant: TenantSchema): AllowedContexts {
    const enableInternalContext = tenant.enable_internal_context ?? true;
    const enableExternalContext = tenant.can_chat_with_base_model ?? false;
    if (enableInternalContext && enableExternalContext) {
        return "internal-and-external";
    }
    if (enableInternalContext) {
        return "internal-only";
    }
    return "external-only";
}
