import React from "react";
import { Box, Typography, Avatar, useTheme, SxProps, Theme, Tooltip } from "@mui/material";
import { DollarCircle, Gps, TickCircle } from "iconsax-react"; // or whatever icons you use for $ and GPS
import { Calendar } from "iconsax-react"; // if you prefer the existing Calendar icon
import { format } from "date-fns";
import { ProjectAnswerCardLayout } from "./ProjectAnswerCardLayout";
import { Project } from "./types";
import { isNonNullable } from "../utils/isNonNullable";
import { ReactComponent as BuildingBold } from "../assets/ag-building.svg";
import { ReactComponent as Building } from "../assets/ag-building-outlined.svg";

export interface ProjectAnswerCardProps {
    project: Project;
    onSelect: () => void;
    isSelected: boolean;
    sx?: SxProps<Theme>;
}

// Map project_type to a short label
const PROJECT_TYPE_LABELS: Record<string, string> = {
    "private-equity-buyside-due-diligence": "CDD",
    "private-equity-sellside-due-diligence": "VCDD",
    "general-strategy": "VCS",
};

export const ProjectAnswerCard = React.memo(function ProjectAnswerCard({
    project,
    onSelect,
    isSelected,
    sx,
}: ProjectAnswerCardProps) {
    const theme = useTheme();

    // Format start/end dates as "MMM yyyy - MMM yyyy"
    const formattedDates = React.useMemo(() => {
        const start = project.start_date ? format(new Date(project.start_date), "MMM yyyy") : null;
        const end = project.end_date ? format(new Date(project.end_date), "MMM yyyy") : null;
        return [start, end].filter(isNonNullable).join(" - ");
    }, [project.start_date, project.end_date]);

    // The short label for the project type
    const projectTypeLabel =
        project.project_type && PROJECT_TYPE_LABELS[project.project_type]
            ? PROJECT_TYPE_LABELS[project.project_type]
            : null;

    // Possibly an icon next to #ID if can_be_used_for_credentials
    const credentialsTick = project.can_be_used_for_credentials ? (
        <Tooltip title="This project is approved for credentials use" placement="top">
            <TickCircle color={theme.palette.success.main} size={16} />
        </Tooltip>
    ) : null;

    // We'll place summary text directly, removing the lamp.
    const parsedQueryAwareSummary = React.useMemo(() => {
        if (!project.query_aware_summary) return null;
        // Simple "split by <b>…</b>" logic:
        return project.query_aware_summary.split(/<\/?b>/).map((part, index) =>
            index % 2 === 1 ? (
                <Typography
                    key={index}
                    variant="caption"
                    component="span"
                    color="neutrals.60"
                    fontWeight={500}
                    sx={{ whiteSpace: "pre-wrap" }}
                >
                    {part}
                </Typography>
            ) : (
                <React.Fragment key={index}>{part}</React.Fragment>
            ),
        );
    }, [project.query_aware_summary]);

    // For the top-right icons:
    // Buyside => two 36×36 boxes. Others => one 56×56 box.
    const topRightSlot = React.useMemo(() => {
        const iconBoxSx = {
            width: 36,
            height: 36,
            border: "1px solid",
            borderColor: "neutrals.30",
            borderRadius: 1,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "white",
        };

        const iconBoxLargeSx = {
            width: 56,
            height: 56,
            border: "1px solid",
            borderColor: "neutrals.30",
            borderRadius: 1,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "surface.0",
        };

        if (project.project_type === "private-equity-buyside-due-diligence") {
            return (
                <Box sx={{ width: 56, height: 56, display: "flex", flexDirection: "column" }}>
                    {/* Client top-left */}
                    <Box sx={{ ...iconBoxSx, zIndex: 1 }}>
                        <LogoWithFallback logoUrl={project.client?.logo_url} fallback="dollar" />
                    </Box>
                    {/* Target bottom-right */}
                    <Box sx={{ ...iconBoxSx, mt: -2.5, alignSelf: "flex-end", zIndex: 2 }}>
                        <LogoWithFallback logoUrl={project.target_organization?.logo_url} fallback="gps" />
                    </Box>
                </Box>
            );
        }
        // All other project types => one 56×56 box
        return (
            <Box sx={iconBoxLargeSx}>
                <LogoWithFallback logoUrl={project.client?.logo_url} fallback="apartment" />
            </Box>
        );
    }, [project.project_type, project.client?.logo_url, project.target_organization?.logo_url]);

    // For the middle slot:
    // Buyside => three items:
    //   1) dollar sign + client name (or dash)
    //   2) gps icon + target name (or dash)
    //   3) calendar + date range
    // Others => one item:
    //   - Building icon + client name (or dash)
    const middleLeftSlot = React.useMemo(() => {
        const calendar = (
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Calendar size={18} color={theme.palette.neutrals[60]} />
                <Typography variant="caption" color="neutrals.60" noWrap>
                    {formattedDates || "-"}
                </Typography>
            </Box>
        );
        if (project.project_type === "private-equity-buyside-due-diligence") {
            return (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, overflow: "hidden" }}>
                    {/* $ + client */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <DollarCircle size={18} color={theme.palette.neutrals[60]} />
                        <Typography variant="caption" color="neutrals.60" noWrap>
                            {project.client?.name || "-"}
                        </Typography>
                    </Box>

                    {/* GPS + target */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        <Gps size={18} color={theme.palette.neutrals[60]} />
                        <Typography variant="caption" color="neutrals.60" noWrap>
                            {project.target_organization?.name || "-"}
                        </Typography>
                    </Box>

                    {calendar}
                </Box>
            );
        }

        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, overflow: "hidden" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    {/* <ApartmentIcon sx={{ fontSize: 18, color: theme.palette.neutrals[60] }} /> */}
                    <Building style={{ color: theme.palette.neutrals[60], width: 16, height: 16 }} />
                    <Typography variant="caption" color="neutrals.60" noWrap>
                        {project.client?.name || "-"}
                    </Typography>
                </Box>
                {calendar}
            </Box>
        );
    }, [
        project.project_type,
        project.client?.name,
        project.target_organization?.name,
        formattedDates,
        theme.palette.neutrals,
    ]);

    // The "type tag" if we have one
    const typeTag = projectTypeLabel ? (
        <Box
            sx={{
                width: 56,
                height: 20,
                bgcolor: "surface.25",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
            }}
        >
            <Typography variant="caption" color="neutrals.80">
                {projectTypeLabel}
            </Typography>
        </Box>
    ) : null;

    // Combine the top-left: project name, #ID, credentials tick, type tag
    const topLeftSlot = (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                minWidth: 0,
                overflow: "hidden",
                maxWidth: "100%",
            }}
        >
            <Typography
                variant="body1"
                color="neutrals.80"
                sx={{
                    fontWeight: 550,
                    overflow: "hidden",
                }}
                noWrap
            >
                {project.name}
            </Typography>
            <Typography variant="caption" color="neutrals.50" noWrap sx={{ flexShrink: 0 }}>
                #{project.id?.split("-")[1] ?? project.id ?? ""}
            </Typography>
            {credentialsTick}
        </Box>
    );

    // Bottom-right contributors slot
    // Show up to 3 avatars. If fewer than 3, center them. If more, align right.
    const bottomSlot = React.useMemo(() => {
        const members = project.all_members || [];
        const showMembers = members.slice(0, 3);

        const firstContributorFirstName = showMembers[0]?.first_name;

        return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, justifyContent: "space-between", height: 24 }}>
                <Typography variant="caption" color="neutrals.50" noWrap>
                    {members.length === 0
                        ? "No active contributors"
                        : members.length === 1
                          ? `${members[0].first_name} ${members[0].last_name} was the sole contributor`
                          : members.length === 2
                            ? `${firstContributorFirstName} and ${members[1].first_name} contributed`
                            : `${firstContributorFirstName} and ${members.length - 1} ${
                                  members.length - 1 === 1 ? "other" : "others"
                              } contributed`}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", gap: 0.5, width: 56 }}>
                    {showMembers.map((m, i) => (
                        <Avatar
                            key={m.unique_id}
                            src={m.profile_picture_url || undefined}
                            sx={{
                                width: 24,
                                height: 24,
                                ml: i === 0 ? 0 : -1.4,
                                border: 1,
                                borderColor: "surface.0",
                                zIndex: showMembers.length - i, // stack them
                            }}
                        />
                    ))}
                </Box>
            </Box>
        );
    }, [project.all_members]);

    return (
        <ProjectAnswerCardLayout
            isSelected={isSelected}
            onClick={onSelect}
            sx={sx}
            topLeftSlot={topLeftSlot}
            topRightSlot={topRightSlot}
            middleLeftSlot={middleLeftSlot}
            middleRightSlot={typeTag}
            summarySlot={
                parsedQueryAwareSummary ? (
                    <Typography
                        variant="caption"
                        color="neutrals.50"
                        sx={{ display: "-webkit-box", WebkitLineClamp: 2, overflow: "hidden" }}
                    >
                        {parsedQueryAwareSummary}
                    </Typography>
                ) : null
            }
            bottomSlot={bottomSlot}
        />
    );
});

ProjectAnswerCard.displayName = "ProjectAnswerCard";

// Renders an img or fallback icon
const LogoWithFallback = ({ logoUrl, fallback }: { logoUrl?: string; fallback: "apartment" | "dollar" | "gps" }) => {
    const theme = useTheme();
    if (logoUrl) {
        return <Box component="img" src={logoUrl} sx={{ width: "100%", height: "100%", objectFit: "contain" }} />;
    }
    // Fallback => apartment icon
    switch (fallback) {
        case "apartment":
            return <BuildingBold style={{ color: theme.palette.secondary.main, width: 32, height: 32 }} />;
        case "dollar":
            return <DollarCircle size={20} color={theme.palette.secondary.main} />;
        case "gps":
            return <Gps size={20} color={theme.palette.secondary.main} />;
    }
};
